import "./GetProfile.css";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";

export const NewGetProfile = () => {
  const auth = JSON.parse(window.localStorage.getItem("auth"));

  return (
    <MainLayout title="Profile">
      <div className="mt-5">
        <div className="flex flex-col gap-2 ml-7">
          <p>
            <img
              alt=""
              src={auth.image}
              style={{ width: "40px", height: "40px", borderRadius: "100px" }}
            />
          </p>

          <p style={{ marginTop: "2vh", fontSize: "14px", color: "black" }}>
            {auth.f_name} {auth.l_name}
          </p>
          <p style={{ fontSize: "14px", marginTop: "1vh" }}>Admin</p>
        </div>

        <div className="details_card">
          <div className="in">
            <p>Details</p>

            <div className="profile-grid">
              <div className="container">
                <div>
                  <label>First Name:</label>
                </div>
                <div className="field">{auth.f_name}</div>
              </div>

              <div className="container">
                <div>
                  <label>Last Name:</label>
                </div>
                <div className="field">{auth.l_name}</div>
              </div>

              <div className="container">
                <div>
                  <label>Email:</label>
                </div>
                <div className="field">{auth.email}</div>
              </div>

              <div className="container">
                <div>
                  <label>Gender:</label>
                </div>
                <div className="field">{auth.gender}</div>
              </div>

              <div className="container">
                <div>
                  <label>Phone Number:</label>
                </div>
                <div className="field">{auth.phone}</div>
              </div>

              <div className="container">
                <div>
                  <label>Address:</label>
                </div>
                <div className="field">
                  {auth.city} {auth.state} {auth.country}
                </div>
              </div>

              <div
                className="container"
                style={{
                  marginTop: "4vh",
                  color: "black",
                  padding: "20px 10px",
                }}
              >
                {`Created On:  ${auth.created_on.substring(0, 10)}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
