import React, { useState } from "react";
import "./Search.css";
import { BsSearch } from "react-icons/bs";

const Search = ({ placeholder, handleClick = () => {}, size = "large" }) => {
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    const val = e.target.value;
    setValue(val);
    if (val === "") {
      handleClick("");
    }
  };

  return (
    <div className={size === "large" ? "search" : "small"}>
      <input
        className={size === "large" ? "input" : "input-small"}
        type="text"
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
      />
      <span style={{ cursor: "pointer" }} onClick={() => handleClick(value)}>
        <BsSearch />
      </span>
    </div>
  );
};

export default Search;
