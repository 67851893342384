/* eslint-disable no-shadow */

import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";

const makeRequest = new CustomerPage();

export default function ProgressLoader({
  openLoader,
  handleCloseLoader,
  data,
  _user,
  setOpenLoaderPin,
  action = "pin",
  limitData,
  restrictData,
}) {
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const user = _user[0];

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleClose = () => {
    // handleCloseLoader()
  };
  const datas = {
    userId: user.zikora_user_id === undefined ? user.id : user.zikora_user_id,
    newPin: data.newPin,
    password: data.adminPassword,
  };
  useEffect(() => {
    if (action === "pin") {
      makeRequest
        .adminChangePin(datas)
        .then((value) => {
          handleCloseLoader();
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setErrMsg("Admin password incorrect");
          if (error.response.status === 401) navigate("/login");
        });
    } else if (action === "limit") {
      const data = {
        newLimit: limitData.increase,
        accountId: limitData.accountId,
        pin: limitData.adminPin.toString(),
      };
      makeRequest
        .setTransferLimit(data)
        .then((value) => {
          handleCloseLoader();
        })
        .catch((error) => {
          setIsError(true);
          setErrMsg(error.response.data.data);
          if (error.response.status === 401) navigate("/login");
        });
    } else if (action === "restrict") {
      const data = {
        ...restrictData,
      };

      makeRequest
        .setTransferLimit(data)
        .then((value) => {
          handleCloseLoader();
        })
        .catch((error) => {
          setIsError(true);
          setErrMsg("Service not yet ready");
          if (error.response.status === 401) navigate("/login");
        });
    }
  }, []);

  return (
    <div>
      <Modal open={openLoader} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "390px",
            minHeight: "200px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            borderRadius: "0.5rem",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              marginLeft: "4rem",
              display: "flex",
              alignItems: "flex-start",
              gap: "2rem",
              fontSize: "28px",
              color: "red",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#252424", fontSize: "25px", fontWeight: "500" }}
            >
              Please wait...
            </Typography>
            {isError && (
              <Box sx={{ cursor: "pointer" }}>
                <ImCancelCircle
                  onClick={() => {
                    setOpenLoaderPin(false);
                  }}
                />
              </Box>
            )}
          </Box>

          {!isError && (
            <CircularProgress size={100} sx={{ color: "#83C79F" }} />
          )}
          {isError && (
            <Typography
              sx={{ color: "red", fontSize: "25px", fontWeight: "500" }}
            >
              {errMsg}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
}
