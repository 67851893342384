import { useState } from "react";
import Button from "./FormUtils/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const list = [10, 20, 30, 40, 50];

const Pagination = ({
  className,
  handleFetchPagination = () => {},
  currentPage,
  pageValue,
  loading,
  handleChange = () => {},
  handleChangeLimitRange = () => {},
  limit = false,
}) => {
  const [toggle, setToggle] = useState(false);
  const [valueIndex, setValueIndex] = useState(0);

  return (
    <div
      className={`self-end text-[14px] flex gap-2 items-center ${className}`}
    >
      <p>Current Page: {pageValue}</p>

      <div className="flex gap-2 items-center">
        <p>Goto:</p>
        <input
          onChange={handleChange}
          className="border-1 rounded w-[70px] p-1"
          type="number"
          value={currentPage}
        />
      </div>

      {limit && (
        <div className="flex gap-2 items-center">
          <p>Limit:</p>
          {/* <input 
                onChange={handleChangeLimitRange}
                className='border-1 rounded w-[70px] p-1' type="number"  value={limitRange}
            /> */}

          <ClickAwayListener onClickAway={() => setToggle(false)}>
            <div className="relative">
              <div
                onClick={() => setToggle((prev) => !prev)}
                className="min-w-[50px] px-1 py-1 bg-7FF cursor-pointer text-center rounded"
              >
                {list[valueIndex]}
              </div>

              {toggle && (
                <div className="absolute flex flex-col min-w-[50px] z-50 cursor-pointer bg-[white] shadow-shift-shadow-small">
                  {list.map((x, index) => (
                    <p
                      onClick={() => {
                        setValueIndex(index);
                        handleChangeLimitRange(x);
                      }}
                      className="px-2 py-2 text-center hover:bg-777 hover:text-[white] cursor-pointer"
                    >
                      {x}
                    </p>
                  ))}
                </div>
              )}
              {/* </ClickAwayListener> */}
            </div>
          </ClickAwayListener>
        </div>
      )}

      <Button
        handleClick={handleFetchPagination}
        className="w-[50px] rounded text-center !px-1 !py-1"
        text={"Fetch"}
        loading={loading}
      />
    </div>
  );
};

export default Pagination;
