/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import { MenuItem, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { FiEdit2 } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { conn } from "../../Utilt/conn";
import Loader from "../Loader/Loader";
import "./UsersAccount.css";

import ChangePin from "../ExistingAccount/changePin";
import ConfirmAdminPin from "../ExistingAccount/changePin/confirmPin";
import ProgressLoaderPin from "../ExistingAccount/changePin/loader";
import SuccessPin from "../ExistingAccount/changePin/success";
import BasicMenu from "../ExistingAccount/menu/menu";

import ChangePassword from "../ExistingAccount/changePassword";
import ConfirmAdminPassword from "../ExistingAccount/changePassword/confirmPassword";
import ProgressLoaderPassword from "../ExistingAccount/changePassword/loader";
import SuccessPassword from "../ExistingAccount/changePassword/success";

import avatarImage from "../../Assets/avatar-image.png";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import LimitModal from "./Widget/LimitModal";
import RestrictModal from "./Widget/RestrictModal";

export const NewUsersAccount = () => {
  const token = window.localStorage.getItem("token");
  const refreshtoken = window.localStorage.getItem("refreshtoken");

  const auth = JSON.parse(window.localStorage.getItem("auth"));

  const navigate = useNavigate();

  const location = useLocation();

  const [openD, setOpen] = useState(false);

  const [isloading, loading] = useState(true);
  const [data, setData] = useState([]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  const UsersAccountData = async () => {
    await axios
      .get(`${conn}/admin/customer_accounts/${location.state.id}`, config)
      .then((value) => {
        setData(value.data.data);
        loading(false);
      })
      .catch((error) => {
        console.log(error);
        loading(false);
        if (error.response.status === 401) navigate("/login");
      });
  };

  useEffect(() => {
    UsersAccountData();
  }, []);

  const handleCloseD = () => {
    setOpen(false);
  };

  const UserTierUpgrade = async (account_id, top_tier, admin_id) => {
    setOpen(true);

    await axios
      .put(
        `${conn}/admin/account/upgrade`,
        {
          account_id,
          to_tier: top_tier,
        },
        config,
      )
      .then((value) => {
        console.log(value.data);
        setOpen(false);
        setData(
          [...data].map((val) =>
            val.id === account_id ? { ...val, account_tier: top_tier } : val,
          ),
        );
      })
      .catch((error) => {
        setOpen(false);
      });
  };

  // CHANGE PIN AND PASSWORD, MENU AND MODAL
  const [openMenu, setOpenMenu] = React.useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  // Change Pin management
  const [openChangePin, setOpenChangePin] = useState(false);
  const [openConfirmPin, setOpenConfirmPin] = useState(false);
  const [openLoaderPin, setOpenLoaderPin] = useState(false);
  const [openSuccessPin, setOpenSuccessPin] = useState(false);
  const [adminNewPin, setAdminNewPin] = useState({});
  const [progressAction, setProgressAction] = useState("pin");

  const handleOpenChangePin = () => {
    setOpenChangePin(true);
  };

  const handleCloseChangePin = () => {
    setOpenChangePin(false);
    setOpenMenu(false);
  };
  const handleSubmitChangePin = (pinData) => {
    setAdminNewPin(pinData);
    setOpenChangePin(false);
    //  setOpenConfirmPin(true)
    setProgressAction("pin");
    setOpenLoaderPin(true);
  };

  const handleCloseConfirmPin = () => {
    setOpenConfirmPin(false);
  };
  const handleSubmitConfirm = () => {
    setOpenConfirmPin(false);
    setOpenLoaderPin(true);
  };

  const handleCloseLoader = () => {
    setOpenLoaderPin(false);
    setOpenSuccessPin(true);
  };

  const handleCloseSuccessPin = () => {
    setOpenSuccessPin(false);
  };

  // Change Password management
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
  const [openLoaderPassword, setOpenLoaderPassword] = useState(false);
  const [openSuccessPassword, setOpenSuccessPassword] = useState(false);
  const [adminNewPassword, setAdminNewPassword] = useState({});

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };
  const handleSubmitChangePassword = (passwordData) => {
    setAdminNewPassword(passwordData);
    setOpenChangePassword(false);
    setOpenConfirmPassword(true);
  };

  const handleCloseConfirmPassword = () => {
    setOpenConfirmPassword(false);
  };
  const handleSubmitConfirmPassword = () => {
    setOpenConfirmPassword(false);
    setOpenLoaderPassword(true);
  };

  const handleCloseLoaderPassword = () => {
    setOpenLoaderPassword(false);
    setOpenSuccessPassword(true);
  };

  const handleCloseSuccessPassword = () => {
    setOpenSuccessPassword(false);
  };

  // LIMIT management
  const [openLimitModal, setOpenLimitModal] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const [limitData, setLimitData] = useState(null);

  const handleCloseLimitModal = () => {
    setOpenLimitModal(false);
    setOpenMenu(false);
  };
  const handleOpenLimitModal = () => {
    setOpenLimitModal(true);
  };

  const handleSubmitLimitModal = (limitData) => {
    handleCloseLimitModal();
    setLimitData(limitData);
    setOpenConfirmPin(true);
    setProgressAction("limit");
  };

  // RESTRICT management
  const [openRestrictModal, setOpenRestrictModal] = useState(false);
  const [restrictData, setRestrictData] = useState("");

  const handleCloseRestrictModal = () => {
    setOpenRestrictModal(false);
    setOpenMenu(false);
  };
  const handleOpenRestrictModal = () => {
    setOpenRestrictModal(true);
  };

  const handleSubmitRetrictModal = (restrictData) => {
    console.log(restrictData);
    handleCloseRestrictModal();
    setRestrictData(restrictData);
    setOpenConfirmPin(true);
    setProgressAction("restrict");
  };

  return (
    <>
      <BasicMenu openMenu={openMenu} setOpenMenu={setOpenMenu}>
        <MenuItem onClick={handleOpenLimitModal}>
          Account Transfer Limit
        </MenuItem>
        <MenuItem onClick={handleOpenChangePin}>Change User’s PIN</MenuItem>
        <MenuItem sx={{ color: "red" }} onClick={handleOpenRestrictModal}>
          Restrict Account
        </MenuItem>
      </BasicMenu>

      {/* CHANGE PIN MANAGEMENT */}
      {openChangePin && (
        <ChangePin
          openChangePin={openChangePin}
          handleCloseChangePin={handleCloseChangePin}
          submitChangePin={(pinData) => handleSubmitChangePin(pinData)}
          data={data}
        />
      )}

      <ConfirmAdminPin
        openConfirm={openConfirmPin}
        handleCloseConfirm={handleCloseConfirmPin}
        handleSubmitConfirm={handleSubmitConfirm}
      />
      {openLoaderPin && (
        <ProgressLoaderPin
          openLoader={openLoaderPin}
          setOpenLoaderPin={setOpenLoaderPin}
          handleCloseLoader={handleCloseLoader}
          data={adminNewPin}
          limitData={limitData}
          restrictData={restrictData}
          action={progressAction}
          _user={data}
        />
      )}
      {openSuccessPin && (
        <SuccessPin
          openSuccesPin={openSuccessPin}
          handleCloseSuccessPin={handleCloseSuccessPin}
          _user={data}
          action={progressAction}
          limitData={limitData}
          pin={adminNewPin}
        />
      )}

      {/* CHANGE PASSWORD MANAGEMENT */}
      {openChangePassword && (
        <ChangePassword
          openChangePassword={openChangePassword}
          handleCloseChangePassword={handleCloseChangePassword}
          submitChangePassword={(passwordData) =>
            handleSubmitChangePassword(passwordData)
          }
          data={data}
        />
      )}
      <ConfirmAdminPassword
        openConfirm={openConfirmPassword}
        handleCloseConfirm={handleCloseConfirmPassword}
        handleSubmitConfirm={handleSubmitConfirmPassword}
      />
      {openLoaderPassword && (
        <ProgressLoaderPassword
          openLoader={openLoaderPassword}
          handleCloseLoader={handleCloseLoaderPassword}
          data={adminNewPassword}
          _user={data}
        />
      )}
      {openSuccessPassword && (
        <SuccessPassword
          openSuccesPassword={openSuccessPassword}
          handleCloseSuccessPassword={handleCloseSuccessPassword}
          _user={data}
          password={adminNewPassword}
        />
      )}

      {/* LIMIT MANAGEMENT */}
      {openLimitModal && (
        <LimitModal
          openLimitModal={openLimitModal}
          handleCloseLimitModal={handleCloseLimitModal}
          data={accountData}
          handleSubmit={(data) => handleSubmitLimitModal(data)}
        />
      )}

      {/* RESTRICT ACCOUNT MANGEMENT */}
      {openRestrictModal && (
        <RestrictModal
          openRestrictModal={openRestrictModal}
          handleRestrictModal={handleCloseRestrictModal}
          data={accountData}
          handleSubmit={(data) => handleSubmitRetrictModal(data)}
        />
      )}

      <MainLayout title="Customer Accounts">
        <div className="in">
          <Stack
            direction={"row"}
            spacing={2}
            my={"30px"}
            py={"20px"}
            sx={{ width: "100%", overflowX: "auto" }}
          >
            <WrapperContainer style={{ flexDirection: "row", gap: "10px" }}>
              <img
                src={location.state.image ?? avatarImage}
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
              />

              <Stack direction={"column"} alignItems={"center"}>
                <Typography sx={{ color: "#252424", fontSize: "20px" }}>
                  {location.state.user}
                </Typography>
                <Fade duration={1000}>
                  <Typography sx={{ color: "#66A681", fontSize: "30px" }}>
                    {data.length}
                  </Typography>
                </Fade>
                <Typography sx={{ color: "#565555", fontSize: "14px" }}>
                  Total Number of Accounts
                </Typography>
              </Stack>
            </WrapperContainer>
            {data.map((val, index) => (
              <WrapperContainer
                style={{ border: "1px dashed", borderSpacing: "100px" }}
              >
                <Typography sx={{ color: "#565555", fontSize: "14px" }}>
                  Account {index + 1}
                </Typography>
                <Fade duration={1000}>
                  <Typography sx={{ color: "#252424", fontSize: "18px" }}>
                    {val.nuban}
                  </Typography>
                </Fade>
                <Fade duration={1000}>
                  <Typography
                    sx={{ color: "#66A681", fontSize: "30px", mt: "10px" }}
                  >
                    {(val.balance / 100).toLocaleString() ?? 0}
                  </Typography>
                </Fade>
                <Typography sx={{ color: "#565555", fontSize: "14px" }}>
                  Account Balance
                </Typography>
              </WrapperContainer>
            ))}
          </Stack>

          <div className="w-full">
            <p>Accounts</p>

            {!isloading ? (
              <div className="max-h-[70vh] min-h-[47vh] w-full overflow-y-auto">
                <table className="w-full">
                  <tr className="sticky w-full text-[black] top-0 z-10 bg-light-gray text-[16px] border-b-1 ">
                    <td className="py-5 px-3">Name</td>
                    <td>Nuban</td>
                    <td>Date Of Creation</td>
                    <td>Tier</td>
                    <td>Transactions</td>
                    <td>Secuirity</td>
                  </tr>

                  {data.map((user, key) => (
                    <tr key={key}>
                      <td className="py-3 px-3">
                        <Fade duration={1000}>{user.account_name}</Fade>
                      </td>
                      <td>
                        <Fade duration={1000}>
                          <span className="numba">{user.nuban}</span>
                        </Fade>
                      </td>
                      <td>
                        <Fade duration={1000}>
                          <span className="numba">
                            {user.created_at.substring(0, 10)}
                          </span>
                        </Fade>
                      </td>

                      <td>
                        <span className="select-cont">
                          <select
                            value={user.account_tier}
                            onChange={(e) =>
                              UserTierUpgrade(user.id, e.target.value, auth.id)
                            }
                          >
                            <option value="1">Tier 1</option>
                            <option value="2">Tier 2</option>
                            <option value="3">Tier 3</option>
                          </select>
                        </span>
                      </td>

                      <td>
                        <Fade duration={1000}>
                          <span
                            onClick={() =>
                              navigate("/new-customers/transactions", {
                                state: {
                                  id: location.state.id,
                                  nuban: user.nuban,
                                },
                              })
                            }
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              color: "green",
                            }}
                          >
                            See Transactions
                          </span>
                        </Fade>
                      </td>
                      <td>
                        <FiEdit2
                          className="edit-icon"
                          onClick={(e) => {
                            handleOpenMenu(e);
                            setAccountData(user);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ) : (
              <center>
                <Loader />
              </center>
            )}
          </div>
        </div>

        <Dialog
          open={openD}
          onClose={handleCloseD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <center>
              <div style={{ width: "200px", padding: "30px" }}>
                <p className="dialog-text">
                  Okay, We're upgrading the Account Tier now
                </p>
                <br />

                <div>
                  <div style={{ backgroundColor: "#4c6356" }} />
                  <div style={{ backgroundColor: "#4c6356" }} />
                </div>
              </div>
            </center>
          </DialogContent>
        </Dialog>
      </MainLayout>
    </>
  );
};

const WrapperContainer = ({ children, style }) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        border: "1px solid #E4E4E4",
        minWidth: "356px",
        height: "128px",
        borderRadius: "10px",
        padding: "10px",
        ...style,
      }}
    >
      {children}
    </Stack>
  );
};
