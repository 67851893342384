/* eslint-disable camelcase */
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import { getConfig } from "../../Utilt/Config";
import { conn } from "../../Utilt/conn";
import Loader from "../Loader/Loader";
import "./ExistingAccount.css";

import ChangePin from "./changePin";
import ConfirmAdminPin from "./changePin/confirmPin";
import ProgressLoaderPin from "./changePin/loader";
import SuccessPin from "./changePin/success";
import BasicMenu from "./menu/menu";

import { useDispatch, useSelector } from "react-redux";
import { getAccountByNumberThunk } from "../../ReduxStore/Customers";
import Search from "../../ReuseComponent/FormUtils/Search";
import ChangePassword from "./changePassword";
import ConfirmAdminPassword from "./changePassword/confirmPassword";
import ProgressLoaderPassword from "./changePassword/loader";
import SuccessPassword from "./changePassword/success";

export const NewExistingAccounts = () => {
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const navigate = useNavigate();

  const [openD, setOpen] = useState(false);

  const dispatch = useDispatch();
  const singleAccount = useSelector((state) => state.customers)?.account;
  const loading = useSelector((state) => state.customers)?.loading;

  const handleCloseD = () => {
    setOpen(false);
  };

  const [data, setData] = useState([]);
  const [nuban, setNuban] = useState("");

  const getUsersAccountData = async (_input) => {
    dispatch(getAccountByNumberThunk({ nuban: _input }));
  };

  const UserTierUpgrade = async (account_id, top_tier, admin_id) => {
    setOpen(true);
    await axios
      .put(
        `${conn}/admin/account/upgrade`,
        {
          account_id,
          to_tier: top_tier,
        },
        getConfig(),
      )
      .then((value) => {
        setOpen(false);
        setData(
          [...data].map((val) =>
            val.id === account_id ? { ...val, account_tier: top_tier } : val,
          ),
        );
      })
      .catch((error) => {
        setOpen(false);
        if (error.response.status === 401) navigate("/login");
      });
  };

  // CHANGE PIN AND PASSWORD, MENU AND MODAL
  const [openMenu, setOpenMenu] = React.useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  // Change Pin management
  const [openChangePin, setOpenChangePin] = useState(false);
  const [openConfirmPin, setOpenConfirmPin] = useState(false);
  const [openLoaderPin, setOpenLoader] = useState(false);
  const [openSuccessPin, setOpenSuccessPin] = useState(false);
  const [adminNewPin, setAdminNewPin] = useState({});

  const handleOpenChangePin = () => {
    setOpenChangePin(true);
  };

  const handleCloseChangePin = () => {
    setOpenChangePin(false);
  };
  const handleSubmitChangePin = (pinData) => {
    setAdminNewPin(pinData);
    setOpenChangePin(false);
    setOpenConfirmPin(true);
  };

  const handleCloseConfirmPin = () => {
    setOpenConfirmPin(false);
  };
  const handleSubmitConfirm = () => {
    setOpenConfirmPin(false);
    setOpenLoader(true);
  };

  const handleCloseLoaderPin = () => {
    setOpenLoader(false);
    setOpenSuccessPin(true);
  };

  const handleCloseSuccessPin = () => {
    setOpenSuccessPin(false);
  };

  // Change Password management
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
  const [openLoaderPassword, setOpenLoaderPassword] = useState(false);
  const [openSuccessPassword, setOpenSuccessPassword] = useState(false);
  const [adminNewPassword, setAdminNewPassword] = useState({});

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };
  const handleSubmitChangePassword = (passwordData) => {
    setAdminNewPassword(passwordData);
    setOpenChangePassword(false);
    setOpenConfirmPassword(true);
  };

  const handleCloseConfirmPassword = () => {
    setOpenConfirmPassword(false);
  };
  const handleSubmitConfirmPassword = () => {
    setOpenConfirmPassword(false);
    setOpenLoaderPassword(true);
  };

  const handleCloseLoaderPassword = () => {
    setOpenLoaderPassword(false);
    setOpenSuccessPassword(true);
  };

  const handleCloseSuccessPassword = () => {
    setOpenSuccessPassword(false);
  };

  return (
    <div className="">
      <BasicMenu
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        handleOpenChangePin={handleOpenChangePin}
        handleOpenChangePassword={handleOpenChangePassword}
      />

      {/* CHANGE PIN MANAGEMENT */}
      {openChangePin && (
        <ChangePin
          openChangePin={openChangePin}
          handleCloseChangePin={handleCloseChangePin}
          submitChangePin={(pinData) => handleSubmitChangePin(pinData)}
          data={data}
        />
      )}
      <ConfirmAdminPin
        openConfirm={openConfirmPin}
        handleCloseConfirm={handleCloseConfirmPin}
        handleSubmitConfirm={handleSubmitConfirm}
      />
      {openLoaderPin && (
        <ProgressLoaderPin
          openLoader={openLoaderPin}
          handleCloseLoader={handleCloseLoaderPin}
          data={adminNewPin}
          _user={data}
        />
      )}
      {openSuccessPin && (
        <SuccessPin
          openSuccesPin={openSuccessPin}
          handleCloseSuccessPin={handleCloseSuccessPin}
          _user={data}
          pin={adminNewPin}
        />
      )}

      {/* CHANGE PASSWORD MANAGEMENT */}
      {openChangePassword && (
        <ChangePassword
          openChangePassword={openChangePassword}
          handleCloseChangePassword={handleCloseChangePassword}
          submitChangePassword={(passwordData) =>
            handleSubmitChangePassword(passwordData)
          }
          data={data}
        />
      )}
      <ConfirmAdminPassword
        openConfirm={openConfirmPassword}
        handleCloseConfirm={handleCloseConfirmPassword}
        handleSubmitConfirm={handleSubmitConfirmPassword}
      />
      {openLoaderPassword && (
        <ProgressLoaderPassword
          openLoader={openLoaderPassword}
          handleCloseLoader={handleCloseLoaderPassword}
          data={adminNewPassword}
          _user={data}
        />
      )}
      {openSuccessPassword && (
        <SuccessPassword
          openSuccesPassword={openSuccessPassword}
          handleCloseSuccessPassword={handleCloseSuccessPassword}
          _user={data}
          password={adminNewPassword}
        />
      )}

      <MainLayout title="Accounts">
        <div className="flex flex-col gap-5 mt-5">
          <Search
            className="w-full"
            placeholder={"Enter Account Number to find Acccount"}
            handleChange={(e) => setNuban(e?.target?.value)}
            handleClick={() => getUsersAccountData(nuban)}
          />

          <div className="flex flex-col gap-2">
            <p>Recent account searches</p>

            {!loading ? (
              <div className="max-h-[70vh] w-full overflow-y-auto">
                {singleAccount !== null && singleAccount !== undefined ? (
                  <table className="w-full">
                    <tr className="sticky w-full top-0 z-10 bg-light-gray text-[16px]">
                      <td className="py-3 px-3 w-[25%]">Name</td>
                      <td className="w-[20%]">Nuban</td>
                      <td className="w-[20%]">Date Of Creation</td>
                      <td className="w-[20%]">Tier</td>
                      <td className="w-[25%]">Transactions</td>
                      {/* <td>Secuirity</td> */}
                    </tr>
                    {[singleAccount].map((user) => (
                      <tr>
                        <td className="py-3 px-3">
                          <Fade duration={1000}>{user.account_name}</Fade>
                        </td>
                        <td>
                          <Fade duration={1000}>{user.nuban}</Fade>
                        </td>
                        <td>
                          <Fade duration={1000}>
                            {user.created_at.substring(0, 10)}
                          </Fade>
                        </td>
                        <td>
                          <Fade duration={1000}>
                            {" "}
                            <span className="select-cont">
                              {" "}
                              <select
                                value={user.account_tier}
                                onChange={(e) =>
                                  UserTierUpgrade(
                                    user.id,
                                    e.target.value,
                                    auth.id,
                                  )
                                }
                              >
                                <option value="1">Tier 1</option>
                                <option value="2">Tier 2</option>
                                <option value="3">Tier 3</option>
                              </select>
                            </span>
                          </Fade>
                        </td>
                        <td>
                          <Fade duration={1000}>
                            <div
                              style={{ cursor: "pointer", color: "green" }}
                              onClick={() =>
                                navigate("/user-account/transactions", {
                                  state: { id: user.id, nuban: user.nuban },
                                })
                              }
                            >
                              See transactions
                            </div>
                          </Fade>
                        </td>
                        {/* <td>
                                                    <FiEdit2 className="edit-icon" onClick={handleOpenMenu}/>
                                                </td> */}
                      </tr>
                    ))}
                  </table>
                ) : (
                  <div className="text-center mt-3">Account Not Found</div>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </MainLayout>

      <Dialog
        open={openD}
        onClose={handleCloseD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <center>
            <div style={{ width: "200px", padding: "30px" }}>
              <p className="dialog-text">
                Okay, We're upgrading the Account Tier now
              </p>
              <br />

              <div>
                <div style={{ backgroundColor: "#4c6356" }} />
                <div style={{ backgroundColor: "#4c6356" }} />
              </div>
            </div>
          </center>
        </DialogContent>
      </Dialog>
    </div>
  );
};
