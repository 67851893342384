/* eslint-disable no-param-reassign */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLimitRequest,
  getSingleRequest,
  approveLimit,
  disapproveLimit,
} from "../Network/Request";

export const getRequestThunk = createAsyncThunk(
  "request/getLimitRequest",
  getLimitRequest,
);
export const getSingleRequestThunk = createAsyncThunk(
  "request/getSingleRequest",
  getSingleRequest,
);
export const approveLimitThunk = createAsyncThunk(
  "request/approveLimit",
  approveLimit,
);
export const disapproveLimitThunk = createAsyncThunk(
  "request/disapproveLimit",
  disapproveLimit,
);

export const getRequestWithoutLoadingThunk = createAsyncThunk(
  "request/getLimitRequest",
  getLimitRequest,
);

const initialState = {
  requests: [],
  singleRequest: null,
  loading: false,
  loadingApprove: false,
};

const RequestSlice = createSlice({
  name: "request",
  initialState,

  extraReducers: {
    [getRequestThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getRequestThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.data || [];
    },
    [getRequestThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getRequestWithoutLoadingThunk.pending]: (state, action) => {
      // state.loading = true;
    },
    [getRequestWithoutLoadingThunk.fulfilled]: (state, action) => {
      // state.loading = false;
      state.requests = action.payload.data;
    },
    [getRequestWithoutLoadingThunk.rejected]: (state, action) => {
      // state.loading = false;
    },

    [getSingleRequestThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getSingleRequestThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleRequest = action.payload.data;
    },
    [getSingleRequestThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [approveLimitThunk.pending]: (state, action) => {
      state.loadingApprove = true;
    },
    [approveLimitThunk.fulfilled]: (state, action) => {
      state.loadingApprove = false;
    },
    [approveLimitThunk.rejected]: (state, action) => {
      state.loadingApprove = false;
    },

    [disapproveLimit.pending]: (state, action) => {
      state.loadingApprove = true;
    },
    [disapproveLimit.fulfilled]: (state, action) => {
      state.loadingApprove = false;
    },
    [disapproveLimit.rejected]: (state, action) => {
      state.loadingApprove = false;
    },
  },
});

export default RequestSlice.reducer;
