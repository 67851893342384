/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable no-unsafe-optional-chaining */

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderRow from "./Widgets/HeaderRow/HeaderRow";
import TransactionValue from "./Widgets/TrasactionValue/TransactionValue";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineSend, AiOutlineCloseCircle } from "react-icons/ai";
import Loader from "../../ReuseComponent/Loader";
import { toast } from "react-toastify";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import Button from "../../ReuseComponent/FormUtils/Button";
import Confirm from "./Widgets/Confirm";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomerInfoThunk,
  adminUpdateUserInfoThunk,
  getCustomerInfoNoLoadingThunk,
} from "../../ReduxStore/Customers";
import { InputNoFormik } from "../../ReuseComponent/FormUtils/Input";
import dayjs from "dayjs";

const checkGender = (gen) => {
  if (Number(gen) === 0) return "Female";
  if (Number(gen) === 1) return "Male";
  if (gen?.toLowerCase() === "male") return 1;
  if (gen?.toLowerCase() === "female") return 0; //
};

export const NewCustomerSummary = ({ auth }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const [chart, setChart] = useState([]);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [editProfile, setEditProfile] = useState(false);

  const [customerInfo, setCustomerInfo] = useState(null);
  const loadingg = useSelector((state) => state.customers)?.loading;
  const loadingUpdateUserInfo = useSelector(
    (state) => state.customers,
  )?.loadingUpdateUserInfo;

  const [bvn, setBvn] = useState(customerInfo?.bvn);
  const [openEditBvn, setOpenEditBvn] = useState(false);

  const [initialValuesForm, setInitialValuesForm] = useState({
    f_name: customerInfo?.f_name,
    l_name: customerInfo?.l_name,
    email: customerInfo?.email,
    home_address: customerInfo?.home_address,
    phone: customerInfo?.phone,
    gender: checkGender(customerInfo?.gender),
  });

  const loadUserInfo = () => {
    dispatch(
      getCustomerInfoNoLoadingThunk({ user_id: location.state.id.toString() }),
    ).then((val) => {
      if (val?.payload?.status === 200) {
        const newData = val.payload?.data?.data;
        setCustomerInfo(newData);
        setBvn(newData?.bvn);
        setInitialValuesForm({
          f_name: newData?.f_name,
          l_name: newData?.l_name,
          email: newData?.email,
          home_address: newData?.home_address,
          phone: newData?.phone,
          gender: checkGender(newData?.gender),
        });

        setChart([...[...newData?.transInAndOut].reverse()]);
      }
    });
  };

  const UpdateBvn = () => {
    const data = { bvn: bvn.toString() };

    dispatch(
      adminUpdateUserInfoThunk({
        data,
        id: location?.state?.id,
      }),
    ).then((val) => {
      if (val?.payload?.status === 200) {
        setOpenEditBvn(false);
        loadUserInfo();
        toast?.success("Bvn updated");
        setOpenConfirmModal(false);
      }
    });
  };

  useEffect(() => {
    dispatch(
      getCustomerInfoThunk({ user_id: location.state.id.toString() }),
    ).then((val) => {
      if (val?.payload?.status === 200) {
        const newData = val.payload?.data?.data;
        setCustomerInfo(newData);
        setBvn(newData?.bvn);
        setInitialValuesForm({
          f_name: newData?.f_name,
          l_name: newData?.l_name,
          email: newData?.email,
          home_address: newData?.home_address,
          phone: newData?.phone,
          gender: checkGender(newData?.gender),
        });

        setChart([...[...newData?.transInAndOut].reverse()]);
      }
    });
  }, []);

  const handleSubmitEditProfile = () => {
    const data = {
      ...initialValuesForm,
      gender:
        initialValuesForm?.gender <= 1
          ? initialValuesForm?.gender.toString()
          : checkGender(initialValuesForm?.gender).toString(),
    };

    dispatch(adminUpdateUserInfoThunk({ data, id: location?.state?.id })).then(
      (val) => {
        if (val?.payload?.status === 200) {
          setEditProfile(false);
          toast.success("User info updated");
          loadUserInfo();
        }
      },
    );
  };

  const handleCancelEditProfile = () => {
    setEditProfile(false);
    setInitialValuesForm({
      f_name: customerInfo?.f_name,
      l_name: customerInfo?.l_name,
      email: customerInfo?.email,
      home_address: customerInfo?.home_address,
      phone: customerInfo?.phone,
      gender: checkGender(customerInfo?.gender),
    });
  };

  return (
    <MainLayout title={"Customer details"}>
      {!loadingg ? (
        <div className="w-full flex flex-col gap-10">
          <HeaderRow
            img={location.state.image}
            dta={location.state}
            customer={customerInfo}
          />

          {customerInfo !== null && <TransactionValue transCount={chart} />}

          <div className="details_card" style={{ width: "100%" }}>
            <div className="in" style={{ width: "100%", padding: "0px" }}>
              <p>Personal Details</p>

              <div style={{ marginTop: "10px" }}>
                {!editProfile ? (
                  <Button
                    handleClick={() => setEditProfile(true)}
                    className="!px-3 !py-1 min-w-24"
                    text={"Edit Profile"}
                    outline
                    Icon={<BiEditAlt />}
                  />
                ) : (
                  <Button
                    handleClick={handleCancelEditProfile}
                    className="!px-3 !py-1 w-20"
                    text={"Cancel"}
                    outline
                  />
                )}
              </div>

              <div className="flex gap-8 flex-wrap w-full mt-4 text-[black]">
                <InputNoFormik
                  title="First Name:"
                  withTitle
                  className={`!w-[45%] `}
                  inputStyle={`!py-4 ${editProfile ? "border-1 border-main-color" : "border-none"}`}
                  placeholder="Enter email"
                  name="f_name"
                  disabled={!editProfile}
                  value={initialValuesForm?.f_name}
                  handleChange={(e) =>
                    setInitialValuesForm({
                      ...initialValuesForm,
                      f_name: e.target.value,
                    })
                  }
                />

                <InputNoFormik
                  title="Last Name:"
                  withTitle
                  className={`!w-[45%] `}
                  inputStyle={`!py-4 ${editProfile ? "border-1 border-main-color" : "border-none"}`}
                  placeholder="Enter Last Name"
                  name="l_name"
                  disabled={!editProfile}
                  value={initialValuesForm?.l_name}
                  handleChange={(e) =>
                    setInitialValuesForm({
                      ...initialValuesForm,
                      l_name: e.target.value,
                    })
                  }
                />

                <InputNoFormik
                  title="Email:"
                  withTitle
                  className={`!w-[45%] `}
                  inputStyle={`!py-4 ${editProfile ? "border-1 border-main-color" : "border-none"}`}
                  placeholder="Enter Email"
                  name="email"
                  disabled={!editProfile}
                  value={initialValuesForm?.email}
                  handleChange={(e) =>
                    setInitialValuesForm({
                      ...initialValuesForm,
                      email: e.target.value,
                    })
                  }
                />

                <InputNoFormik
                  title="Gender:"
                  withTitle
                  className={`!w-[45%] `}
                  inputStyle={`!py-4 ${editProfile ? "border-1 border-main-color" : "border-none"}`}
                  placeholder="Enter Gender"
                  name="gender"
                  disabled={!editProfile}
                  value={initialValuesForm?.gender}
                  handleChange={(e) =>
                    setInitialValuesForm({
                      ...initialValuesForm,
                      gender: e.target.value,
                    })
                  }
                />

                <InputNoFormik
                  title="Phone Number:"
                  withTitle
                  className={`!w-[45%] `}
                  inputStyle={`!py-4 ${editProfile ? "border-1 border-main-color" : "border-none"}`}
                  placeholder="Enter Phone Number"
                  name="phone"
                  disabled={!editProfile}
                  value={initialValuesForm?.phone}
                  handleChange={(e) =>
                    setInitialValuesForm({
                      ...initialValuesForm,
                      phone: e.target.value,
                    })
                  }
                />

                <InputNoFormik
                  title="Address:"
                  withTitle
                  className={`!w-[45%] `}
                  inputStyle={`!py-4 ${editProfile ? "border-1 border-main-color" : "border-none"}`}
                  placeholder="Enter Address"
                  name="address"
                  disabled={!editProfile}
                  value={initialValuesForm?.home_address}
                  handleChange={(e) =>
                    setInitialValuesForm({
                      ...initialValuesForm,
                      home_address: e.target.value,
                    })
                  }
                />

                <div className="flex gap-2 items-center w-[45%]">
                  <InputNoFormik
                    title="Bvn:"
                    withTitle
                    className={`!w-[100%] `}
                    inputStyle={`!py-4 ${openEditBvn ? "border-1 border-main-color" : "border-none"}`}
                    placeholder="Enter Address"
                    name="address"
                    disabled={!openEditBvn}
                    value={bvn}
                    handleChange={(e) => setBvn(e.target.value)}
                  />

                  <div className="mt-3">
                    {!openEditBvn ? (
                      <BiEditAlt
                        className="cursor-pointer text-[18px]"
                        onClick={() => setOpenEditBvn(!openEditBvn)}
                      />
                    ) : (
                      <div className="flex gap-3">
                        <AiOutlineCloseCircle
                          className="cursor-pointer text-[18px]"
                          onClick={() => {
                            setOpenEditBvn(false);
                            setBvn(customerInfo?.bvn);
                          }}
                        />

                        <AiOutlineSend
                          className="cursor-pointer text-[18px]"
                          onClick={() => setOpenConfirmModal(true)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="px-3 py-2 mt-7 w-[45%] text-[16px] bg-light-gray">
                  {dayjs(customerInfo?.updated_at).format("DD MMMM YYYY")}
                </div>

                {editProfile && (
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "flex-end",
                      margin: "30px",
                    }}
                  >
                    {!loadingUpdateUserInfo ? (
                      <Button
                        handleClick={handleSubmitEditProfile}
                        className=""
                        text={"Submit"}
                      />
                    ) : (
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <Confirm
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleAction={UpdateBvn}
        loading={loadingUpdateUserInfo}
      />
    </MainLayout>
  );
};
