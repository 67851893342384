import React from "react";
import { Formik } from "formik";
import { Stack, Typography } from "@mui/material";
import * as Yup from "yup";

import ModalTemplate from "./ModalTemplate";
import {
  TextAndField,
  Field,
  FillButton,
} from "../../../ReuseComponent/FormUtils/FormUtils";

const RestrictModal = ({
  openRestrictModal,
  handleRestrictModal,
  data,
  handleSubmit,
}) => {
  return (
    <ModalTemplate
      title="Restrict Account"
      openModalTemplate={openRestrictModal}
      handleCloseModalTemplate={handleRestrictModal}
      style={{ minHeight: "350px" }}
    >
      <Stack width={"80%"} direction={"column"} justifyContent={"center"}>
        <Formik
          initialValues={{
            reason: "",
          }}
          validationSchema={Yup.object({
            reason: Yup.string().min(10).required("required"),
          })}
          onSubmit={(values) => {
            const val = {
              ...values,
              accountId: data.id,
            };
            handleSubmit(val);
          }}
        >
          {(props) => {
            return (
              <Stack spacing={"30px"}>
                <TextAndField>
                  <Typography sx={{ color: "#404040" }}>
                    Enter reasons for account restriction.
                  </Typography>
                  <Field
                    multiline
                    maxRows={8}
                    name="reason"
                    width={"100%"}
                    size="large"
                    placeholder="Here, you can write your reason why account restriction is taking place."
                  />
                </TextAndField>

                <FillButton text={"Submit"} callBack={props.handleSubmit} />
              </Stack>
            );
          }}
        </Formik>
      </Stack>
    </ModalTemplate>
  );
};

export default RestrictModal;

const FieldContainer = ({ children }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      width={"100%"}
      sx={{ borderBottom: "2px solid black" }}
    >
      {children}
    </Stack>
  );
};
