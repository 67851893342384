/* eslint-disable no-shadow */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImCancelCircle } from "react-icons/im";
import { BsExclamationCircle } from "react-icons/bs";

export default function ChangePassword({
  openChangePassword,
  handleCloseChangePassword,
  submitChangePassword,
  data,
}) {
  const user = data[0];
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleClose = () => {
    handleCloseChangePassword();
  };
  const submit = () => {
    if (newPassword === "" || confirmPassword === "") {
      setError(true);
      setErrorMessage("Field can  not be empty");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError(true);
      setErrorMessage("Password Mismatch");
      return;
    }
    const data = {
      newPassword,
      confirmPassword,
    };
    submitChangePassword(data);
  };

  const auth = JSON.parse(window.localStorage.getItem("auth"));

  return (
    <div>
      <Modal open={openChangePassword} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "640px",
            height: "456px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            borderRadius: "0.5rem",
            gap: "3rem",
          }}
        >
          {/* row heading */}
          <Box
            sx={{
              marginLeft: "7rem",
              display: "flex",
              alignItems: "flex-start",
              gap: "7rem",
              fontSize: "28px",
              color: "red",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#252424", fontSize: "25px", fontWeight: "500" }}
            >
              Change User’s PASSWORD
            </Typography>
            <Box sx={{ cursor: "pointer" }}>
              <ImCancelCircle onClick={handleClose} />
            </Box>
          </Box>

          {/* row describtion */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "70%",
              gap: "2rem",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${auth.image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: "24px",
                width: "60px",
                height: "50px",
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#404040",
                textAlign: "center",
              }}
            >
              Admin {auth.f_name}, you are about to change {user.account_name}’s
              Account PASSWORD.
            </Typography>
          </Box>

          {/* row form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "55%",
              gap: "1.5rem",
            }}
          >
            <TextField
              id="standard-basic"
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              variant="standard"
            />
            <TextField
              id="standard-basic"
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              variant="standard"
            />
            {error && (
              <Typography sx={{ color: "red", fontSize: "14px" }}>
                {errorMessage}
              </Typography>
            )}
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Typography sx={{ fontSize: "18px", color: "#FF4040" }}>
                <BsExclamationCircle />
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#FF4040" }}>
                Please note that this change will be on record.
              </Typography>
            </Box>
          </Box>

          {/* row button */}
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: "50%",
              backgroundColor: "#66A681",
              fontSize: "18px",
              textTransform: "none",
              ":hover": {
                backgroundColor: "rgba(102, 166, 129, 0.7)",
              },
            }}
            onClick={submit}
          >
            Next
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
