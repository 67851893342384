/* eslint-disable react/require-default-props */

import Header from "./Header";
import Nav from "./Nav";
import Loader from "../Loader";
import PropTypes from "prop-types";

const MainLayout = ({ children, title = "Metrics", isLoading = false }) => {
  return (
    <div className="flex flex-col h-[100%] w-[100%]">
      <Nav />

      <div className="w-full ">
        <Header title={title} />

        <div className="pl-[208px] pt-[90px] pb-3">
          <div className="px-5">{!isLoading ? children : <Loader />}</div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default MainLayout;
