import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";

const makeRequest = new CustomerPage();

export default function ProgressLoader({
  openLoader,
  handleCloseLoader,
  formData,
}) {
  console.log(formData);
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  // const user = _user[0]

  const handleClose = () => {
    handleCloseLoader();
  };

  // useEffect(()=>{
  //   makeRequest.adminChangePassword(datas).then((value)=>{
  //         // console.log(value.data)
  //         handleCloseLoader()
  //     }).catch((error)=>{
  //         console.log(error);

  //     });
  // },[])

  return (
    <div>
      <Modal open={openLoader} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "390px",
            height: "200px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            borderRadius: "0.5rem",
            justifyContent: "space-between",
          }}
        >
          {/* row heading */}

          <Typography
            sx={{ color: "#252424", fontSize: "25px", fontWeight: "500" }}
          >
            Please wait...
          </Typography>
          <CircularProgress size={100} sx={{ color: "#83C79F" }} />
        </Box>
      </Modal>
    </div>
  );
}
