import zikoraApi from "./api";

const notification = zikoraApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: () => `/admin/get-unread-notifications`,

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Notifications", id })),
              { type: "Notifications", id: "LIST" },
            ]
          : [{ type: "Notifications", id: "LIST" }],
    }),

    updateNotification: builder.mutation({
      query(id) {
        return {
          url: `admin/update-notification-status?notificationId=${id}`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Notifications", id },
      ],
    }),
  }),
});

export const { useGetAllNotificationsQuery, useUpdateNotificationMutation } =
  notification;
