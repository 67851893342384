import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Stack, Box } from "@mui/material";

export const AppTable = ({ setPageIndex, data, setSelectedUser }) => {
  return (
    <TableContainer sx={{ height: "98%", overflowY: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ position: "sticky", top: "0", bgcolor: "white" }}>
          <TableRow>
            <CellHeader text={"Name"} />
            <CellHeader align={"center"} text={"No of Accounts"} />
            <CellHeader align={"center"} text={"ACCOUNT STATUS"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={`${row.bvn} ${row.id}`}
              sx={{
                "&:nth-child(2)": { bgcolor: "#FCFCFC" },
                cursor: "pointer",
                "&:hover": { bgcolor: "#efefef" },
              }}
              onClick={() => {
                setPageIndex(1);
                setSelectedUser(row);
              }}
            >
              <CellBody text={`${row.f_name} ${row.l_name}`} />
              <CellBody align={"center"} text={row.bvn} />
              <TableCell align="center" sx={{ border: "none" }}>
                <Stack direction={"row"} justifyContent={"center"}>
                  <Status text={row.approved} />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CellHeader = ({ text, align = "left" }) => {
  return (
    <TableCell
      align={align}
      sx={{ fontSize: "12px", color: "#404040", border: "none", width: "33%" }}
    >
      {text}
    </TableCell>
  );
};

const CellBody = ({ text, align = "left" }) => {
  return (
    <TableCell
      align={align}
      sx={{ fontSize: "16px", color: "#252424", border: "none", width: "33%" }}
    >
      {text}
    </TableCell>
  );
};

const Status = ({ text }) => {
  let indicator;
  if (text === 1) indicator = "approved";
  if (text === 0) indicator = "pending";
  if (text === -1) indicator = "disapproved";

  const theme = {
    approved: {
      bg: "#E0FFE9",
      color: "#1AC847",
    },

    pending: {
      bg: "#ffffcd",
      color: "#b3b300",
    },

    disapproved: {
      bg: "#FF9A8C",
      color: "#FF4040",
    },
  };

  return (
    <Stack
      direction={"row"}
      spacing={1}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        bgcolor: theme[indicator].bg,
        color: theme[indicator].color,
        borderRadius: "10px",
        width: "100px",
        padding: "5px 0",
      }}
    >
      <Box
        sx={{
          width: "7px",
          height: "7px",
          borderRadius: "3px",
          bgcolor: theme[indicator].color,
        }}
      />
      <Typography fontSize={"12px"}>{indicator}</Typography>
    </Stack>
  );
};
