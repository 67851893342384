import { useState } from "react";
import Proptypes from "prop-types";
import { AiOutlineCaretDown } from "react-icons/ai";
import trimText from "../Utilt/trimText";
import { Menu } from "@mui/material";

const CustomDropdown = ({
  data,
  value = { label: "Select", value: "Select" },
  onChangeOption,
  showIcon = true,
  className = "",
  optionClass = "",
  dropdownTitleClass = "",
  mainContainerStyle = "",
  trimtext = false,
  labelClass = "",
  smallLabelClassName = "",
  trimLength = 10,
}) => {
  const [openDropdown, setDropdown] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenDropDown = (e) => {
    // setDropdown((prev) => !prev);
    setOpenMenu(e.currentTarget);
  };

  const handleSelectedOption = (val) => {
    onChangeOption(val);
    setOpenMenu(null);
  };

  const open = Boolean(openMenu);

  const handleClose = () => {
    setOpenMenu(null);
  };
  return (
    <div className={`${mainContainerStyle}`}>
      <div
        onClick={handleOpenDropDown}
        className={`flex justify-between hover:border-main-color cursor-pointer items-center border-1 border-[#E4E4E4] rounded-[5px] py-[6px] px-[10px] ${className}`}
      >
        <p
          className={`text-grey-600 cursor-pointer text-[14px] w-[80%] font-medium ${dropdownTitleClass}`}
        >
          {trimtext === true
            ? trimText(value?.label, trimLength, trimLength)
            : value?.label}
        </p>
        <AiOutlineCaretDown className="text-[12px] text-gray-500" />
      </div>

      {open && (
        <Menu
          id="basic-menu"
          anchorEl={openMenu}
          open={open}
          onClose={handleClose}
        >
          <div className={` ${optionClass}`}>
            {data.map((item) => (
              <div
                onClick={() => handleSelectedOption(item)}
                className={`px-[14px] py-[5px] hover:bg-[#E4E4E4] hover:text-white flex items-center justify-between`}
              >
                <p className={`text-[14px] cursor-pointer ${labelClass}`}>
                  {item.label}{" "}
                  {item.smallLabel !== undefined && (
                    <span className={`text-[9px] ${smallLabelClassName}`}>
                      {item?.smallLabel}
                    </span>
                  )}{" "}
                </p>
              </div>
            ))}
          </div>
        </Menu>
      )}
    </div>
  );
};

export default CustomDropdown;

CustomDropdown.proptype = {
  data: Proptypes.arrayOf({
    value: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
  }).isRequired,
  value: Proptypes.string.isRequired,
  onChangeOption: Proptypes.func.isRequired,
};
