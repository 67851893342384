import "./WelcomeBack.css";
import { useNavigate } from "react-router-dom";
import { conn } from "../../Utilt/conn";
import { useEffect, useState } from "react";

import zikora from "../../Assets/LOGO/ZikoraLogoGreen.png";
import axios from "axios";
import { Fade } from "react-awesome-reveal";
import loginimg from "../../Assets/ICONS/login.png";

export function WelcomeBack() {
  const auth = JSON.parse(window.localStorage.getItem("auth"));

  const email = auth !== null ? auth.email : null;

  const navigate = useNavigate();

  useEffect(() => {
    if (auth === null || auth === "") {
      navigate("/login");
    }
  }, []);

  const [isloading, loading] = useState(false);

  const [password, setPassword] = useState("");

  const authenticate = (_password) => {
    if (_password === "") {
      return;
    }
    loading(true);

    axios
      .post(`${conn}/admin/authenticate`, {
        email,
        password: _password,
        device_id: "3333",
        device_type: "computer",
        device_name: "Del",
      })
      .then((value) => {
        loading(false);
        window.localStorage.setItem("token", value.data.token);
        window.localStorage.setItem("refreshtoken", value.data.refreshToken);
        navigate("/home");
      })
      .catch((error) => {});
  };

  return (
    <div className="sign-in-form">
      <div className="inner">
        <div className="double-row">
          <div className="row1">
            <div className="image-logo">
              <img
                src={zikora}
                alt="img"
                style={{
                  width: "50px",
                  height: "50px",
                  margin: "0px 0px 50px 0px",
                }}
              />
            </div>

            <p
              style={{
                textAlign: "left",
                width: "40%",
                marginBottom: "20px",
                fontSize: "14px",
                color: "black",
              }}
            >
              Welcome Back!
            </p>

            <h1
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: "20px",
                marginBottom: "40px",
                color: "black",
              }}
            >
              {auth !== null && auth.f_name}
            </h1>

            <form>
              <center>
                <Fade>
                  <div
                    style={{ textAlign: "start", margin: "0", padding: "0" }}
                  >
                    <label>Password</label>
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                  />
                  <br />
                </Fade>
              </center>

              <center>
                {isloading && (
                  <div
                    style={{ width: "50px", height: "50px", padding: "20px" }}
                  >
                    <div className="sk-bounce">
                      <div
                        className="sk-bounce-dot"
                        style={{ backgroundColor: "#4c6356" }}
                      />
                      <div
                        className="sk-bounce-dot"
                        style={{ backgroundColor: "#4c6356" }}
                      />
                    </div>
                  </div>
                )}
              </center>
              <center>
                <Fade>
                  <button
                    type="button"
                    className="hover:bg-[green] mt-4 bg-[#4c6356] text-[white] py-3 rounded-md"
                    onClick={() => authenticate(password)}
                  >
                    Log In
                  </button>
                </Fade>
              </center>
              <p className="text-center text-[14px] mt-1">
                Use another account?{" "}
                <span
                  onClick={() => navigate("/login")}
                  className="text-[green] cursor-pointer"
                >
                  Yes
                </span>
              </p>
            </form>
          </div>

          <div className="row2">
            <div className="in">
              <div className="header">
                <h1 style={{ fontWeight: "normal", fontSize: "21px" }}>
                  <span style={{ color: "#c0dfce" }}>Welcome To</span> Zikora
                  Admin
                </h1>
                <p style={{ fontSize: "15px" }}>
                  Here on your admin console it possible to manage the accounts
                  and also monitor users transactions and activities
                </p>
              </div>

              <div className="img">
                <img src={loginimg} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
