import { configureStore } from "@reduxjs/toolkit";
import MetricReducer from "./Metric";
import OnboardReducer from "./OnboardSlice";
import Request from "./Request";
import Home from "./Home";
import Customers from "./Customers";
import Feedback from "./Feedback";
import zikoraApi from "../Network/api";
import { setupListeners } from "@reduxjs/toolkit/query";

export const Store = configureStore({
  reducer: {
    [zikoraApi.reducerPath]: zikoraApi.reducer,
    metric: MetricReducer,
    onboard: OnboardReducer,
    request: Request,
    home: Home,
    customers: Customers,
    feedback: Feedback,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(zikoraApi.middleware),
});

setupListeners(Store.dispatch);
