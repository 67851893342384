/* eslint-disable react/jsx-no-useless-fragment */

const Button = ({
  text,
  loading = false,
  disable = false,
  handleClick = () => {},
  className = "",
  outline = false,
  Icon,
}) => {
  const notActive = loading || disable;

  return (
    <>
      {!outline ? (
        <button
          type="button"
          disabled={notActive}
          onClick={handleClick}
          className={`text-[white] px-6 py-2 flex gap-2 items-center justify-center rounded-sm ${notActive ? "opacity-50" : "hover:opacity-50"}  bg-main-color ${loading && "opacity-60"} ${className}`}
        >
          {Icon}
          <p>{text}</p>
        </button>
      ) : (
        <button
          type="button"
          disabled={notActive}
          onClick={handleClick}
          className={`border-1 bg-[white] px-6 py-2 flex gap-2 items-center justify-center rounded-sm ${notActive ? "opacity-50" : "hover:opacity-50"} border-main-color ${loading && "opacity-60"} ${className}`}
        >
          {Icon}
          <p>{text}</p>
        </button>
      )}
    </>
  );
};

export default Button;
