/* eslint-disable no-param-reassign */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getJoinTimeline,
  getTrancsactionsTimeline,
  getTrancsactedValueTimeline,
} from "../Network/Home";

export const getJoinTimelineThunk = createAsyncThunk(
  "home/getJoinTimeline",
  getJoinTimeline,
);
export const getTrancsactedValueTimelineThunk = createAsyncThunk(
  "home/getTrancsactedValueTimeline",
  getTrancsactedValueTimeline,
);
export const getTrancsactionsTimelineThunk = createAsyncThunk(
  "home/getTrancsactionsTimeline",
  getTrancsactionsTimeline,
);

const initialState = {
  joinTimeline: [],
  trasactedValues: [],
  transactions: [],
  loading: false,
};

const HomeSlice = createSlice({
  name: "home",
  initialState,

  extraReducers: {
    [getJoinTimelineThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getJoinTimelineThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.joinTimeline = action.payload.data?.data;
    },
    [getJoinTimelineThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTrancsactedValueTimelineThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getTrancsactedValueTimelineThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.trasactedValues = action.payload.data?.data;
    },
    [getTrancsactedValueTimelineThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTrancsactionsTimelineThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getTrancsactionsTimelineThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactions = action.payload.data?.data;
    },
    [getTrancsactionsTimelineThunk.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default HomeSlice.reducer;
