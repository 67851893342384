import { getConfig, getUploadFileConfig } from "../../Utilt/Config";
import axios from "axios";
import { conn } from "../../Utilt/conn";

export const transferToZikora = async (data) => {
  const result = await axios.post(
    `${conn}/admin/send/zikora`,
    data,
    getConfig(),
  );
  return result;
};

export const getBanks = async () => {
  const result = await axios.get(
    `${conn}/zikora-api/v1/banks/all`,
    getConfig(),
  );
  return result;
};

export const bankEnquiry = async (data) => {
  const result = await axios.post(
    `${conn}/account/name_enquiry`,
    data,
    getConfig(),
  );
  return result;
};
