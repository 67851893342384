/* eslint-disable camelcase */
/* eslint-disable camelcase */
import React, { useState, useRef } from "react";
import "./OnboardUser.css";
import axios from "axios";
import { conn, uploadimg } from "../../Utilt/conn";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../ReuseComponent/Loader";
import { useNavigate } from "react-router-dom";
import sign_img_ from "../../Assets/ICONS/sign_img.png";
import id_img_ from "../../Assets/ICONS/id_img.png";
import selfie_img_ from "../../Assets/ICONS/selfie_im.png";
import { RiUserLocationLine } from "react-icons/ri";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";

const NewOnboardUser = () => {
  const navigate = useNavigate();

  const token = window.localStorage.getItem("token");
  const refreshtoken = window.localStorage.getItem("refreshtoken");

  const [analog, setAnalog] = useState(false);

  const [sign_img, setSign] = useState("");
  const [id_img, setID] = useState("");
  const [address_img, setAddress] = useState("");

  const [selfie_img_stat, setSelfieStat] = useState("Empty");
  const [sign_img_stat, setSignStat] = useState("Empty");
  const [id_img_stat, setIDStat] = useState("Empty");
  const [address_img_stat, setAddressStat] = useState("");

  const [emailStat, setEmailStat] = useState("pending");

  const [isUploading, setIsUploading] = useState(false);
  const [UploadingStatus, setUploadingStatus] = useState("");

  const [checkEmail, SetMailCheck] = useState(false);

  const [signingUp, setSigningUp] = useState(false);

  const [index, setIndex] = useState(1);

  // Images

  const [selfie_img, setSelfie] = useState("");

  const [signOpen, setSignOpen] = useState(false);
  const opensign = () => setSignOpen(true);
  const closesign = () => setSignOpen(false);

  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 500,
    height: 720,
    facingMode: "user",
  };

  const [openD, setOpen] = useState(false);

  const handleClickOpenD = () => {
    setOpen(true);
  };

  const handleCloseD = () => {
    setOpen(false);
  };

  const config2 = {
    headers: {
      "Content-type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  const formik = useFormik({
    initialValues: {
      f_name: "",
      l_name: "",
      gender: "0",
      dateOfBirth: "",
      email: "",
      phone: "",
      password: "",
      bvn: "",
      pin: "",
      CompanyCode: "NG0520001",
      imageType: "image/jpeg",
      signatureType: "image/jpeg",
      category: "1001",
      homeAddress: "BVN REFER",
      city: "BVN REFER",
      state: "BVN REFER",
      id_num: "00000000",
    },

    validationSchema: Yup.object({
      f_name: Yup.string().min(2, "Invalid First Name").required("required"),
      l_name: Yup.string().min(2, "Invalid First Name").required("required"),
      email:
        analog === false &&
        Yup.string().required("required").email("Invalid Email Address"),
      phone: Yup.string().required("required").max(11, "Invalid Phone Number"),
      dateOfBirth: Yup.string().required("required"),
      password:
        analog === false &&
        Yup.string().min(6, "Must be 6 characters long").required("required"),
      bvn:
        analog === false &&
        Yup.string()
          .min(11, "Invalid BVN")
          .required("required")
          .max(11, "Invalid BVN"),
      pin: Yup.string()
        .required("required")
        .min(4, "4 values required")
        .max(4, "4 values required"),
    }),

    onSubmit: async (val) => {
      const values = {
        ...val,
        image: sign_img,
        id_image: selfie_img,
        signature: id_img,
        doc_one: address_img,
      };

      if (!selfie_img) setSelfieStat("Upload Selfie Image");
      else setSelfieStat("");

      if (!sign_img) setSignStat("Upload Signature Image");
      else setSignStat("");

      if (!id_img) setIDStat("Upload ID Image");
      else setIDStat("");

      if (!address_img) setAddressStat("Upload Proof of Address");
      else setAddressStat("");

      if (emailStat === "pending" && analog !== true) handleClickOpenD();

      if (
        sign_img &&
        selfie_img &&
        id_img &&
        (emailStat === "" || analog === true) &&
        address_img
      ) {
        setSigningUp(true);
        await axios
          .put(`${conn}/user/createProfile`, JSON.stringify(values), config)
          .then((value) => {
            setSigningUp(false);
            console.log(value.data.user_id);
            navigate("/createAccount", { state: { id: value.data.user_id } });
          })
          .catch((error) => {
            setSigningUp(false);
            console.log(error);
            if (error.response.status === 401) navigate("/login");
          });
      }
    },
  });

  // Onboard User

  // Dialog

  const UploadImage = async (ind) => {
    const file = webcamRef.current.getScreenshot();
    const convertedFile = file.replace("data:image/jpeg;base64,", "");
    const formData = new FormData();

    setIsUploading(true);

    formData.append("data", convertedFile);

    axios
      .post(uploadimg, formData, config2)
      .then((value) => {
        setIsUploading(false);

        if (ind === 1) setSelfie(value.data);
        else if (ind === 2) setSign(value.data);
        else if (ind === 3) setID(value.data);
        else setAddress(value.data);

        setUploadingStatus("Image Uploaded Successfully");
      })
      .catch((error) => {
        setUploadingStatus("Image failed to upload");
        setIsUploading(false);
        console.log(error);
        if (error.response.status === 401) navigate("/login");
      });
  };

  const EmailValidate = async (email) => {
    if (email.length > 0) {
      SetMailCheck(true);
      await axios
        .post(
          `${conn}/user/email_available`,
          {
            email,
          },
          config,
        )
        .then((value) => {
          setTimeout(1000, SetMailCheck(false));
          setEmailStat("");
        })
        .catch((error) => {
          setTimeout(1000, SetMailCheck(false));
          setEmailStat("Email Address is already taken..");
          handleClickOpenD();
          if (error.response.status === 401) navigate("/login");
        });
    }
  };

  return (
    <MainLayout title={"Onboard User"}>
      <section className="content">
        <p>Kindly fill out the details of the individual</p>

        <section className="details">
          <p className="analog-check">
            <input
              type="checkbox"
              defaultChecked={analog}
              onChange={() => setAnalog(!analog)}
            />{" "}
            <span style={{ fontSize: "14px" }}>Analog</span>
          </p>

          <p>Details</p>

          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="grid">
              <div className="form-input">
                <label>First Name</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="f_name"
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  placeholder="Enter First Name"
                />
                {formik.errors.fname && <p>{formik.errors.fname}</p>}
              </div>

              <div className="form-input">
                <label>Last Name</label>
                <input
                  type="text"
                  autoComplete="off"
                  required
                  name="l_name"
                  value={formik.values.l_name}
                  onChange={formik.handleChange}
                  placeholder="Enter Last Name"
                />
                {formik.errors.lname && <p>{formik.errors.lname}</p>}
              </div>

              {analog === false && (
                <div className="form-input">
                  <label>Email</label>
                  <input
                    type="email"
                    autoComplete="off"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={(e) => EmailValidate(e.target.value)}
                    placeholder="Enter Email Address"
                  />
                  {formik.errors.lname && <p>{formik.errors.lname}</p>}
                  {checkEmail ? (
                    <center>
                      <Loader />
                    </center>
                  ) : (
                    <p>{emailStat !== "pending" && emailStat}</p>
                  )}
                </div>
              )}

              <div className="form-input">
                <label>Gender</label>
                <div className="select">
                  <select
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                  >
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                  </select>
                </div>
              </div>

              <div className="form-input">
                <label>Date Of Birth</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  placeholder="Enter Date of Birth"
                />
                {formik.errors.dob && <p>{formik.errors.dob}</p>}
              </div>

              <div className="form-input">
                <label>Phone Number</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  placeholder="Enter Phone Number"
                />
                {formik.errors.phone && <p>{formik.errors.phone}</p>}
              </div>

              {analog === false && (
                <div className="form-input">
                  <label>BVN</label>
                  <input
                    type="text"
                    autoComplete="off"
                    min="11"
                    name="bvn"
                    value={formik.values.bvn}
                    onChange={formik.handleChange}
                    placeholder="Enter BVN"
                  />
                  {formik.errors.bvn && <p>{formik.errors.bvn}</p>}
                </div>
              )}
            </div>

            <div className="grid" style={{ marginTop: "5vh" }}>
              <div
                className="image-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUploadingStatus("");
                  setIndex(1);
                  opensign();
                }}
              >
                <label>Selfie</label>
                <br />
                <br />

                <div className="img">
                  {!selfie_img ? (
                    <p className="label">Upload a selfie</p>
                  ) : (
                    <p className="label" style={{ color: "#608E75" }}>
                      Selfie Uploaded
                    </p>
                  )}

                  <span>
                    <img
                      src={selfie_img_}
                      alt="img"
                      style={{ width: "23px", height: "23px" }}
                    />
                  </span>

                  <p style={{ color: "#608E75", fontSize: "15px" }}>
                    Image should not be more 3mb
                  </p>
                </div>

                <p
                  className="stat"
                  style={{ color: "#608E75", fontSize: "14px" }}
                >
                  {selfie_img_stat !== "Empty" && selfie_img_stat}
                </p>
              </div>

              <div
                className="image-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUploadingStatus("");
                  setIndex(2);
                  opensign();
                }}
              >
                <label>Signature</label>
                <br />
                <br />

                <div className="img">
                  {!sign_img ? (
                    <p className="label">Upload a signature</p>
                  ) : (
                    <p className="label" style={{ color: "#608E75" }}>
                      Signature Uploaded
                    </p>
                  )}

                  <span>
                    <img width="23px" height="23px" alt="img" src={sign_img_} />
                  </span>

                  <p style={{ color: "#608E75", fontSize: "14px" }}>
                    this can be .pdf, .docx
                  </p>
                </div>

                <p className="stat" style={{ color: "#608E75" }}>
                  {sign_img_stat !== "Empty" && sign_img_stat}
                </p>
              </div>

              <div
                className="image-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUploadingStatus("");
                  setIndex(3);
                  opensign();
                }}
              >
                <label>Identification</label>
                <br />
                <br />

                <div className="img">
                  {!id_img ? (
                    <p className="label">Upload a form identification</p>
                  ) : (
                    <p className="label" style={{ color: "#608E75" }}>
                      ID Uploaded
                    </p>
                  )}

                  <p style={{ fontSize: "23px", color: "#608E75" }}>
                    <img width="23px" height="23px" alt="img" src={id_img_} />
                  </p>

                  <p style={{ color: "#608E75", fontSize: "14px" }}>
                    this can be .pdf, .docx
                  </p>
                </div>

                <p className="stat" style={{ color: "#608E75" }}>
                  {id_img_stat !== "Empty" && id_img_stat}
                </p>
              </div>

              <div
                className="image-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUploadingStatus("");
                  setIndex(4);
                  opensign();
                }}
              >
                <label>Proof of Address</label>
                <br />
                <br />

                <div className="img">
                  {!address_img ? (
                    <p className="label">Upload proof address</p>
                  ) : (
                    <p className="label" style={{ color: "#608E75" }}>
                      Proof of address Uploaded
                    </p>
                  )}

                  <p style={{ fontSize: "23px", color: "#608E75" }}>
                    <span style={{ color: "#608E75" }}>
                      <RiUserLocationLine />
                    </span>
                  </p>

                  <p style={{ color: "#608E75", fontSize: "14px" }}>
                    this can be .pdf, .docx
                  </p>
                </div>

                <p className="stat" style={{ color: "#608E75" }}>
                  {address_img_stat !== "Empty" && address_img_stat}
                </p>
              </div>
            </div>

            <div className="grid">
              {analog === false && (
                <div className="form-input">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Enter Password"
                  />
                  {formik.errors.password && <p>{formik.errors.password}</p>}
                </div>
              )}

              <div className="form-input">
                <label>Set Pin</label>
                <input
                  type="text"
                  name="pin"
                  value={formik.values.pin}
                  onChange={formik.handleChange}
                  placeholder="Enter 4 digit pin"
                />
                {formik.errors.pin && <p>{formik.errors.pin}</p>}
              </div>
            </div>
          </form>

          <div className="grid">
            <div />

            <div
              style={{
                display: "inline-flex",
                justifyContent: "flex-end",
                margin: "30px",
              }}
            >
              <button
                onClick={formik.handleSubmit}
                type="button"
                className="sub-btn"
              >
                Submit
              </button>
            </div>
          </div>

          <div className="grid">
            <div />

            <div
              style={{
                display: "inline-flex",
                justifyContent: "flex-end",
                margin: "30px",
              }}
            >
              {signingUp && <Loader />}
            </div>
          </div>
        </section>
      </section>
    </MainLayout>
  );
};

export default NewOnboardUser;
