import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/LOGO/ZikoraLogoGreen.png";
import { CgUserAdd, CgHome } from "react-icons/cg";
import { TbUsers, TbDatabase, TbMessageCircle } from "react-icons/tb";
import { BiTransfer } from "react-icons/bi";
import { FcFeedback } from "react-icons/fc";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getLimitRequestCount } from "../../Network/Request";
import { HiOutlineLogout } from "react-icons/hi";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 10,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Nav = () => {
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth"));

  const [count, setCount] = useState(0);

  useEffect(() => {
    getLimitRequestCount().then((val) => {
      setCount(val?.count);
    });
  }, []);

  const Logout = () => {
    window.localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="w-52 border-r-1 fixed border-grey-300 border-primary py-3 h-[100vh] flex flex-col gap-12">
      <img className="h-12 w-12 self-center mt-12" src={logo} alt="" />

      <div className="flex flex-col gap-6">
        <NavItem Icon={CgHome} text={"Home"} active={"/home"} />

        {Number(auth.user_level) !== 2 && (
          <NavItem
            Icon={TbUsers}
            text={"Customers"}
            active={"/new-customers"}
          />
        )}

        {Number(auth.user_level) !== 2 && (
          <NavItem
            Icon={TbDatabase}
            text={"Accounts"}
            active={"/user-account"}
          />
        )}

        {/* {Number(auth.user_level) !== 2 &&<NavItem 
                Icon={TbArrowsUpDown}
                text={"Transfer"}
                active={'/transfer'}
            />}

             {Number(auth.user_level) !== 2 &&<NavItem 
                Icon={FaMoneyBill}
                text={"Bill Payment"}
                active={'/bill-payment'}
            />} */}

        <NavItem
          Icon={CgUserAdd}
          text={"Onboard User"}
          active={"/new-onboard-user"}
        />

        <NavItem
          Icon={BiTransfer}
          text={"Transactions"}
          active={"/user-transactions"}
        />

        {Number(auth.user_level) !== 2 && (
          <NavItem
            Icon={FcFeedback}
            text={"Feedback"}
            active={"/new-feedback"}
          />
        )}

        {Number(auth.user_level) !== 2 && (
          <NavItem active={"/new-request"} useChild>
            <div className="flex gap-2">
              <StyledBadge badgeContent={count} color="primary">
                <TbMessageCircle style={{ fontSize: "24px" }} />
              </StyledBadge>

              <p>Request</p>
            </div>
          </NavItem>
        )}

        <div className="w-full border-t-1 border-primary">
          <div
            className={`px-4 text-[14px] pt-4 hover:text-main-color cursor-pointer items-center`}
          >
            <div onClick={Logout} className="flex gap-2 items-center">
              <HiOutlineLogout size={18} />
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;

const NavItem = ({ Icon, text, active, children, useChild = false }) => {
  const { pathname } = useLocation();
  const path = `/${pathname.split("/")[1]}`;

  return (
    <Link to={active}>
      <div
        className={`px-4 text-[14px] ${path === active && "text-[green] border-r-1 border-[green]"} hover:text-main-color cursor-pointer items-center`}
      >
        {!useChild ? (
          <div className="flex gap-2">
            <Icon size={18} />
            <p>{text}</p>
          </div>
        ) : (
          children
        )}
      </div>
    </Link>
  );
};
