import { Stack, Typography, Divider } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Fade } from "react-awesome-reveal";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";

import docsDownload from "../../Assets/docs-download.png";
import { CustomIconButton } from "../../ReuseComponent/FormUtils/FormUtils";
import useGetLimitReuest from "./hooks/useGetLimitReuest";
import { useParams, useNavigate } from "react-router-dom";

const RequestDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isFetching, data } = useGetLimitReuest(id);

  return (
    <MainLayout title="Requst Details" isLoading={isFetching}>
      <div>
        <Stack direction={"column"} spacing={2} sx={{ width: "50%" }}>
          <Fade duration={1000}>
            <div className="flex gap-3 items-center w-24">
              <CustomIconButton
                Icon={KeyboardBackspaceIcon}
                callBack={() => navigate("/new-request")}
                iconStyle={{ color: "#66A681" }}
              />
              <p>Back</p>
            </div>
          </Fade>

          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <img
              src={data?.image}
              alt=""
              style={{ height: "50px", width: "50px", borderRadius: "25px" }}
            />
            <Stack direction={"column"} spacing={1}>
              <Typography
                sx={{ color: "#252424", fontSize: "14px", fontWeight: "600" }}
              >
                {data?.account_name}
              </Typography>
              <Typography sx={{ color: "#6B6B6B", fontSize: "10px" }}>
                Email: {data?.email}
              </Typography>
            </Stack>
          </Stack>

          <Divider />

          <Typography
            sx={{ color: "#252424", fontSize: "20px", fontWeight: "600" }}
          >
            {data?.account_name}
          </Typography>

          <Stack direction={"column"} spacing={1}>
            <Typography sx={{ color: "#252424", fontSize: "14px" }}>
              {" "}
              <span style={{ color: "#565555" }}>Account Name:</span>{" "}
              {data?.account_name}
            </Typography>
            <Typography sx={{ color: "#252424", fontSize: "14px" }}>
              {" "}
              <span style={{ color: "#565555" }}>Account Number:</span>{" "}
              {data?.nuban}
            </Typography>
          </Stack>

          <Fade duration={1000}>
            <Typography sx={{ color: "#565555" }}>
              {data?.description}
            </Typography>
          </Fade>

          <Stack direction={"column"} spacing={0} sx={{ marginTop: "16rem" }}>
            <Typography sx={{ color: "#565555", fontSize: "16px" }}>
              Preferred Limit increase:
            </Typography>
            <Typography
              sx={{ color: "#252424", fontSize: "16px", fontWeight: "600" }}
            >
              {data?.amount}
            </Typography>
          </Stack>

          <Typography
            sx={{ color: "#252424", fontSize: "12px", marginTop: "6rem" }}
          >
            {" "}
            <span
              style={{ color: "#66A681", fontSize: "14px", fontWeight: "600" }}
            >
              Attachment:{" "}
            </span>{" "}
            Income statement.pdf
          </Typography>

          <a
            href={data?.document_url}
            target="_blank"
            rel="noreferrer"
            download={"doc"}
          >
            <img
              src={docsDownload}
              alt=""
              style={{ height: "130px", width: "130px" }}
            />
          </a>
        </Stack>
      </div>
    </MainLayout>
  );
};

export default RequestDetails;
