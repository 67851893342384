/* eslint-disable consistent-return */
import axios from "axios";
import { getConfig, getUploadFileConfig } from "../Utilt/Config";
import { conn } from "../Utilt/conn";

export const getCustomers = async ({ page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(
      `${conn}/admin/get_customers?page=${page}&limit=${limit}`,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getCustomersCount = async () => {
  try {
    const response = await axios.get(
      `${conn}/user/get-user-count`,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const searchCustomers = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/search_customers`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const approveUser = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/approve/user`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const disApproveUser = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/disapprove/user`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const approveDocument = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/user/verify_user_document`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const uploadPicture = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/upload_pic`,
      data,
      getUploadFileConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const replaceDocument = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/user/updateDocument`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getTransactions = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/account/transactions`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getAccountByNumber = async (data) => {
  try {
    const response = await axios.get(
      `${conn}/admin/get-account-by-nuban/${data.nuban}`,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getSingleUserTransactions = async (data) => {
  try {
    const { headers } = getConfig();
    const response = await axios.get(
      `${conn}/admin/account/single_transaction_from_user`,
      { params: { ...data }, headers },
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const feedbackMail = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/send_email_to_user`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const verifyBvn = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/user/verify_user_bvn`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getCustomerInfo = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/user/get_user_brief_info`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const adminUpdateUserInfo = async ({ data, id }) => {
  try {
    const response = await axios.patch(
      `${conn}/admin/user/updateUserProfile/${id}`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};
