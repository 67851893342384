/* eslint-disable consistent-return */
import axios from "axios";
import { conn } from "../Utilt/conn";
import { getConfig } from "../Utilt/Config";

export const getJoinTimeline = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/metrics/users/joins/timeline`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getTrancsactedValueTimeline = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/metrics/transactions/count/timeline`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getTrancsactionsTimeline = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/metrics/transactions/sum/timeline`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};
