import { useState } from "react";
import CustomModal from "../../ReuseComponent/Modal/CustomModal";
import Loader from "../../ReuseComponent/Loader";

const ConfirmModal = ({
  open,
  handleClose,
  name = "name",
  handleCallBack,
  loading = false,
}) => {
  const [text, setText] = useState("");
  const close = () => {
    handleClose();
    setText("");
  };

  return (
    <CustomModal
      className="!min-h-[180px] w-[400px]"
      open={open}
      handleClose={!loading && close}
    >
      {!loading ? (
        <div className="flex flex-col gap-3 items-center px-5 py-5 ">
          <p className="text-[14px] text-center  text-grey-800">
            To continue with action, type{" "}
            <span className="font-bold">{name}</span> in the input field
          </p>

          <input
            onChange={(e) => setText(e.target.value)}
            className="border-1 border-[gray] w-full focus:outline-none py-1 px-2 rounded"
            type="text"
          />

          <div className="w-full flex justify-between">
            <button
              type="submit"
              onClick={close}
              className="px-3 py-2 rounded border-1 text-[black] hover:opacity-70"
            >
              Cancel
            </button>

            <button
              type="submit"
              onClick={handleCallBack}
              disabled={text !== name}
              className={`${text !== name ? "opacity-50" : "hover:opacity-70"} px-3 py-2 bg-main-color rounded text-[white]`}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : (
        <div className="h-full pt-6">
          <Loader />
        </div>
      )}
    </CustomModal>
  );
};

export default ConfirmModal;
