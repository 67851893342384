import React from "react";
import { MdOutlineCancel } from "react-icons/md";

const PopError = ({ message, handleClick }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        color: "red",
        justifyContent: "center",
        marginTop: "1px",
        padding: "1px",
        borderRadius: "md",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <p style={{ fontSize: "10px", verticalAlign: "middle" }}>{message}</p>
      <MdOutlineCancel
        style={{ fontSize: "21px", cursor: "pointer" }}
        onClick={handleClick}
      />
    </div>
  );
};

export default PopError;
