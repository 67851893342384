import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, TextField } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  TextAndField,
  Field,
  FieldSelect,
  Text,
  FillButton,
} from "../../../ReuseComponent/FormUtils/FormUtils";
import CustumPopperSelect from "../../../ReuseComponent/CustumPopperSelect";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";
import {
  getBillerCategories,
  getSingleBillerCategory,
  getPaymentItem,
} from "../../../APIs/ServiceClass/BillersService";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";

const customerService = new CustomerPage();
const SecondPage = ({ handleCallBack, selectedUser }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);

  const [whatPaymentCategory, setWhatPaymentCategory] = useState(null);

  const [catId, setCatId] = useState("none");
  const [paymentItemId, setPaymentItemId] = useState("none");
  const [paymentCode, setPaymentCode] = useState("null");
  const [amount, setAmount] = useState(0);
  const [paymentType, setPaymentType] = useState("none");
  const [customerId, setCustomerId] = useState("");
  const [disableAmount, setDisableAmount] = useState(false);
  const [senderId, setSenderId] = useState(null);

  const [categories, setCategories] = useState([]);
  const [isLoadingBiller, setIsLoadingBiller] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const [billers, setBillers] = useState([]);
  const [payment, setPayment] = useState([]);

  // console.log(selectedUser)

  useEffect(() => {
    setIsLoading(true);
    customerService
      .getCustomersAccount(selectedUser.id)
      .then((val) => {
        setUserAccounts(val.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401) navigate("/login");
      });

    getBillerCategories()
      .then((val) => {
        setCategories(val.data.data.categorys);
      })
      .catch((error) => {
        if (error.response.status === 401) navigate("/login");
      });
  }, []);

  const handleSubmitForm = (values, action) => {};

  const handleSetCategory = (id) => {
    setCatId(id);
    setIsLoadingBiller(true);
    getSingleBillerCategory(id)
      .then((val) => {
        setIsLoadingBiller(false);
        setWhatPaymentCategory(val.data.data.billers[0].categoryname);
        setBillers(val.data.data.billers);
      })
      .catch((error) => {
        setIsLoadingBiller(false);
        if (error.response.status === 401) navigate("/login");
      });
  };

  const handleSetPaymentItem = (id) => {
    setPaymentItemId(id);
    setIsLoadingPayment(true);
    getPaymentItem(id)
      .then((val) => {
        setIsLoadingPayment(false);
        setPayment(val.data.data.paymentitems);
      })
      .catch((error) => {
        setIsLoadingPayment(false);
        if (error.response.status === 401) navigate("/login");
      });
  };

  const handleExecutePayment = (val) => {
    setPaymentType(val.paymentitemname);
    setPaymentCode(val.paymentCode);

    if (Number(val.amount) > 0) {
      setDisableAmount(true);
      const payAmount = val.amount.substring(0, val.amount.length - 2);
      setAmount(payAmount);
    } else {
      setDisableAmount(false);
      setAmount(Number(val.amount));
    }
  };

  const handleSubmit = () => {
    const data = {
      sender_id: senderId,
      customer_id: customerId,
      customer_mobile: customerId,
      customer_email: selectedUser.email,
      payment_code: paymentCode,
      amount,
      payCategory: whatPaymentCategory,
    };
    handleCallBack(data);
  };

  return (
    <Stack>
      {!isLoading && (
        <Formik
          initialValues={{
            customerName: `${selectedUser.f_name} ${selectedUser.l_name}`,
          }}
          onSubmit={handleSubmitForm}
        >
          {(props) => (
            <div>
              {!isLoading && (
                <Stack spacing={3}>
                  <TextAndField space={0.5}>
                    <Text text={"Customer’s Account Name"} />
                    <Field
                      size="large"
                      width={"100%"}
                      name="customerName"
                      disable
                    />
                  </TextAndField>

                  <TextAndField space={0.5}>
                    <Text text={"Select Customer Account"} />
                    <CustumPopperSelect
                      handleCallBack={(val) => setSenderId(val)}
                      placeholder="Select account"
                      displayCom={userAccounts.map((val) => {
                        return {
                          main: (
                            <AccountComponent
                              name={val.account_name}
                              account={val.nuban}
                              tier={val.account_tier}
                            />
                          ),
                          id: val.id,
                          searchData: { data1: val.nuban },
                        };
                      })}
                    />
                  </TextAndField>

                  <TextAndField space={0.5}>
                    <Text text={"Select Biller"} />
                    <FieldSelect
                      callback={(e) => {
                        handleSetCategory(e.target.value);
                      }}
                      list={categories.map((val) => {
                        return {
                          id: val.categoryid,
                          name: val.categoryname,
                        };
                      })}
                      value={catId}
                      height="55px"
                      width={"100%"}
                      placeholder="Choose Biller"
                      name="biller"
                    />
                  </TextAndField>

                  <TextAndField space={0.5}>
                    <Text text={"Select Biller Category"} />
                    <FieldSelect
                      callback={(e) => {
                        handleSetPaymentItem(e.target.value);
                      }}
                      list={billers.map((val) => {
                        return {
                          id: val.billerid,
                          name: val.billername,
                        };
                      })}
                      value={paymentItemId}
                      height="55px"
                      width={"100%"}
                      placeholder={
                        !isLoadingBiller ? "Select Biller Category" : "...."
                      }
                      disable={isLoadingBiller}
                      name="billerCategory"
                    />
                  </TextAndField>

                  <Stack direction={"row"} gap={6} sx={{ height: "300px" }}>
                    <Stack
                      direction={"column"}
                      gap={2}
                      sx={{
                        border: "1px solid #E0DEDE",
                        borderRadius: "10px",
                        height: "95%",
                        width: "50%",
                        padding: "10px",
                      }}
                    >
                      <Typography>Payment Items</Typography>
                      {!isLoadingPayment && (
                        <Stack sx={{ height: "80%", overflowY: "scroll" }}>
                          {payment.map((val) => {
                            return (
                              <Typography
                                sx={{
                                  color: "black",
                                  "&:nth-child(2)": { bgcolor: "#FCFCFC" },
                                  cursor: "pointer",
                                  padding: "10px 20px",
                                  "&:hover": { bgcolor: "#efefef" },
                                }}
                                onClick={() => handleExecutePayment(val)}
                              >
                                {val.paymentitemname}
                              </Typography>
                            );
                          })}
                        </Stack>
                      )}
                      {isLoadingPayment && <Loader />}
                    </Stack>

                    <Stack direction={"column"} gap={3} sx={{ width: "50%" }}>
                      <TextAndField space={0.5}>
                        <Text text={"Payment Type"} />
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={paymentType}
                          inputProps={{ style: { fontSize: 14 } }}
                          onChange={(e) => {
                            setPaymentType(e.target.value);
                          }}
                          disabled
                        />
                      </TextAndField>

                      <TextAndField space={0.5}>
                        <Text text={"Cable ID / Recharge mobile"} />
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={customerId}
                          inputProps={{ style: { fontSize: 14 } }}
                          onChange={(e) => {
                            setCustomerId(e.target.value);
                          }}
                        />
                      </TextAndField>

                      <TextAndField space={0.5}>
                        <Text text={"Amount"} />
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={amount}
                          type="number"
                          inputProps={{ style: { fontSize: 14 } }}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          disabled={disableAmount}
                        />
                      </TextAndField>
                    </Stack>
                  </Stack>

                  <Box sx={{ width: "100%", marginTop: "150px" }}>
                    <FillButton
                      width={"100%"}
                      text="Pay Bills"
                      callBack={handleSubmit}
                    />
                  </Box>
                </Stack>
              )}
            </div>
          )}
        </Formik>
      )}
      {isLoading && <Loader />}
    </Stack>
  );
};

export default SecondPage;

const SelectCharges = ({ setCharges }) => {
  const list = [100, 200, 300, 400, 500];
  const [selected, setSelected] = useState("null");
  const handleClick = (val, index) => {
    setSelected(index);
    setCharges(val);
  };
  return (
    <Stack spacing={1}>
      <Typography fontSize={"12px"} color="red">
        This transfer will attract a charge of N
      </Typography>
      <Stack direction={"row"} spacing={2}>
        {list.map((val, index) => {
          return (
            <div onClick={() => handleClick(val, index)}>
              <ChargesItems keyVal={index} text={val} selected={selected} />
            </div>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ChargesItems = ({ text, keyVal, selected }) => {
  return (
    <Box
      key={keyVal}
      sx={{
        border: selected === keyVal ? "1px solid red" : "1px solid #C1C1C5",
        color: selected === keyVal ? "red" : "black",
        cursor: "pointer",
        width: "70px",
        padding: "5px 10px",
        borderRadius: "15px",
        textAlign: "center",
      }}
    >
      {text}
    </Box>
  );
};

const AccountComponent = ({ name, account, tier }) => {
  return (
    <Stack
      sx={{
        "&:nth-child(2)": { bgcolor: "#FCFCFC" },
        cursor: "pointer",
        padding: "10px 20px",
        "&:hover": { bgcolor: "#efefef" },
      }}
      direction={"row"}
      justifyContent={"space-between"}
    >
      <Typography fontSize={"14px"} color={"#252424"}>
        {name}
      </Typography>
      <Typography fontSize={"14px"} color={"#252424"}>
        {account}
      </Typography>
      <Typography fontSize={"14px"} color={"#252424"}>
        {tier}
      </Typography>
    </Stack>
  );
};
