/* eslint-disable camelcase */

import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import { style2 } from "../Styles";
import "./SendEmailModal.css";
import { FeedbackClass } from "../../../APIs/ServiceClass/Feedback";
import { CgClose } from "react-icons/cg";
import { GiPaperClip } from "react-icons/gi";
import { MdDeleteOutline } from "react-icons/md";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";
import Loader from "../../../ReuseComponent/Loader";

export const SendEmailModal = ({ open, close, user }) => {
  const feedbackclass = new FeedbackClass();
  const customerclass = new CustomerPage();

  const auth = JSON.parse(localStorage.getItem("auth"));

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const [subject, setSubject] = useState("");
  const [bodytext, setBody] = useState("");

  const [img_file, setImageFile] = useState(null);
  const [img_file_link, setImageFileLink] = useState("");

  const openFile = () => {
    const file = document.querySelector(".file");
    file.click();
  };

  const sendReply = () => {
    if (subject !== "" || bodytext !== "") {
      setLoading(true);
      setMsg("");

      feedbackclass
        .feedbackMail({
          user_id: user.id,
          admin_id: auth.id,
          subject,
          body:
            img_file_link === ""
              ? bodytext
              : `${bodytext} <html><br><br><img width="30%" src="${img_file_link}"/></html>`,
        })
        .then((value) => {
          console.log(value);
          setLoading(false);
          close();
          setMsg("");
          setBody("");
          setSubject("");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setMsg("Failed to send mail");
        });
    } else {
      setMsg("Please input empty fields");
    }
  };

  const UploadImage = () => {
    setLoading(true);
    setMsg("");
    const formData = new FormData();
    formData.append("pic", img_file);
    customerclass
      .uploadFile(formData)
      .then((value) => {
        setImageFileLink(value.data.mediaImage);
        console.log(value.data);
        setMsg("");
        sendReply();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMsg("Failed to send mail");
      });
  };

  const handleMail = () => {
    if (img_file !== null) {
      UploadImage();
    } else {
      sendReply();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setImageFile(null);
        setImageFileLink("");
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style2,
          overflowY: "scroll",
          fontFamily: "Poppins, sans-serif",
          fontSize: "12px",
        }}
      >
        {user !== null && (
          <>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginBottom: "20px",
                fontSize: "20px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#66A681" }}>Send an Email</span>
              <span style={{ fontSize: "22px" }} onClick={() => close()}>
                <CgClose />
              </span>
            </div>

            <div
              className="modal-form"
              style={{
                display: "inline-flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  margin: "10px 0px",
                }}
              >
                <span>To:</span>
                <span style={{ marginLeft: "30px" }}>{user.email}</span>
              </div>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  margin: "10px 0px",
                }}
              >
                <span>From:</span>
                <span style={{ marginLeft: "30px", color: "#8D8D8D" }}>
                  customerservice@zikora.com
                </span>
              </div>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  margin: "10px 0px",
                }}
              >
                <span>From:</span>
                <span
                  style={{ marginLeft: "30px", color: "#8D8D8D", width: "70%" }}
                >
                  <input
                    style={{
                      fontSize: "13px",
                      width: "100%",
                      padding: "0",
                      border: "none",
                    }}
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Type subject here..."
                  />
                </span>
              </div>
              <hr />

              <textarea
                style={{ border: "none", marginTop: "20px" }}
                value={bodytext}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Type body here..."
                cols={5}
              />

              <input
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
                className="file"
                style={{ display: "none" }}
              />

              <p style={{ marginTop: "10px", color: "red" }}>
                <center>{msg}</center>
              </p>

              {!loading ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    display: "inline-flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="submit"
                    onClick={handleMail}
                    style={{
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: "#66A681",
                    }}
                  >
                    Send Mail
                  </button>

                  <span
                    style={{
                      marginLeft: "20px",
                      width: "70%",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <span onClick={openFile} style={{ fontSize: "20px" }}>
                      <GiPaperClip />
                    </span>
                    {img_file === null ? "Add Attachment" : "1 File selected"}
                  </span>

                  <span
                    onClick={() => {
                      setImageFile(null);
                      setImageFileLink("");
                    }}
                    style={{
                      fontSize: "23px",
                      justifyContent: "flex-end",
                      color: "red",
                    }}
                  >
                    <MdDeleteOutline />
                  </span>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};
