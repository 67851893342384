/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { BiErrorAlt } from "react-icons/bi";
import { IoMdFingerPrint } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import done from "../../Assets/ICONS/done.png";
import { SendEmailModal } from "./Modal/SendEmailModal";
import { style2 } from "./Styles";

import Loader from "../../ReuseComponent/Loader";
import BasicMenu from "../ExistingAccount/menu/menu";

import { toast } from "react-toastify";
import {
  deleteUserService,
  restrictUserService,
  unrestrictUserService,
} from "../../Network/Request";
import CustomDropdown from "../../ReuseComponent/CustomDropdown";
import ConfirmModal from "./ConfirmModal";

import { BsFolderFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersCount } from "../../Network/Customers";
import {
  approveUserThunk,
  disApproveUserThunk,
  getCustomersNoLoadingThunk,
  getCustomersThunk,
  searchCustomersThunk,
  setLoading,
  verifyBvnThunk,
} from "../../ReduxStore/Customers";
import Button from "../../ReuseComponent/FormUtils/Button";
import Search from "../../ReuseComponent/FormUtils/Search";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import Pagination from "../../ReuseComponent/Pagination";
import Document from "./Modal/Document";

const listOptions = [
  { label: "First Name", value: "f_name" },
  { label: "Last Name", value: "l_name" },
  { label: "Email", value: "email" },
  { label: "BVN", value: "bvn" },
];

const NewCustomers = () => {
  const navigate = useNavigate();
  const distpatch = useDispatch();

  const [filter, setFilter] = useState({ label: "Email", value: "email" });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageValue, setPageValue] = useState(1);
  const [limitRange, setLimitRange] = useState(10);

  const [mail, setMail] = useState(false);
  const [current_user, setCurrentUser] = useState(null);

  const [openMenu, setOpenMenu] = useState(null);
  const [openApproveMenu, setOpenApproveMenu] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);

  const [actionType, setActionType] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  const [clickedDisapprove, setClickedDisapprove] = useState(false);
  const [reason, setReason] = useState("");

  const [openViewDoc, setOpenViewDoc] = useState(false);
  const [docs, setDocs] = useState(null);
  const [singleData, setSingleData] = useState([]);

  const customersList = useSelector((state) => state.customers)?.customersList;
  const loadingCustomers = useSelector((state) => state.customers)?.loading;

  const [bvnMsg, SetBvnMsg] = useState("");

  const [errbvn, setErrbvn] = useState(false);
  const [bvnData, setBvnData] = useState({});

  const [bvnLoader, setBvnLoader] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const loadingApprove = useSelector(
    (state) => state.customers,
  )?.loadingApprove;

  const [searchText, setSearchText] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const token = window.localStorage.getItem("token");

  const auth = JSON.parse(window.localStorage.getItem("auth"));

  const fetchCustomers = async () => {
    distpatch(setLoading(true));
    distpatch(getCustomersThunk({ page: currentPage, limit: limitRange })).then(
      (_) => {
        setPageValue(currentPage);
        distpatch(setLoading(false));
      },
    );
  };

  useEffect(() => {
    if (token === null) {
      navigate("/login");
    }
    fetchCustomers();
    getCustomersCount().then((val) => setTotalCustomers(val?.data?.count));
  }, []);

  useEffect(() => {
    if (searchText !== null && searchText.length === 0) {
      fetchCustomers();
    }
  }, [searchText]);

  const startSearch = async (qu) => {
    distpatch(searchCustomersThunk({ field: filter?.value, value: qu })).then(
      (val) => {
        if (val?.payload?.status === 404) {
          fetchCustomers();
          toast.error("User not found");
        }
      },
    );
  };

  const openBvn = () => setBvnLoader(true);
  const closeBvn = () => {
    setBvnLoader(false);
    setBvnData();
  };

  // Verify BVN

  const verifyingBvn = useSelector(
    (state) => state?.customers,
  )?.loadingVerifyBvn;

  const verifyBvn = async (bvn) => {
    setBvnData();
    SetBvnMsg("");
    distpatch(verifyBvnThunk({ bvn })).then((val) => {
      if (val?.payload?.status === 200) {
        setErrbvn(false);
        SetBvnMsg("BVN is valid");
        setBvnData(val?.payload?.data?.data);
      } else {
        setErrbvn(true);
        setBvnData();
        SetBvnMsg(val?.payload?.data?.message);
      }
    });
  };

  const handleCloseApproveMenu = () => {
    setOpenApproveMenu(null);
    setClickedDisapprove(false);
    setReason("");
  };

  const handleDisapproveUser = async (_user) => {
    distpatch(disApproveUserThunk({ userId: _user, reason })).then((val) => {
      distpatch(
        getCustomersNoLoadingThunk({ page: currentPage, limit: limitRange }),
      );
      handleCloseApproveMenu();
      if (val?.payload?.status === 200) toast.success("User Disapproved");
      else toast.error(val?.payload?.data?.data);
    });
  };

  const handleApproveUser = async (_user) => {
    distpatch(approveUserThunk({ userId: _user })).then((val) => {
      distpatch(
        getCustomersNoLoadingThunk({ page: currentPage, limit: limitRange }),
      );
      handleCloseApproveMenu();
      if (val?.payload?.status === 200) toast.success("User Approved");
      else toast.error(val?.payload?.data?.data);
    });
  };

  const handleOpenDocs = (dta) => {
    const verification = dta?.doc_verifications?.split(",");
    setOpenViewDoc(true);
    setDocs({
      ...dta,
      docs: [
        {
          name: "Selfie",
          url: dta?.image,
          verified: verification[0],
          v: "image",
        },
        {
          name: "Signature",
          url: dta?.signature,
          verified: verification[2],
          v: "signature",
        },
        {
          name: "Identification",
          url: dta?.id_image,
          verified: verification[1],
          v: "id_image",
        },
        {
          name: "Proof Of Address",
          url: dta?.doc_one,
          verified: verification[3],
          v: "doc_one",
        },
      ],
    });
  };

  const handleOpenMenu = (event, data) => {
    setOpenMenu(event.currentTarget);
    setSingleData([data]);
  };

  const writeApproveText = (approve) => {
    if (approve === 1) return "Approved";
    if (approve === -1) return "Disapproved";
    return "Pending";
  };

  return (
    <MainLayout title="Customers">
      {openViewDoc && (
        <Document
          openModal={openViewDoc}
          handleCloseModal={() => setOpenViewDoc(false)}
          data={docs}
          callBack={() => fetchCustomers()}
        />
      )}

      <ConfirmModal
        handleCallBack={() => {
          setActionLoading(true);

          if (actionType === "restrict") {
            restrictUserService({ userId: singleData[0]?.id }).then((val) => {
              setActionLoading(false);
              if (val?.status === 200) {
                distpatch(
                  getCustomersNoLoadingThunk({
                    page: currentPage,
                    limit: limitRange,
                  }),
                );
                toast.success(val?.data?.message);
                setOpenConfirmModal(false);
              } else toast.error(val?.data?.message);
            });
          }

          if (actionType === "unrestrict") {
            unrestrictUserService({ userId: singleData[0]?.id }).then((val) => {
              setActionLoading(false);
              if (val?.status === 200) {
                distpatch(
                  getCustomersNoLoadingThunk({
                    page: currentPage,
                    limit: limitRange,
                  }),
                );
                toast.success(val?.data?.message);
                setOpenConfirmModal(false);
              } else toast.error(val?.data?.message);
            });
          }

          if (actionType === "delete") {
            deleteUserService({
              userId: singleData[0]?.id,
            }).then((val) => {
              setActionLoading(false);
              if (val?.status === 200) {
                distpatch(
                  getCustomersNoLoadingThunk({
                    page: currentPage,
                    limit: limitRange,
                  }),
                );
                setOpenConfirmModal(false);
                toast.success(val?.data?.data);
              } else toast.error(val?.data?.message);
            });
          }
        }}
        loading={actionLoading}
        name={singleData[0]?.email.trim()}
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
      />

      {openMenu && (
        <BasicMenu openMenu={openMenu} setOpenMenu={setOpenMenu}>
          <div className="text-[12px]">
            {singleData[0]?.restrict_user === 0 ? (
              <p
                onClick={() => {
                  setOpenConfirmModal(true);
                  setOpenMenu(false);
                  setActionType("restrict");
                }}
                className="text-[#656565] px-2 py-2 cursor-pointer hover:bg-[#E4E4E4] hover:text-white"
              >
                Restrict User
              </p>
            ) : (
              <p
                onClick={() => {
                  setOpenConfirmModal(true);
                  setOpenMenu(false);
                  setActionType("unrestrict");
                }}
                className="text-[#656565] px-2 py-2 cursor-pointer hover:bg-[#E4E4E4] hover:text-white"
              >
                Unrestrict User
              </p>
            )}

            <p
              onClick={() => {
                setOpenConfirmModal(true);
                setOpenMenu(false);
                setActionType("delete");
              }}
              className="text-[#FF4040] px-2 py-2 cursor-pointer hover:bg-[#E4E4E4] hover:text-white"
            >
              Delete User
            </p>
          </div>
        </BasicMenu>
      )}

      <div>
        <div className="flex flex-col gap-2 items-center border-1 border-main-border-color rounded-md py-4 px-2">
          <p className="text-[20px] text-[#252424] font-medium">
            Total Customers
          </p>

          <Fade>
            <p className="text-[30px] text-main-color font-bold">
              {totalCustomers}
            </p>
          </Fade>

          <p className="text-[14px] text-[#252424]">
            These are the total number of customers you have
          </p>
        </div>

        <Fade duration={1000}>
          <div className="flex gap-5 items-center mt-6">
            <Search
              placeholder={`Search for your customer by ${filter.value === "f_name" ? "first name" : filter.value === "l_name" ? "last name" : filter.value}`}
              handleChange={(e) => setSearchText(e.target.value)}
              handleClick={() => startSearch(searchText)}
            />

            <CustomDropdown
              trimtext
              trimLength={7}
              data={listOptions}
              className="w-28 h-12  rounded"
              optionClass="w-28"
              value={filter}
              onChangeOption={(val) => {
                setFilter(val);
                setSearchText("");
              }}
            />
          </div>
        </Fade>

        <div className="mt-5 border-1 border-main-border-color rounded-md">
          <div className="flex justify-between px-3 py-3">
            <p className="text-[16px] text-main-color font-semibold">
              Customers
            </p>
            <Pagination
              limit
              handleFetchPagination={fetchCustomers}
              currentPage={currentPage}
              pageValue={pageValue}
              handleChange={(e) => {
                if (e.target.value >= 0) setCurrentPage(e.target.value);
              }}
              handleChangeLimitRange={(e) => {
                if (e >= 0) setLimitRange(e);
              }}
              loading={loadingCustomers}
            />
          </div>

          {!loadingCustomers ? (
            <div className="max-h-[70vh] min-h-[47vh] w-full overflow-y-auto">
              <table className="w-full" cellSpacing="0">
                <thead className="sticky w-full top-0 z-10 bg-[white] text-[14px] border-b-1 border-main-border-color border-dashed">
                  <td className="py-3 px-3 min-w-[150px]">Name</td>
                  <td className="">Verify BVN</td>
                  <td className="">Documents</td>
                  <td className="">View Accounts</td>
                  <td className="">Send Email</td>
                  <td className="">Account Status</td>
                  <td className="pr-3 w-[50px]">More</td>
                </thead>

                {customersList.map((dta, index) => (
                  <tr className="odd:bg-[#FCFCFC]">
                    <td className="py-3 px-3">
                      <Fade duration={1000}>
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            navigate("/new-customers/details", { state: dta })
                          }
                        >
                          {`${dta.f_name} ${dta.l_name}`}
                        </span>
                      </Fade>
                    </td>

                    <td
                      className="text-[30px]"
                      onClick={() => {
                        openBvn();
                        verifyBvn(dta.bvn);
                      }}
                    >
                      <Fade duration={1000}>
                        <IoMdFingerPrint className="cursor-pointer" />
                      </Fade>
                    </td>

                    <td>
                      <Fade duration={1000}>
                        <BsFolderFill
                          size={22}
                          className="text-[black] cursor-pointer"
                          onClick={() => handleOpenDocs(dta)}
                        />
                      </Fade>
                    </td>

                    <td>
                      <Fade duration={1000}>
                        <p
                          className="cursor-pointer"
                          onClick={() =>
                            navigate("/new-customers/user-account", {
                              state: {
                                id: dta.id,
                                user: `${dta.f_name} ${dta.l_name}`,
                                image: dta.image,
                              },
                            })
                          }
                        >
                          Accounts
                        </p>
                      </Fade>
                    </td>

                    <td className="text-[20px]">
                      <span
                        onClick={() => {
                          setMail(true);
                          setCurrentUser(dta);
                        }}
                      >
                        <Fade duration={1000}>
                          <MdEmail className="cursor-pointer" />
                        </Fade>
                      </span>
                    </td>

                    <td>
                      <Fade duration={1000}>
                        <div className="cursor-pointer">
                          <p
                            onClick={(e) => {
                              setOpenApproveMenu(e.currentTarget);
                              setMenuIndex(index);
                              setReason("");
                            }}
                            className={`cursor-pointer ${dta?.approved === 1 && "bg-[#8CFFAC] text-[#008000]"} ${dta?.approved === -1 && "bg-[#FF9A8C] text-[#FF0000]"} ${dta?.approved === 0 && "bg-[#ffbd33] text-[#ff5733]"} text-[12px] min-w-[70px] max-w-[100px] px-3 py-2 text-center rounded-xl`}
                          >
                            {writeApproveText(dta?.approved)}
                          </p>

                          {menuIndex === index && (
                            <BasicMenu
                              openMenu={openApproveMenu}
                              setOpenMenu={
                                !loadingApprove && handleCloseApproveMenu
                              }
                            >
                              {!clickedDisapprove ? (
                                <div>
                                  {!loadingApprove ? (
                                    <div className="text-[12px]">
                                      <p
                                        onClick={() => {
                                          handleApproveUser(dta?.id);
                                        }}
                                        className="px-2 py-2 cursor-pointer hover:bg-[#E4E4E4] hover:text-white"
                                      >
                                        Approve
                                      </p>

                                      <p
                                        onClick={() => {
                                          setClickedDisapprove(true);
                                        }}
                                        className="px-2 py-2 cursor-pointer hover:bg-[#E4E4E4] hover:text-white"
                                      >
                                        Disapprove
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="w-32 h-16 flex justify-center items-center">
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {!loadingApprove ? (
                                    <div className="w-56 h-44 flex flex-col justify-between px-3 py-3 items-center">
                                      <p className="text-[12px] text-center">
                                        Why would you like to disapprove this
                                        account?
                                      </p>

                                      <input
                                        className="w-[90%] py-1 text-[12px]"
                                        type="text"
                                        value={reason}
                                        onChange={(e) =>
                                          setReason(e.target.value)
                                        }
                                      />

                                      <Button
                                        text={"Submit"}
                                        className="text-[12px]"
                                        handleClick={() =>
                                          handleDisapproveUser(dta?.id)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-32 h-16 flex justify-center items-center">
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              )}
                            </BasicMenu>
                          )}
                        </div>
                      </Fade>
                    </td>

                    <td className="pr-3">
                      <Fade duration={1000}>
                        <p
                          className={`cursor-pointer text-[12px] w-[80px] py-2 text-center rounded-xl ${dta?.restrict_user ? "text-[#FF0000] bg-[#FF9A8C]" : "bg-[#8CFFAC] text-[#008000]"} `}
                          onClick={(event) => handleOpenMenu(event, dta)}
                        >
                          {dta?.restrict_user ? "Restricted" : "Active"}
                        </p>
                      </Fade>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          ) : (
            <div className="h-[50vh]">
              <Loader />
            </div>
          )}
        </div>
      </div>

      <div>
        <Modal
          open={bvnLoader}
          onClose={closeBvn}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <center className="dialog-text">
              {!bvnMsg ? "Verifying BVN" : ""}
            </center>

            <br />

            {verifyingBvn ? (
              <Loader />
            ) : (
              <center>
                {!errbvn ? (
                  <img src={done} alt="img" />
                ) : (
                  <span style={{ color: "red", fontSize: "26px" }}>
                    <BiErrorAlt />
                  </span>
                )}
                <br />
                <br />
                <p className="dialog-text">{bvnMsg}</p>
                <br />

                {bvnData && (
                  <p style={{ marginTop: "20px" }} className="dialog-text">
                    <p>Name: {bvnData.FirstName}</p>
                    <br />
                    <p>Last Name: {bvnData.LastName}</p>
                    <br />
                    <p>BVN: {bvnData.BVN}</p>
                    <br />
                    <p>Phone Number: {bvnData.phoneNumber}</p>
                    <br />
                    <p>DOB: {bvnData.DOB}</p>
                    <br />
                  </p>
                )}
              </center>
            )}
          </Box>
        </Modal>
      </div>

      <SendEmailModal
        open={mail}
        close={() => setMail(false)}
        user={current_user}
      />
    </MainLayout>
  );
};

export default NewCustomers;
