import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ImCancelCircle } from "react-icons/im";
import { Typography } from "@mui/material";

export default function ModalTemplate({
  openModalTemplate,
  handleCloseModalTemplate,
  title = "",
  children,
  style = "",
}) {
  const handleClose = () => {
    handleCloseModalTemplate();
  };

  return (
    <div>
      <Modal open={openModalTemplate} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "640px",
            height: "456px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            borderRadius: "0.5rem",
            gap: "3rem",
            ...style,
          }}
        >
          {/* row heading */}
          <Box
            sx={{
              marginLeft: "9rem",
              display: "flex",
              alignItems: "flex-start",
              gap: "8rem",
              fontSize: "28px",
              color: "red",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#252424", fontSize: "25px", fontWeight: "500" }}
            >
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }}>
              <ImCancelCircle onClick={handleClose} />
            </Box>
          </Box>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
