import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function BasicMenu({ openMenu, setOpenMenu, children }) {
  const open = Boolean(openMenu);

  const handleClose = () => {
    setOpenMenu(null);
  };

  // const handleOpenPin = () => {
  //   handleOpenChangePin()
  //   setOpenMenu(null);

  // }

  // const handleOpenPassword = () => {
  //   handleOpenChangePassword()
  //   setOpenMenu(null);

  // }

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
}
