/* eslint-disable consistent-return */
/* eslint-disable no-else-return */

import { useState } from "react";

import CustomModal from "../../../ReuseComponent/Modal/CustomModal";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { FcApproval } from "react-icons/fc";
import { MdOutlineCancel, MdWarning } from "react-icons/md";
import Button from "../../../ReuseComponent/FormUtils/Button";
import Loader from "../../../ReuseComponent/Loader";
import { useSelector, useDispatch } from "react-redux";
import { approveDocumentThunk } from "../../../ReduxStore/Customers";
import { toast } from "react-toastify";
import ReplaceDoc from "./ReplaceDoc";

const checkDocType = (name) => {
  return name?.split(".")?.reverse()[0]?.toLowerCase();
};

const verification = (val) => {
  if (Number(val) === 1) {
    return (
      <div className="flex gap-2 items-center">
        <MdWarning className="text-[#ff7933]" size={20} />
        <p className="text-[18px]">Awaiting Review</p>
      </div>
    );
  } else if (Number(val) === 2) {
    return (
      <div className="flex gap-2 items-center">
        <FcApproval className="" size={20} />
        <p className="text-[18px]">Approved</p>
      </div>
    );
  } else if (Number(val) === 0) {
    return (
      <div className="flex gap-2 items-center">
        <MdOutlineCancel className="text-[red]" size={20} />
        <p className="text-[18px]">Unaccepted</p>
      </div>
    );
  } else {
    return <p />;
  }
};
const Document = ({ openModal, handleCloseModal, data, callBack }) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.customers)?.loadingDoc;

  const [attachementList, setAttachmentList] = useState(data?.docs);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [openReplaceDoc, setOpenReplaceDoc] = useState(false);

  const downloadFile = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const uri = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = uri;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(uri);
      });
  };

  const handleApproveDoc = (status, msg) => {
    dispatch(
      approveDocumentThunk({
        user_id: data?.id,
        doc: attachementList[selectedIndex]?.v,
        status,
      }),
    ).then((v) => {
      if (v?.payload?.status === 200) {
        toast.success(msg);
        callBack();
        handleCloseModal();
      } else {
        toast.error(v?.payload?.data?.message);
      }
    });
  };

  return (
    <div>
      {openReplaceDoc && (
        <ReplaceDoc
          openModal={openReplaceDoc}
          handleCloseModal={() => setOpenReplaceDoc(false)}
          field={attachementList[selectedIndex]?.v}
          data={data}
          callBack={() => {
            callBack();
            handleCloseModal();
          }}
        />
      )}
      <CustomModal
        className="!w-[60vw] h-[80vh]"
        open={openModal}
        handleClose={() => !loading && handleCloseModal()}
      >
        {!loading ? (
          <div className="w-[100%] h-full pb-5 pt-7  flex flex-col justify-between items-center">
            <div className="flex items-center gap-3 w-[100%] h-[80%]">
              <ArrowButton
                handleClick={() => {
                  if (selectedIndex >= 1) {
                    setSelectedIndex(selectedIndex - 1);
                  }
                }}
                Icon={AiOutlineArrowLeft}
              />

              <div className="w-[100%] h-[100%]">
                {checkDocType(attachementList[selectedIndex]?.url) === "pdf" ? (
                  <PDFViewer pdfUrl={attachementList[selectedIndex]?.url} />
                ) : (
                  <div className="h-full w-full aspect-ratio-16/9 flex justify-center">
                    <img
                      className="min-w-[30%] inset-0 object-cover"
                      src={attachementList[selectedIndex]?.url}
                      alt=""
                    />
                  </div>
                )}
              </div>

              <ArrowButton
                handleClick={() => {
                  if (selectedIndex < (attachementList?.length ?? 0) - 1) {
                    setSelectedIndex(selectedIndex + 1);
                  }
                }}
                Icon={AiOutlineArrowRight}
              />
            </div>

            <div className="flex gap-2">
              {attachementList?.map((_, index) => (
                <p
                  onClick={() => setSelectedIndex(index)}
                  className={`w-3 h-3 rounded-full ${selectedIndex === index ? "bg-[blue]" : "border-1 border-gray-500"} `}
                />
              ))}
            </div>

            <div className="flex justify-between items-center self-start w-full px-3">
              <div className="flex gap-5 items-center">
                {attachementList[selectedIndex].url !== "-" && (
                  <HiDownload
                    onClick={() =>
                      downloadFile(
                        attachementList[selectedIndex].url,
                        `${data?.f_name}-${attachementList[selectedIndex].url}`,
                      )
                    }
                    size={18}
                    className="cursor-pointer"
                  />
                )}

                <p className="font-semibold text-[16px]">
                  {attachementList[selectedIndex].name}
                </p>

                {verification(attachementList[selectedIndex].verified)}
              </div>

              <p
                onClick={() => setOpenReplaceDoc(true)}
                className="cursor-pointer text-main-color"
              >
                Replace Document
              </p>

              <div className="flex gap-3">
                <Button
                  handleClick={() => handleApproveDoc("verified", "Approved")}
                  text="Approve"
                />

                <Button
                  handleClick={() =>
                    handleApproveDoc("unacceptable", "Declined")
                  }
                  outline
                  text="Decline"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[100%] h-full flex justify-between items-center">
            <Loader />
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default Document;

const ArrowButton = ({ Icon, handleClick = () => {} }) => {
  return (
    <div
      onClick={handleClick}
      className="px-3 py-3 rounded-full cursor-pointer hover:bg-gray-100"
    >
      <Icon size={18} />
    </div>
  );
};

const PDFViewer = ({ pdfUrl }) => {
  return (
    <iframe
      title="pdfviewer"
      className="h-full w-full"
      style={{ objectFit: "fill" }}
      src={pdfUrl}
    />
  );
};
