import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";

const makeRequest = new CustomerPage();

export default function ProgressLoader({
  openLoader,
  handleCloseLoader,
  data,
  _user,
}) {
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const user = _user[0];

  const navigate = useNavigate();

  const handleClose = () => {
    // handleCloseLoader()
  };
  const datas = {
    user_id: user.zikora_user_id === undefined ? user.id : user.zikora_user_id,
    new_password: data.newPassword,
  };
  useEffect(() => {
    makeRequest
      .adminChangePassword(datas)
      .then((value) => {
        // console.log(value.data)
        handleCloseLoader();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) navigate("/login");
      });
  }, []);

  return (
    <div>
      <Modal open={openLoader} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "390px",
            height: "200px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            borderRadius: "0.5rem",
            justifyContent: "space-between",
          }}
        >
          {/* row heading */}

          <Typography
            sx={{ color: "#252424", fontSize: "25px", fontWeight: "500" }}
          >
            Please wait...
          </Typography>
          <CircularProgress size={100} sx={{ color: "#83C79F" }} />
        </Box>
      </Modal>
    </div>
  );
}
