import { getConfig, getUploadFileConfig } from "../../Utilt/Config";
import axios from "axios";
import { conn } from "../../Utilt/conn";

export const getBillerCategories = async () => {
  const result = await axios.get(`${conn}/bills/categories`, getConfig());
  return result;
};

export const getSingleBillerCategory = async (data) => {
  const result = await axios.post(
    `${conn}/bills/billers_by_cat`,
    { cat_id: data },
    getConfig(),
  );
  return result;
};

export const getPaymentItem = async (data) => {
  const result = await axios.post(
    `${conn}/bills/billers/payment_items`,
    { biller_id: data },
    getConfig(),
  );
  return result;
};
