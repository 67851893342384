import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImCancelCircle } from "react-icons/im";
import { conn } from "../../../Utilt/conn";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";

export default function ConfirmAdminPassword({
  openConfirm,
  handleCloseConfirm,
  handleSubmitConfirm,
}) {
  const navigate = useNavigate();
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isloading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    handleCloseConfirm();
  };
  const handleSubmit = () => {
    if (password === "") {
      setError(true);
      setErrorMessage("Password most not be empty!");
    } else {
      setError(false);
      setIsLoading(true);

      axios
        .post(`${conn}/admin/authenticate`, {
          email: auth.email,
          password,
          device_id: "3333",
          device_type: "computer",
          device_name: "Del",
        })
        .then((value) => {
          window.localStorage.setItem("token", value.data.token);
          window.localStorage.setItem("refreshtoken", value.data.refreshToken);
          setIsLoading(false);
          handleSubmitConfirm();
        })
        .catch((err) => {
          setIsLoading(false);
          setError(true);
          setErrorMessage("Incorrect Password");
          if (err.response.status === 401) navigate("/login");
        });
    }
  };

  return (
    <div>
      <Modal open={openConfirm} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "469px",
            height: "300px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            borderRadius: "0.5rem",
            gap: "2rem",
          }}
        >
          {/* row heading */}
          <Box
            sx={{
              marginLeft: "5rem",
              display: "flex",
              alignItems: "flex-start",
              gap: "5rem",
              fontSize: "28px",
              color: "red",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#252424", fontSize: "25px", fontWeight: "500" }}
            >
              Confirmation Pin
            </Typography>
            <Box sx={{ cursor: "pointer" }}>
              <ImCancelCircle onClick={handleClose} />
            </Box>
          </Box>

          <Typography sx={{ color: "#404040", fontSize: "14px" }}>
            Please enter your Admin Password to effect change.
          </Typography>

          {/* row form */}
          <TextField
            sx={{ width: "70%" }}
            id="standard-basic"
            label="Enter Admin Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
          />

          {error && (
            <Typography sx={{ color: "red", fontSize: "14px" }}>
              {errorMessage}
            </Typography>
          )}

          {/* row button */}
          {!isloading && (
            <Button
              variant="contained"
              disableElevation
              sx={{
                width: "70%",
                backgroundColor: "#66A681",
                fontSize: "18px",
                textTransform: "none",
                ":hover": {
                  backgroundColor: "rgba(102, 166, 129, 0.7)",
                },
              }}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          )}
          {isloading && <Loader />}
        </Box>
      </Modal>
    </div>
  );
}
