import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { conn } from "../Utilt/conn";

const zikoraApi = createApi({
  refetchOnReconnect: true,
  reducerPath: "zikoraApi",
  tagTypes: ["Notifications"],
  baseQuery: fetchBaseQuery({
    baseUrl: conn,

    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem("token");
      const refreshToken = window.localStorage.getItem("refreshtoken");

      if (token) {
        headers.set("token", token);
      }

      if (refreshToken) {
        headers.set("refreshtoken", refreshToken);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default zikoraApi;
