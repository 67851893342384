/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCustomers,
  searchCustomers,
  approveUser,
  disApproveUser,
  approveDocument,
  replaceDocument,
  getTransactions,
  getSingleUserTransactions,
  getAccountByNumber,
  feedbackMail,
  verifyBvn,
  getCustomerInfo,
  adminUpdateUserInfo,
} from "../Network/Customers";

export const getCustomersThunk = createAsyncThunk(
  "customers/getCustomers",
  getCustomers,
);
export const getCustomersNoLoadingThunk = createAsyncThunk(
  "customers/getCustomers",
  getCustomers,
);
export const searchCustomersThunk = createAsyncThunk(
  "customers/searchCustomers",
  searchCustomers,
);
export const approveUserThunk = createAsyncThunk(
  "customers/approveUser",
  approveUser,
);
export const disApproveUserThunk = createAsyncThunk(
  "customers/disApproveUser",
  disApproveUser,
);
export const approveDocumentThunk = createAsyncThunk(
  "customers/approveDocument",
  approveDocument,
);
export const replaceDocumentThunk = createAsyncThunk(
  "customers/replaceDocument",
  replaceDocument,
);

export const getTransactionsThunk = createAsyncThunk(
  "customers/getTransactions",
  getTransactions,
);
export const getAccountByNumberThunk = createAsyncThunk(
  "customers/getAccountByNumber",
  getAccountByNumber,
);

export const getSingleUserTransactionsThunk = createAsyncThunk(
  "customers/getSingleUserTransactions",
  getSingleUserTransactions,
);

export const feedbackMailThunk = createAsyncThunk(
  "feedback/feedbackMail",
  feedbackMail,
);
export const verifyBvnThunk = createAsyncThunk(
  "customers/verifyBvn",
  verifyBvn,
);
export const getCustomerInfoThunk = createAsyncThunk(
  "customers/getCustomerInfo",
  getCustomerInfo,
);
export const getCustomerInfoNoLoadingThunk = createAsyncThunk(
  "customers/getCustomerInfoNoLoading",
  getCustomerInfo,
);

export const adminUpdateUserInfoThunk = createAsyncThunk(
  "customers/adminUpdateUserInfo",
  adminUpdateUserInfo,
);

const initialState = {
  customersList: [],
  transactionList: [],
  userTransactions: [],
  loading: false,
  loadingApprove: false,
  loadingDoc: false,
  loadingReplaceDoc: false,
  loadingVerifyBvn: false,
  loadingUpdateUserInfo: false,
  account: null,
  customerInfo: null,
};

const CustomersSlice = createSlice({
  name: "customers",
  initialState,

  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },

  extraReducers: {
    [getCustomersThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomersThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.customersList = action.payload.data?.data || [];
    },
    [getCustomersThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getCustomersNoLoadingThunk.pending]: (state, action) => {},
    [getCustomersNoLoadingThunk.fulfilled]: (state, action) => {
      state.customersList = action.payload.data?.data || [];
    },
    [getCustomersNoLoadingThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTransactionsThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getTransactionsThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactionList = action.payload.data?.data || [];
    },
    [getTransactionsThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getSingleUserTransactionsThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getSingleUserTransactionsThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.userTransactions = action.payload.data?.data || [];
    },
    [getSingleUserTransactionsThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [searchCustomersThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [searchCustomersThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.customersList = action.payload.data?.data || [];
    },
    [searchCustomersThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [approveUserThunk.pending]: (state, action) => {
      state.loadingApprove = true;
    },
    [approveUserThunk.fulfilled]: (state, action) => {
      state.loadingApprove = false;
    },
    [approveUserThunk.rejected]: (state, action) => {
      state.loadingApprove = false;
    },

    [disApproveUserThunk.pending]: (state, action) => {
      state.loadingApprove = true;
    },
    [disApproveUserThunk.fulfilled]: (state, action) => {
      state.loadingApprove = false;
    },
    [disApproveUserThunk.rejected]: (state, action) => {
      state.loadingApprove = false;
    },

    [approveDocumentThunk.pending]: (state, action) => {
      state.loadingDoc = true;
    },
    [approveDocumentThunk.fulfilled]: (state, action) => {
      state.loadingDoc = false;
    },
    [approveDocumentThunk.rejected]: (state, action) => {
      state.loadingDoc = false;
    },

    [replaceDocumentThunk.pending]: (state, action) => {
      state.loadingReplaceDoc = true;
    },
    [replaceDocumentThunk.fulfilled]: (state, action) => {
      state.loadingReplaceDoc = false;
    },
    [replaceDocumentThunk.rejected]: (state, action) => {
      state.loadingReplaceDoc = false;
    },

    [getAccountByNumberThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getAccountByNumberThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.account = action.payload.data?.data || null;
    },
    [getAccountByNumberThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [feedbackMailThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [feedbackMailThunk.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [feedbackMailThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [verifyBvnThunk.pending]: (state, action) => {
      state.loadingVerifyBvn = true;
    },
    [verifyBvnThunk.fulfilled]: (state, action) => {
      state.loadingVerifyBvn = false;
    },
    [verifyBvnThunk.rejected]: (state, action) => {
      state.loadingVerifyBvn = false;
    },

    [getCustomerInfoThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerInfoThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerInfo = action.payload.data?.data || null;
    },
    [getCustomerInfoThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getCustomerInfoNoLoadingThunk.pending]: (state, action) => {},
    [getCustomerInfoNoLoadingThunk.fulfilled]: (state, action) => {
      state.customerInfo = action.payload.data?.data || null;
    },
    [getCustomerInfoNoLoadingThunk.rejected]: (state, action) => {},

    [adminUpdateUserInfoThunk.pending]: (state, action) => {
      state.loadingUpdateUserInfo = true;
    },
    [adminUpdateUserInfoThunk.fulfilled]: (state, action) => {
      state.loadingUpdateUserInfo = false;
    },
    [adminUpdateUserInfoThunk.rejected]: (state, action) => {
      state.loadingUpdateUserInfo = false;
    },
  },
});

export const { setLoading } = CustomersSlice.actions;
export default CustomersSlice.reducer;
