import React from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { SignInForm } from "./Components/LoginPage/SignInForm/SignInForm";
import { WelcomeBack } from "./Components/WelcomeBack/WelcomeBack";
import BreakPoint from "./Components/BreakPoint/BreakPoint";
import AppToast from "./ReuseComponent/Toaster/AppToast";

import Home from "./Screens/Home/Home";
import NewCustomers from "./Screens/Customers/NewCustomers";
import { NewUsersAccount } from "./Screens/UsersAccount/NewUsersAccount";
import { NewTransactions } from "./Screens/Transactions/NewTransaction";
import NewOnboardUser from "./Screens/OnboardUser/NewOnboardUser";
import UserTransaction from "./Screens/UserTransaction/UserTransaction";
import { NewFeedback } from "./Screens/Feedback/NewFeedback";
import NewRequest from "./Screens/Request/NewRequest";
import RequestDetails from "./Screens/Request/RequestDetails";
import { NewExistingAccounts } from "./Screens/ExistingAccount/NewExistingAccount";
import { NewGetProfile } from "./Screens/GetProfile/NewGetProfile";
import FeedbackDetail from "./Screens/FeedbackDetail/FeedbackDetail";
import BillPayment from "./Screens/BillPayment/BillPayment";
import Transfer from "./Screens/Transfer/Transfer";
import { NewCustomerSummary } from "./Screens/CustomerSummary/NewCustomerSummary";

function App() {
  return (
    <>
      <div className="app">
        <AppToast />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<SignInForm />} />

            <Route path="/" exact element={<WelcomeBack />} />

            <Route path="/home" element={<Home />} />
            <Route path="/new-customers" element={<NewCustomers />} />
            <Route
              path="/new-customers/user-account"
              element={<NewUsersAccount />}
            />
            <Route
              path="/new-customers/transactions"
              element={<NewTransactions />}
            />
            <Route
              path="/new-customers/details"
              element={<NewCustomerSummary />}
            />
            <Route path="/user-transactions" element={<UserTransaction />} />
            <Route path="/new-onboard-user" element={<NewOnboardUser />} />
            <Route path="/new-feedback" element={<NewFeedback />} />
            <Route path="/new-feedback/:id" element={<FeedbackDetail />} />
            <Route path="/new-request" element={<NewRequest />} />
            <Route path="/new-request/:id" element={<RequestDetails />} />
            <Route path="/user-account" element={<NewExistingAccounts />} />
            <Route
              path="/user-account/transactions"
              element={<NewTransactions />}
            />
            <Route path="/profile" element={<NewGetProfile />} />
            <Route path="/bill-payment" element={<BillPayment />} />
            <Route path="/transfer" element={<Transfer />} />
          </Routes>
        </BrowserRouter>
      </div>

      <span className="break">
        <BreakPoint />
      </span>
    </>
  );
}

// Prop is a property for individual components

// States

export default App;
