import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImCancelCircle } from "react-icons/im";
import tickPic from "../../../Assets/success-tick.png";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";
import Loader from "../../Loader/Loader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const makeRequest = new CustomerPage();
export default function SuccessPin({
  openSuccesPin,
  handleCloseSuccessPin,
  _user,
  pin,
  limitData,
  action,
}) {
  const navigate = useNavigate();
  const user = _user[0];
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const [isLoading, setIsLoading] = useState(false);
  const [send, setSend] = useState(false);

  let textt;
  if (action === "pin") textt = "PIN";
  else if (action === "limit") textt = "Limit";
  else if (action === "restrict") textt = "Restrict";

  const handleClose = () => {
    handleCloseSuccessPin();
  };
  const handleSubmit = () => {
    setIsLoading(true);
    let data;

    if (action === "pin") {
      data = {
        user_id:
          user.zikora_user_id === undefined ? user.id : user.zikora_user_id,
        sender_id: auth.id,
        subject: "New Transaction PIN",
        body: `Per your request, we have changed your transaction PIN and the new Transaction PIN is: PIN: ${pin.newPin} `,
      };
    } else if (action === "limit") {
      data = {
        user_id:
          user.zikora_user_id === undefined ? user.id : user.zikora_user_id,
        sender_id: auth.id.toString(),
        subject: "Transaction Limit",
        body: `Congratulations, your account transfer limit has been successfully updated to N ${limitData.increase}. You can now enjoy the increased limit for your transactions. `,
      };
    }

    makeRequest
      .adminSendEmail(data)
      .then((value) => {
        setIsLoading(false);
        setSend(true);
        window.localStorage.setItem("token", value.data.token);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (error.response.status === 401) navigate("/login");
      });
  };

  return (
    <div>
      <Modal open={openSuccesPin} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "469px",
            minHeight: "375px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 2rem",
            borderRadius: "0.5rem",
            gap: "2rem",
          }}
        >
          {/* row heading */}
          <Box
            sx={{
              cursor: "pointer",
              fontSize: "28px",
              color: "red",
              alignSelf: "flex-end",
            }}
          >
            <ImCancelCircle onClick={handleClose} />
          </Box>

          {/* row description  */}
          <Box
            sx={{
              backgroundImage: `url(${tickPic})`,
              width: "70px",
              height: "70px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          />

          {/* row form */}
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "500",
              color: "#263238",
              textAlign: "center",
            }}
          >
            {textt === "restrict"
              ? "Account has been successfully restricted."
              : `${textt} has been successfully changed.`}
          </Typography>

          {/* row button */}
          {!isLoading && !send && (
            <Button
              variant="contained"
              disableElevation
              sx={{
                minWidth: "70%",
                backgroundColor: "#66A681",
                fontSize: "18px",
                textTransform: "none",
                ":hover": {
                  backgroundColor: "rgba(102, 166, 129, 0.7)",
                },
              }}
              onClick={handleSubmit}
            >
              Send New {textt} to{" "}
              {user.account_name === undefined
                ? user.f_name
                : user.account_name.split(" ")[0]}
            </Button>
          )}

          {isLoading && <Loader />}

          {!isLoading && send && (
            <Button
              sx={{
                textTransform: "none",
                backgroundColor: "#66A681",
                fontSize: "18px",

                ":hover": {
                  backgroundColor: "rgba(102, 166, 129, 0.7)",
                },
              }}
              disableElevation
              variant="contained"
              onClick={handleClose}
            >
              send successfull
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}
