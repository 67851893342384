/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { createSlice } from "@reduxjs/toolkit";

const date = new Date();

const end = `${date.toISOString().split("T")[0]}T23:59:59.000Z`;

const solve_starting = new Date(date.setMonth(date.getMonth() - 2));

const starting = `${solve_starting.toISOString().split("T")[0]}T23:59:59.000Z`;

const initialStateValue = {
  transactedValue: 0,
  total_accounts: 0,
  total_transactions: 0,
  total_male_user: 0,
  total_female_user: 0,
  starting,
  ending: end,
};

export const MetricsSlice = createSlice({
  name: "metrics",
  initialState: initialStateValue,
  reducers: {
    updateTransactionCount: (state, action) => {
      state.total_transactions = action.payload;
    },

    updateTransactionValue: (state, action) => {
      state.transactedValue = action.payload;
    },

    updateTotalAccounts: (state, action) => {
      state.total_accounts = action.payload;
    },

    updateMaleUsers: (state, action) => {
      state.total_male_user = action.payload;
    },

    updateFemaleUsers: (state, action) => {
      state.total_female_user = action.payload;
    },

    updateDates: (state, action) => {
      state.starting = action.payload.starting;
      state.ending = action.payload.ending;
    },
  },
});

export const {
  updateTransactionCount,
  updateTransactionValue,
  updateTotalAccounts,
  updateMaleUsers,
  updateFemaleUsers,
  updateDates,
} = MetricsSlice.actions;

export default MetricsSlice.reducer;
