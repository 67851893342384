/* eslint-disable consistent-return */
import axios from "axios";
import { getConfig } from "../Utilt/Config";
import { conn } from "../Utilt/conn";
import zikoraApi from "./api";

export const getAllFeedbacks = async ({ page = 1 }) => {
  try {
    const response = await axios.get(
      `${conn}/admin/feedback/fetch?page=${page}`,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const feedbackMail = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/send_email_to_user`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const updateFeedbackStatus = async (data) => {
  try {
    const response = await axios.put(
      `${conn}/admin/feedback/update_status`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

const feedback = zikoraApi.injectEndpoints({
  endpoints: (builder) => ({
    getSingleFeedBackById: builder.query({
      query: (id) => `/get-feedback-using-id/${id}`,
      keepUnusedDataFor: 30,
    }),
  }),
});

export const { useGetSingleFeedBackByIdQuery } = feedback;
