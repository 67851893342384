import "./spinkit.css";

const Loader = ({ className = "" }) => {
  return (
    <div className="w-full flex justify-center">
      <div className={`w-[50px] h-[50px] ${className}`}>
        <div className="sk-bounce">
          <div
            className="sk-bounce-dot"
            style={{ backgroundColor: "#4c6356" }}
          />
          <div
            className="sk-bounce-dot"
            style={{ backgroundColor: "#4c6356" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;
