import React from "react";
import "./TransactionValue.css";
import {
  YAxis,
  XAxis,
  LineChart,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import { highest } from "../../../../Utilt/EstimateHighest";
import { nFormatter } from "../../../../APIs/Metrics/Metrics";
import capitalizeFirstLetter from "../../../../Utilt/capitalizeFirstLetter";
import dayjs from "dayjs";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    // You can access the tooltip values from 'payload'
    return (
      <div className="custom-tooltip bg-[white] shadow-shift-shadow-small rounded font-bold px-4 py-2 text-[14px] ">
        <p>Date: {dayjs(label).format("DD MMM YYYY")}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${capitalizeFirstLetter(entry.name)}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className="flex justify-center gap-5 font-bold">
      {payload.map((entry, index) => (
        <div className="flex gap-2 items-center">
          <p
            className="w-2 h-2 rounded-full"
            style={{ background: entry.color }}
          />
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {capitalizeFirstLetter(entry.value.split("_")[0])}{" "}
            {capitalizeFirstLetter(entry.value.split("_")[1])}
          </p>
        </div>
      ))}
    </div>
  );
};

const TransactionValue = ({ transCount = null }) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-[16px] text-main-color">Transaction value</p>

      <div className="h-[200px] pt-[20px]">
        {transCount !== null && transCount.length > 0 && (
          <ResponsiveContainer className="" width={"100%"} height={"100%"}>
            <LineChart width={500} height={300} data={transCount}>
              <XAxis
                interval={Math.floor(transCount / 9)}
                angle={0}
                tick={{ stroke: "rgb(125, 123, 123)", strokeWidth: 0.3 }}
                dx={10}
                dy={8}
                dataKey="date"
                tickLine={false}
                padding={{ left: 0, right: 20 }}
                tickSize={10}
                allowDataOverflow
                minTickGap={30}
                style={{
                  backgroundColor: "#fff",
                  fontSize: "10px",
                  zIndex: "2",
                }}
              />
              <YAxis
                tickFormatter={nFormatter}
                allowDecimals={false}
                dataKey="in"
                domain={[0, highest(transCount) + 10]}
                tickSize={22}
                tickLine={false}
                axisLine={false}
                padding={{ bottom: 0 }}
              />

              <Tooltip content={(props) => CustomTooltip(props)} />
              <Legend content={(props) => CustomLegend(props)} />
              <Line
                type="monotone"
                dataKey="in"
                stroke="#48ff7d"
                fill="#E0FFE9"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="out"
                stroke="#FF9A8C"
                fill="#FF9A8C"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}

        {/* {(transCount !== null && transCount.length>0) && 
        <ResponsiveContainer width="100%" height={180}>

          <AreaChart data={transCount}>

            <YAxis tickFormatter={nFormatter} allowDecimals={false} dataKey="in" domain={[0, highest(transCount)+10]} tickSize={22} tickLine={false} axisLine={false} padding={{ bottom: 0 }}
              style={{ fontSize: "10px", }}
            />
            <Area type="monotone" dataKey="in" stroke="#E0FFE9" fill="#E0FFE9" />
            <XAxis interval={Math.floor(transCount / 9)} angle={0} tick={{ stroke: 'rgb(125, 123, 123)', strokeWidth: 0.3 }} dx={10} dy={8} dataKey="date" tickLine={false} padding={{ left: 0, right: 20 }} tickSize={10} allowDataOverflow={true} minTickGap={30} style={{ backgroundColor: "#fff", fontSize: "10px", zIndex: "2" }} />

          </AreaChart>

        </ResponsiveContainer>} */}
      </div>

      {/* <div className='h-[200px] pt-[20px]' >

        {(transCount !== null && transCount.length>0) && <ResponsiveContainer width="100%" height={180}>

          <AreaChart data={transCount}>

            <YAxis tickFormatter={nFormatter} allowDecimals={false} dataKey="out" domain={[0, highest(transCount)+10]}  tickSize={22} tickLine={false} axisLine={false} padding={{ bottom: 0 }}
              style={{ fontSize: "10px", }}
            />
            <Area type="monotone" dataKey="out" stroke="#FF9A8C" fill="#FF9A8C" />
            <XAxis interval={Math.floor(transCount / 9)} angle={0} tick={{ stroke: 'rgb(125, 123, 123)', strokeWidth: 0.3 }} dx={10} dy={8} dataKey="date" tickLine={false} padding={{ left: 0, right: 20 }} tickSize={10} allowDataOverflow={true} minTickGap={30} style={{ backgroundColor: "#fff", fontSize: "10px", zIndex: "2" }} />

          </AreaChart>

        </ResponsiveContainer>}

      </div> */}
    </div>
  );
};

export default TransactionValue;
