import "./Transaction.css";
import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import transact from "../../Assets/ICONS/transact.png";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import Search from "../../ReuseComponent/FormUtils/Search";
import TablePagination from "@mui/material/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import { getTransactionsThunk } from "../../ReduxStore/Customers";
import Loader from "../../ReuseComponent/Loader";

const { FormatMoney } = require("format-money-js");

const fm = new FormatMoney({
  decimals: 2,
});

export const NewTransactions = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const transactionList = useSelector(
    (state) => state.customers,
  )?.transactionList;
  const loading = useSelector((state) => state.customers)?.loading;

  const token = window.localStorage.getItem("token");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const FetchTransactions = async (id) => {
    dispatch(
      getTransactionsThunk({
        input: id.toString(),
        page: page + 1,
        limit: rowsPerPage,
      }),
    ).then((val) => {});
  };

  useEffect(() => {
    if (token === null && location.state === null) navigate("/welcome");
    FetchTransactions(location.state.nuban);
  }, [page, rowsPerPage]);

  return (
    <MainLayout title="Customers Transactions">
      <div className="flex flex-col gap-2 mt-7">
        <p>{location.state.nuban}</p>
        <p>Nuban</p>
      </div>

      <Fade>
        <Search
          className="mt-3"
          placeholder={"Search for transaction with reference number"}
          handleChange={(e) => {
            console.log(e.target.value);
          }}
        />
      </Fade>

      {transactionList.length !== 0 ? (
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <p className="text-[green]">Transactions</p>

            <TablePagination
              component="div"
              count={100}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          {!loading ? (
            <div className=" max-h-[60vh] w-full overflow-y-auto">
              <table className="w-full">
                <tr className="sticky w-full text-[black] top-0 z-10 bg-light-gray text-[16px]">
                  <td className="py-5 px-3 w-[35%]">Narration</td>
                  <td className="w-[10%]">Amount</td>
                  <td className="w-[10%]">Date</td>
                  <td className="w-[35%]">Transaction ref</td>
                  <td className="w-[10%]">Status</td>
                </tr>

                {transactionList.map((dt, key) => (
                  <tr key={key}>
                    <td className="py-3 px-3">
                      <Fade duration={1000}>
                        {`${dt.from_name} TO ${dt.to_name}`}
                      </Fade>
                    </td>

                    <td
                      style={
                        dt.from_account_no === location.state.nuban
                          ? { color: "red" }
                          : { color: "green" }
                      }
                    >
                      <Fade duration={1000}>
                        <span className="numba">
                          {fm.from(parseFloat(dt.amt / 100), { symbol: "" })}
                        </span>
                      </Fade>
                    </td>
                    <td className="numba">
                      <Fade>{dt.created_on.substring(0, 10)}</Fade>
                    </td>
                    <td className="pr-3">
                      <Fade duration={1000}>{dt.transaction_ref}</Fade>
                    </td>
                    <td>
                      <Fade duration={1000}>
                        <span style={{ color: "green" }}>Approved</span>
                      </Fade>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      ) : (
        <center className="empty">
          <img alt="" src={transact} />
          <br />
          <p
            style={{ textAlign: "center", margin: "1vh 0vh", fontSize: "14px" }}
          >
            No Transactions Yet
          </p>
        </center>
      )}
    </MainLayout>
  );
};
