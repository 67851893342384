import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, TextField } from "@mui/material";
import { Formik } from "formik";
import { deepOrange, deepPurple } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  TextAndField,
  Field,
  Text,
  FillButton,
} from "../../../ReuseComponent/FormUtils/FormUtils";
import CustumPopperSelect from "../../../ReuseComponent/CustumPopperSelect";
import { CustomerPage } from "../../../APIs/ServiceClass/CustomerPage";
import {
  getBanks,
  bankEnquiry,
} from "../../../APIs/ServiceClass/TransferService";
import Loader from "../../Loader/Loader";

const customerService = new CustomerPage();
const OtherBanks = ({ handleCallBack, selectedUser }) => {
  const navigate = useNavigate();

  // console.log(selectedUser)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAllBank, setIsLoadingAllBank] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [beneficiaryAcc, setBeneficiaryAcc] = useState(null);

  const [allBanks, setAllBanks] = useState([]);
  const [bankCode, setBankCode] = useState(null);

  const [senderAcc, setSenderAcc] = useState(null);
  const [senderId, setSenderId] = useState(null);

  const [receiverName, setReceiverName] = useState(null);

  const adminId = JSON.parse(window.localStorage.getItem("auth")).id;

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingAllBank(true);
    customerService
      .getCustomersAccount(selectedUser.id)
      .then((val) => {
        setUserAccounts(val.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401) navigate("/login");
      });

    customerService
      .getCategories()
      .then((val) => {
        setCategories(val.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) navigate("/login");
      });

    getBanks()
      .then((val) => {
        setAllBanks(val.data.data);
        setIsLoadingAllBank(false);
      })
      .catch((error) => {
        // setIsLoadingAllBanck(false)
        if (error.response.status === 401) navigate("/login");
      });
  }, []);

  const handleSubmitForm = (values, action) => {
    const data = {
      amt: values.amount,
      senderNuban: senderAcc,
      receiver_nuban: beneficiaryAcc,
      narration: values.remarks,
      sender_account_id: senderId,
      userId: selectedUser.id,
      receiver_name: receiverName,
      sender_name: `${selectedUser.f_name} ${selectedUser.l_name}`,
      bankcode: bankCode,
      // "receiverId" : "1354",
      adminId,
      type: "others",
    };
    toast.success("Not yet ready");
    console.log(data);
    //   handleCallBack(data)
  };

  const handleSenderAccount = (val) => {
    setSenderAcc(val.split("-")[0]);
    setSenderId(val.split("-")[1]);
  };

  useEffect(() => {
    if (bankCode !== null && beneficiaryAcc !== null) {
      bankEnquiry({ nuban: beneficiaryAcc, bank_code: bankCode })
        .then((val) => {
          setReceiverName(val.data.data.Name);
          console.log(val.data.data.Name);
        })
        .catch((error) => {
          if (error.response.status === 401) navigate("/login");
        });
    }
  }, [beneficiaryAcc, bankCode]);

  return (
    <Stack>
      {!isLoading && (
        <Formik
          initialValues={{
            customerName: `${selectedUser.f_name} ${selectedUser.l_name}`,
            amount: 0,
            remarks: "",
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number().required("Amount is required"),
            remarks: Yup.string().required("Remarks is required"),
          })}
          onSubmit={handleSubmitForm}
        >
          {(props) => (
            <Stack spacing={3}>
              <TextAndField space={0.5}>
                <Text text={"Customer’s Account Name"} />
                <Field
                  size="large"
                  width={"100%"}
                  name="customerName"
                  disable
                />
              </TextAndField>

              <TextAndField space={0.5}>
                <Text text={"Select Customer Account"} />
                <CustumPopperSelect
                  handleCallBack={(val) => handleSenderAccount(val)}
                  placeholder="Select account"
                  displayCom={userAccounts.map((val) => {
                    return {
                      main: (
                        <AccountComponent
                          name={val.account_name}
                          account={val.nuban}
                          tier={val.account_tier}
                        />
                      ),
                      id: `${val.nuban}-${val.id}`,
                      searchData: { data1: val.nuban },
                    };
                  })}
                />
              </TextAndField>

              {/* <TextAndField space={0.5}>
                        <Text text={'Choose Bank'}/>
                        <FieldSelect 
                            list={[{id:1, name:'nema'}]} 
                            height='55px'
                            width={'100%'} 
                            placeholder='Choose Bank' 
                            name='bank'/>
                    </TextAndField> */}

              <TextAndField space={0.5}>
                <Text text={"Choose Bank"} />
                {!isLoadingAllBank && (
                  <CustumPopperSelect
                    placeholder="Select a bank"
                    handleCallBack={(val) => setBankCode(val)}
                    displayCom={allBanks.map((val) => {
                      return {
                        id: val.bank_code,
                        main: <CategoryComponent name={val.name} />,
                        searchData: { data1: val.name },
                      };
                    })}
                  />
                )}
                {!isLoadingAllBank && <p>...</p>}
              </TextAndField>

              <TextAndField space={0.5}>
                <Text text={"Account Number"} />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={beneficiaryAcc}
                  inputProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => {
                    setBeneficiaryAcc(e.target.value);
                  }}
                />
              </TextAndField>

              <TextAndField space={0.5}>
                <Text text={"Receiver name"} />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={receiverName}
                  inputProps={{ style: { fontSize: 14 } }}
                  disabled
                />
              </TextAndField>

              <TextAndField space={0.5}>
                <Text text={"Amount"} />
                <Field
                  size="large"
                  width={"100%"}
                  name="amount"
                  placeholder="Enter amount"
                />
              </TextAndField>

              <TextAndField space={0.5}>
                <Text text={"Remarks"} />
                <Field
                  size="large"
                  width={"100%"}
                  name="remarks"
                  placeholder="Your remarks"
                />
              </TextAndField>

              <TextAndField space={0.5}>
                <Text text={"Category"} />
                <CustumPopperSelect
                  placeholder="What is this for?"
                  displayCom={categories.map((val) => {
                    return {
                      id: val.id,
                      main: <CategoryComponent name={val.name} />,
                      searchData: { data1: val.name },
                    };
                  })}
                />
              </TextAndField>

              <Box sx={{ width: "100%", marginTop: "150px" }}>
                <FillButton
                  width={"100%"}
                  text="Transfer"
                  callBack={props.handleSubmit}
                />
              </Box>
            </Stack>
          )}
        </Formik>
      )}
      {isLoading && <Loader />}
    </Stack>
  );
};

export default OtherBanks;

const SelectCharges = ({ setCharges }) => {
  const list = [100, 200, 300, 400, 500];
  const [selected, setSelected] = useState("null");
  const handleClick = (val, index) => {
    setSelected(index);
    setCharges(val);
  };
  return (
    <Stack spacing={1}>
      <Typography fontSize={"12px"} color="red">
        This transfer will attract a charge of N
      </Typography>
      <Stack direction={"row"} spacing={2}>
        {list.map((val, index) => {
          return (
            <div onClick={() => handleClick(val, index)}>
              <ChargesItems keyVal={index} text={val} selected={selected} />
            </div>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ChargesItems = ({ text, keyVal, selected }) => {
  return (
    <Box
      key={keyVal}
      sx={{
        border: selected === keyVal ? "1px solid red" : "1px solid #C1C1C5",
        color: selected === keyVal ? "red" : "black",
        cursor: "pointer",
        width: "70px",
        padding: "5px 10px",
        borderRadius: "15px",
        textAlign: "center",
      }}
    >
      {text}
    </Box>
  );
};

const AccountComponent = ({ name, account, tier }) => {
  return (
    <Stack
      sx={{
        "&:nth-child(2)": { bgcolor: "#FCFCFC" },
        cursor: "pointer",
        padding: "10px 20px",
        "&:hover": { bgcolor: "#efefef" },
      }}
      direction={"row"}
      justifyContent={"space-between"}
    >
      <Typography fontSize={"14px"} color={"#252424"}>
        {name}
      </Typography>
      <Typography fontSize={"14px"} color={"#252424"}>
        {account}
      </Typography>
      <Typography fontSize={"14px"} color={"#252424"}>
        {tier}
      </Typography>
    </Stack>
  );
};

const BeneficiaryComponent = ({ name, account }) => {
  return (
    <Stack
      sx={{
        "&:nth-child(2)": { bgcolor: "#FCFCFC" },
        cursor: "pointer",
        padding: "5px 20px",
        "&:hover": { bgcolor: "#efefef" },
      }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Avatar
        sx={{
          width: "30px",
          height: "30px",
          "&:nth-child(2)": { bgcolor: deepPurple[500] },
          "&:nth-child(1)": { bgcolor: deepOrange[500] },
        }}
      >
        <Typography fontSize={"11px"} color={"#252424"}>
          {name.split("").splice(0, 2).join("")}
        </Typography>
      </Avatar>
      <Typography fontSize={"14px"} color={"#252424"}>
        {account}
      </Typography>
      <Typography fontSize={"14px"} color={"#252424"}>
        {name}
      </Typography>
    </Stack>
  );
};

const CategoryComponent = ({ name }) => {
  return (
    <Stack
      sx={{
        "&:nth-child(2)": { bgcolor: "#FCFCFC" },
        cursor: "pointer",
        padding: "8px 20px",
        "&:hover": { bgcolor: "#efefef" },
      }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"300px"}
    >
      <Box
        sx={{
          width: "20px",
          height: "20px",
          borderRadius: "10px",
          bgcolor: "black",
        }}
      />
      <Typography fontSize={"14px"} color={"#252424"}>
        {name}
      </Typography>
    </Stack>
  );
};
