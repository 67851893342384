import { useState } from "react";

import CustomModal from "../../../ReuseComponent/Modal/CustomModal";
import Button from "../../../ReuseComponent/FormUtils/Button";
import Loader from "../../../ReuseComponent/Loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FiUpload } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { uploadPicture } from "../../../Network/Customers";
import { replaceDocumentThunk } from "../../../ReduxStore/Customers";

const ReplaceDoc = ({ openModal, handleCloseModal, data, callBack, field }) => {
  const dispatch = useDispatch();

  const [isReplacing, Replacing] = useState(false);
  const [fileReplace, setFileReplace] = useState(null);

  const SelectFile = () => {
    const file = document.querySelector(".file_upload");
    file.click();
  };

  const saveFile = (e) => {
    setFileReplace(e.target.files[0]);
  };

  const UploadImageFile = async () => {
    Replacing(true);
    const formData = new FormData();
    formData.append("pic", fileReplace);

    uploadPicture(formData).then((val) => {
      dispatch(
        replaceDocumentThunk({
          user_id: data?.id.toString(),
          field,
          value: val?.data?.mediaImage,
        }),
      ).then((v) => {
        Replacing(false);
        if (v?.payload?.status === 200) {
          toast.success("Document Replaced");
          callBack();
        } else toast.error("Failed");
      });
    });
  };

  return (
    <div>
      <CustomModal
        className="!w-[30vw] h-[60vh]"
        open={openModal}
        handleClose={() => {
          handleCloseModal();
        }}
      >
        <div className="p-5 flex flex-col gap-2 h-full">
          <AiOutlineClose
            onClick={handleCloseModal}
            className="self-end cursor-pointer"
            size={18}
          />

          <center className="flex flex-col gap-3 h-[100%]">
            <p className="text-main-color font-semibold">Replace document</p>
            <p className=" text-grey-600 text-[14px] font-semibold">
              Replace existing file
            </p>

            <div className="flex flex-col justify-between h-full">
              {!isReplacing ? (
                <div className="outline-1 outline-dashed flex flex-col justify-center h-[60%] py-8">
                  <div className="">
                    <span style={{ display: "none" }}>
                      <input
                        type="file"
                        className="file_upload"
                        multiple={false}
                        onChange={saveFile}
                      />
                    </span>
                  </div>

                  <p>
                    <FiUpload
                      onClick={SelectFile}
                      size={27}
                      className="text-main-color cursor-pointer"
                    />
                  </p>
                  {fileReplace && <p className="link_label">1 File Selected</p>}
                </div>
              ) : (
                <div>
                  <Loader />
                </div>
              )}

              <Button
                loading={isReplacing}
                disable={!fileReplace}
                handleClick={UploadImageFile}
                text={"Upload file"}
              />
            </div>
          </center>
        </div>
      </CustomModal>
    </div>
  );
};

export default ReplaceDoc;
