import { BsSearch } from "react-icons/bs";

const Search = ({
  placeholder,
  handleChange = () => {},
  className,
  handleClick = () => {},
}) => {
  return (
    <div
      className={`flex w-[90%] justify-between px-3 py-3 rounded-md border-1 border-main-border-color items-center bg-[#F8F8F8] ${className}`}
    >
      <input
        className="border-none w-full"
        style={{ backgroundColor: "transparent" }}
        type="text"
        placeholder={placeholder}
        // value={searchText}
        onChange={handleChange}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleClick();
        }}
      />
      <span className="cursor-pointer" onClick={handleClick}>
        <BsSearch />
      </span>
    </div>
  );
};

export default Search;
