import { useGetSingleFeedBackByIdQuery } from "../../../Network/Feedback";
import { useNavigate } from "react-router-dom";

const useGetFeedBack = (id) => {
  const navigate = useNavigate();
  const { isFetching, data, error } = useGetSingleFeedBackByIdQuery(id);

  if (error) {
    if (error.status === "401" || error.status === 401) navigate("/login");
  }

  return { isFetching, data };
};

export default useGetFeedBack;
