/* eslint-disable no-param-reassign */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllFeedbacks, updateFeedbackStatus } from "../Network/Feedback";

export const getAllFeedbacksThunk = createAsyncThunk(
  "feedback/getAllFeedbacks",
  getAllFeedbacks,
);
export const getAllFeedbacksNoLoadingThunk = createAsyncThunk(
  "feedback/getAllFeedbacks",
  getAllFeedbacks,
);

export const updateFeedbackStatusThunk = createAsyncThunk(
  "feedback/updateFeedbackStatus",
  updateFeedbackStatus,
);

const initialState = {
  feebacksList: [],
  loading: false,
  loadingStatus: false,
};

const FeedbackSlice = createSlice({
  name: "feedback",
  initialState,

  extraReducers: {
    [getAllFeedbacksThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllFeedbacksThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.feebacksList = action.payload.data?.data || [];
      return state;
    },
    [getAllFeedbacksThunk.rejected]: (state, action) => {
      state.loading = false;
    },

    [getAllFeedbacksNoLoadingThunk.pending]: (state, action) => {},
    [getAllFeedbacksNoLoadingThunk.fulfilled]: (state, action) => {
      state.feebacksList = action.payload.data?.data || [];
      return state;
    },
    [getAllFeedbacksNoLoadingThunk.rejected]: (state, action) => {},

    [updateFeedbackStatusThunk.pending]: (state, action) => {
      state.loadingStatus = true;
    },
    [updateFeedbackStatusThunk.fulfilled]: (state, action) => {
      state.loadingStatus = false;
      return state;
    },
    [updateFeedbackStatusThunk.rejected]: (state, action) => {
      state.loadingStatus = false;
    },
  },
});

export default FeedbackSlice.reducer;
