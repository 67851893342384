/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */

import { MenuItem, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  approveLimitThunk,
  disapproveLimitThunk,
  getRequestThunk,
  getRequestWithoutLoadingThunk,
} from "../../ReduxStore/Request";

import Loader from "../../ReuseComponent/Loader";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import Pagination from "../../ReuseComponent/Pagination";
import BasicMenu from "../ExistingAccount/menu/menu";

const NewRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.request);
  const [loading, setIsloading] = useState(false);

  const auth = JSON.parse(window.localStorage.getItem("auth"));

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageValue, setPageValue] = useState(1);

  const getRequest = () => {
    setIsloading(true);

    dispatch(getRequestThunk(currentPage))
      .then((_) => {
        setPageValue(currentPage);
        setIsloading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) navigate("/login");
      });
  };

  useEffect(() => {
    getRequest();
  }, []);

  const handleFetchPagination = () => {
    getRequest();
  };

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const [openLoaderMenu, setOpenLoaderMenu] = useState(null);
  const handleOpenLoaderMenu = (event) => {
    setOpenLoaderMenu(event.currentTarget.parentElement.parentElement);
  };

  const handleApprove = (e) => {
    setOpenMenu(false);
    handleOpenLoaderMenu(e);

    dispatch(
      approveLimitThunk({
        limitId: data.id,
        userId: data.zikora_user_id,
      }),
    )
      .then((value) => {
        dispatch(getRequestWithoutLoadingThunk(currentPage)).then((value) =>
          setOpenLoaderMenu(false),
        );
      })
      .catch((error) => {
        if (error.response.status === 401) navigate("/login");
      });
  };

  const handleDisapprove = (e) => {
    setOpenMenu(false);
    handleOpenLoaderMenu(e);
    dispatch(
      disapproveLimitThunk({
        limitId: data.id,
        userId: data.zikora_user_id,
      }),
    )
      .then((val) => {
        dispatch(getRequestWithoutLoadingThunk(currentPage)).then((val) =>
          setOpenLoaderMenu(false),
        );
      })
      .catch((error) => {
        if (error.response.status === 401) navigate("/login");
      });
  };

  return (
    <div>
      <BasicMenu openMenu={openMenu} setOpenMenu={setOpenMenu}>
        <MenuItem sx={{ color: "#1AC847" }} onClick={handleApprove}>
          Approve
        </MenuItem>
        <MenuItem sx={{ color: "#FF4040" }} onClick={handleDisapprove}>
          Disapproved
        </MenuItem>
      </BasicMenu>

      <BasicMenu openMenu={openLoaderMenu} setOpenMenu={setOpenLoaderMenu}>
        <Loader />
      </BasicMenu>

      <MainLayout title={"Request"}>
        <div className="mt-8 flex flex-col gap-2">
          <Pagination
            handleFetchPagination={handleFetchPagination}
            currentPage={currentPage}
            pageValue={pageValue}
            handleChange={(e) => {
              if (e.target.value >= 0) setCurrentPage(e.target.value);
            }}
            loading={loading}
          />

          <div className=" w-full h-[80vh] overflow-y-auto">
            {!loading && (
              <table className="border-1 border-[#E4E4E4] w-full rounded-md">
                <thead className="sticky top-0 bg-[white] w-full text-[12px] text-[#404040] font-semibold">
                  <td className="border-b-1 py-5 px-5 w-[25%]">ACCOUNT NAME</td>
                  <td className="border-b-1 w-[20%]">AMOUNT</td>
                  <td className="border-b-1 w-[25%]">DATE</td>
                  <td className="border-b-1 w-[20%]">DETAILS</td>
                  <td className="border-b-1 w-[10%]">ACTION</td>
                </thead>
                <tbody>
                  {store.requests?.map((val) => {
                    const timestamp = Date.parse(val.created_at); // convert the date string to a timestamp
                    const date = new Date(timestamp); // create
                    return (
                      <tr className="h-20 text-[12px] font-semibold">
                        <td className="px-4 text-[#252424] text-[14px]">
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={2}
                          >
                            <img
                              src={val?.image}
                              alt=""
                              className="h-[50px] w-[50px] object-cover rounded-full"
                            />
                            <p>
                              {val?.account_name.toString().toLocaleUpperCase()}
                            </p>
                          </Stack>
                        </td>

                        <td className="text-[#404040] font-medium">
                          {val?.amount}
                        </td>
                        <td className="text-[#8D8D8D] font-normal">
                          {date.toLocaleString()}
                        </td>
                        <td
                          className="text-[#66A681] cursor-pointer"
                          onClick={() => navigate(`/new-request/${val?.id}`)}
                        >
                          {"View Details".toLocaleUpperCase()}
                        </td>

                        <td
                          className="cursor-pointer pr-5"
                          onClick={(e) => {
                            handleOpenMenu(e);
                            setData(val);
                          }}
                        >
                          <span
                            className={
                              val?.status === "pending"
                                ? "pending"
                                : val?.status === "approved"
                                  ? "approve"
                                  : "disapprove"
                            }
                            style={{
                              color:
                                val?.status === "approved"
                                  ? "green"
                                  : val?.status === "disapproved"
                                    ? "red"
                                    : "black",
                            }}
                          >
                            <div className="bullet" />
                            {val?.status}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {loading && <Loader />}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default NewRequest;
