/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { useSelector, useDispatch } from "react-redux";
import {
  getTransactionCount,
  getTransactionValue,
  getTotalAccounts,
  getUserCount,
  nFormatter,
} from "../../APIs/Metrics/Metrics";
import { Fade } from "react-awesome-reveal";

import { BarChart } from "../../ReuseComponent/BarChat";
import dayjs from "dayjs";
import Loader from "../../ReuseComponent/Loader";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import {
  getJoinTimelineThunk,
  getTrancsactedValueTimelineThunk,
  getTrancsactionsTimelineThunk,
} from "../../ReduxStore/Home";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const transactedval = useSelector((state) => state.metric)?.transactedValue;
  const total_accounts = useSelector((state) => state.metric)?.total_accounts;
  const total_transactions = useSelector(
    (state) => state.metric,
  ).total_transactions;
  const total_female = useSelector((state) => state.metric)?.total_female_user;
  const total_male = useSelector((state) => state.metric).total_male_user;

  const joinTimeline = useSelector((state) => state.home)?.joinTimeline;
  const trasactedValues = useSelector((state) => state.home)?.trasactedValues;
  const transactions = useSelector((state) => state.home)?.transactions;

  const loading = useSelector((state) => state.home)?.loading;

  const token = window.localStorage.getItem("token");

  useEffect(() => {
    if (token === "" || token === null) navigate("/login");
  }, []);

  const auth = JSON.parse(window.localStorage.getItem("auth"));

  const [fromDate, setFromDate] = useState(dayjs().format("YYYY-MM-DD"));

  const handleApply = () => {
    dispatch(getJoinTimelineThunk({ fromDate, todate: fromDate }));
    dispatch(getTrancsactionsTimelineThunk({ fromDate, todate: fromDate }));
    dispatch(getTrancsactedValueTimelineThunk({ fromDate, todate: fromDate }));
  };

  useEffect(() => {
    handleApply();
    getTransactionCount(auth.id, dispatch);
    getTransactionValue(auth.id, dispatch);
    getTotalAccounts(auth.id, dispatch);
    getUserCount(auth.id, dispatch);
  }, []);

  return (
    <MainLayout>
      <div className="">
        <div className="flex gap-6 w-full mt-3">
          <Card title={"Total Users"}>
            <TextAndLabel title={total_male} text={"Male"} />
            <div className="h-full border-r-1 border-[#94A38E]" />
            <TextAndLabel title={total_female} text={"Female"} />
          </Card>

          <Card title={"Total Accounts"}>
            <TextAndLabel
              textStyle="text-[10px] text-[#959595]"
              title={nFormatter(total_accounts, 2)}
              text={"These are the number of accounts"}
            />
          </Card>

          <Card title={"Total Transactions"}>
            <TextAndLabel
              textStyle="text-[10px] text-[#959595]"
              title={nFormatter(total_transactions, 2)}
              text={"These are the number of transactions"}
            />
          </Card>

          <Card title={"Transacted Value"}>
            <TextAndLabel
              textStyle="text-[10px] text-[#959595]"
              title={`N ${nFormatter(transactedval / 100, 2)}`}
              text={"The total amount that has been transacted"}
            />
          </Card>
        </div>

        <div className="w-full mt-6">
          <div className="flex flex-col gap-10 border-1 p-5 border-main-border-color rounded-md">
            <div className="flex justify-between items-center">
              <p className="text-[18px] font-semibold text-main-color">
                User sign ups
              </p>

              <div className="flex gap-4 items-center text-[14px]">
                <GraphIndicator text={"Total users for the day"} />

                <input
                  className="border-1 border-main-border-color hover:border-main-color px-2 py-2 rounded-md cursor-pointer flex items-center"
                  type="date"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />

                <button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    handleApply();
                  }}
                  className={`text-[white] px-6 py-2 rounded-sm hover:opacity-50 bg-main-color ${loading && "opacity-60"}`}
                >
                  Apply
                </button>
              </div>
            </div>

            {!loading ? (
              <div className="">
                {/* chh */}

                <Fade duration={2000}>
                  <ResponsiveContainer width="100%" height={180}>
                    <BarChart
                      data={joinTimeline}
                      settings={{ maxColumnWidth: 60, color: "#E4E4E4" }}
                    />
                  </ResponsiveContainer>
                </Fade>
              </div>
            ) : (
              <div className="h-[200px] flex justify-center mt-3">
                <Loader />
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-3 w-full mt-10">
          <GraphLayout
            title={"Number of transactions"}
            subTitle={"Total No of transaction"}
            subTitleStyle={"!bg-[#aaaa50]"}
          >
            {!loading ? (
              <div className="">
                {/* chh */}

                <Fade duration={2000}>
                  <ResponsiveContainer width="100%" height={180}>
                    <BarChart
                      data={trasactedValues}
                      settings={{ maxColumnWidth: 40, color: "#E4E4E4" }}
                    />
                  </ResponsiveContainer>
                </Fade>
              </div>
            ) : (
              <div className="h-[200px] flex justify-center mt-3">
                <Loader />
              </div>
            )}
          </GraphLayout>

          <GraphLayout
            title={"Transaction value"}
            subTitle={"Total No of transaction value"}
            subTitleStyle={"!bg-[#8484ca]"}
          >
            {!loading ? (
              <div>
                <Fade duration={2000}>
                  <ResponsiveContainer width="100%" height={180}>
                    <BarChart
                      data={transactions}
                      settings={{ maxColumnWidth: 60, color: "#E4E4E4" }}
                    />
                  </ResponsiveContainer>
                </Fade>
              </div>
            ) : (
              <div className="h-[200px] flex justify-center mt-3">
                <Loader />
              </div>
            )}
          </GraphLayout>
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;

const GraphLayout = ({ title, subTitle, subTitleStyle, children }) => {
  return (
    <div className="w-[50%] border-1 border-main-border-color rounded-md flex flex-col gap-8 px-5 py-6">
      <div className="text-[14px] flex justify-between">
        <p className="font-semibold text-[#4C6356]">{title}</p>
        <GraphIndicator indicatorStyle={subTitleStyle} text={subTitle} />
      </div>

      {children}
    </div>
  );
};

const GraphIndicator = ({ indicatorStyle = "", text }) => {
  return (
    <div className="flex gap-2 items-center">
      <div className={`h-4 w-4 bg-main-color ${indicatorStyle}`} />
      <p className="text-[#565555]">{text}</p>
    </div>
  );
};
const TextAndLabel = ({ title, text, textStyle = "" }) => {
  return (
    <div className="flex flex-col items-center text-[#252424]">
      <p className="text-[30px] font-semibold">
        <Fade duration={1000}>{title}</Fade>
      </p>
      <p className={`text-[12px] text-center ${textStyle}`}>{text}</p>
    </div>
  );
};

const Card = ({ title, children }) => {
  return (
    <div className="border-1 border-main-border-color w-[50%] h-[130px] px-3 py-2 rounded-md flex flex-col gap-4">
      <p className="text-main-color text-[14px] font-medium">{title}</p>

      <div className="flex gap-4 self-center ">{children}</div>
    </div>
  );
};
