import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import avatar from "../../Assets/ICONS/users.png";
import Button from "../../ReuseComponent/FormUtils/Button";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Input from "../../ReuseComponent/FormUtils/Input";
import { useDispatch, useSelector } from "react-redux";
import { feedbackMailThunk } from "../../ReduxStore/Customers";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useGetFeedBack from "./hooks/useGetFeedBack";

const FeedbackDetail = () => {
  const { id } = useParams();
  const { isFetching, data } = useGetFeedBack(id);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.customers)?.loading;

  const auth = JSON.parse(localStorage.getItem("auth"));

  const initialValues = {
    toEmail: data?.email,
    subject: "",
    body: "",
  };

  const validationSchema = Yup.object().shape({
    toEmail: Yup.string().email("Invalid email").required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    body: Yup.string().required("Body is required"),
  });

  const handleSubmit = (values, actions) => {
    dispatch(
      feedbackMailThunk({
        user_id: data?.user_id,
        admin_id: auth?.id,
        subject: values?.subject,
        body: values?.body,
      }),
    ).then((val) => {
      if (val?.payload?.status === 200) {
        toast.success("Reply send");
        actions.resetForm();
      } else toast.error(val?.payload?.data?.message);
    });
  };

  return (
    <MainLayout title={"Feedback Detail"} isLoading={isFetching}>
      <div className="flex w-full h-[100vh]">
        <div className="w-[55%] h-full border-r-1 border-[#E4E4E4]">
          <div className="text-[16px]">
            <span className="text-[#8D8D8D]">Customers Feedback </span>
            <span className="text-[black]">&gt; </span>
            <span className="text-[#FF4040]">Feedback {data?.id}</span>
          </div>

          <div className="mt-10 ml-8 flex flex-col gap-8">
            <div className="flex items-center gap-3">
              <img
                className="w-12 h-12 rounded-full"
                src={data?.image || avatar}
                alt=""
              />

              <div className="flex flex-col gap-1 text-[14px] text-[#252424]">
                <p className="font-medium">
                  {data?.f_name || "No first name"}{" "}
                  {data?.l_name || "No last name"}
                </p>
                <p className="text-[12px]">
                  {" "}
                  <span>Email: </span>{" "}
                  <span className="text-[#6B6B6B]">
                    {data?.email || "No email"}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-5 ml-16">
              <div className="flex flex-col gap-2">
                <p className="text-main-color text-[14px]">Report an Issue</p>
                <p className="text-[20px] text-[#252424]">
                  {data?.description}
                </p>
              </div>

              <div className="text-[black] leading-10 text-[14px] w-[80%]">
                {data?.name}
              </div>

              <div className="text-[black] leading-10 text-[14px] w-[80%]">
                {data?.title}
              </div>
            </div>
          </div>
        </div>

        <div className="px-3 w-[45%]">
          <p className="text-main-color text-[16px] font-semibold">
            Send a Reply
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <div className="mt-10 flex flex-col w-full gap-8">
                <div className="flex flex-col gap-4 text-[black] w-full text-[14px]">
                  <div className="flex gap-4 border-b-1 items-center border-[#8D8D8D] w-full">
                    <p>To:</p>
                    <Input
                      className=" w-full"
                      inputStyle="border-none"
                      placeholder="Enter email"
                      name="toEmail"
                      disabled
                    />
                  </div>

                  <div className="flex gap-4 border-b-1 border-[#8D8D8D] items-center w-full">
                    <p>Subject:</p>
                    <Input
                      className=" w-full"
                      inputStyle="border-none"
                      placeholder="Enter Subject"
                      name="subject"
                    />
                  </div>
                </div>

                <Field
                  as="textarea"
                  className={`focus:outline-none p-3 ${
                    props.errors?.body &&
                    props.touched?.body &&
                    "border-1 border-[red]"
                  }`}
                  name="body"
                  rows="10"
                  placeholder="Type Body here"
                />

                <Button
                  loading={loading}
                  className="self-end"
                  text={loading ? "...Replying" : "Reply"}
                  handleClick={props.handleSubmit}
                />
              </div>
            )}
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default FeedbackDetail;
