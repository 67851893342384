import React, { useState, useEffect } from "react";
import { Popper, Stack, Box } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";

import Search from "./Search/Search";

const AppPopper = ({
  openAppPopper,
  data,
  setOpenAppPopper,
  callBack = () => {},
}) => {
  const open = Boolean(openAppPopper);
  const id = open ? "simple-popper" : undefined;

  const [searchDatap, setSearchDatap] = useState([]);

  const handleSearch = (val) => {
    setSearchDatap(data);
    if (val !== "") {
      const pattern = new RegExp(`\\D*${val}\\D*`, "i");
      const result = data.filter((x) => x.searchData.data1.match(pattern));

      setSearchDatap(result);
    }
  };
  useEffect(() => {
    setSearchDatap(data);
  }, [data]);

  return (
    <div>
      <Popper
        id={id}
        open={open}
        anchorEl={openAppPopper}
        placement="bottom-start"
      >
        <Stack
          spacing={2}
          sx={{
            bgcolor: "white",
            width: "656px",
            height: "230px",
            overflowY: "auto",
            boxShadow: "5px 30px 40px rgba(173, 173, 173, 0.1)",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pr={2.5}
          >
            <Box sx={{ width: "200px", pl: 2.5, pt: 2 }}>
              <Search
                size="small"
                placeholder={"search"}
                handleClick={(val) => handleSearch(val)}
              />
            </Box>
            <AiOutlineClose
              style={{ cursor: "pointer" }}
              onClick={() => setOpenAppPopper(false)}
            />
          </Stack>
          <div>
            {searchDatap.map((val) => {
              return (
                <div
                  onClick={() => {
                    callBack(val);
                    setOpenAppPopper(false);
                  }}
                >
                  {val.main}
                </div>
              );
            })}
          </div>
        </Stack>
      </Popper>
    </div>
  );
};

export default AppPopper;
