import React from "react";
import "./Breakpoint.css";

function BreakPoint() {
  return (
    <div className="breakpoint">
      <div className="message">
        This experience is designed to be viewed via desktop or tablet
      </div>
    </div>
  );
}

export default BreakPoint;
