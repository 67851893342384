/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import "./Transaction.css";
import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import transact from "../../Assets/ICONS/transact.png";
import Loader from "../../ReuseComponent/Loader";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import { useSelector, useDispatch } from "react-redux";
import { getSingleUserTransactionsThunk } from "../../ReduxStore/Customers";
import Search from "../../ReuseComponent/FormUtils/Search";
import Pagination from "../../ReuseComponent/Pagination";

const { FormatMoney } = require("format-money-js");

const fm = new FormatMoney({
  decimals: 2,
});

const UserTransaction = () => {
  const dispatch = useDispatch();
  const userTransactions = useSelector(
    (state) => state.customers,
  )?.userTransactions;

  const loading = useSelector((state) => state.customers)?.loading;

  const [search_input, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageValue, setPageValue] = useState(1);
  const [limitRange, setLimitRange] = useState(10);

  const searchTransaction = async () => {
    dispatch(
      getSingleUserTransactionsThunk({
        input: search_input,
        page: currentPage,
        limit: limitRange,
      }),
    );
  };

  const fetchTransactions = () => {
    dispatch(
      getSingleUserTransactionsThunk({
        page: currentPage,
        limit: limitRange,
      }),
    ).then((_) => {
      setPageValue(currentPage);
    });
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <MainLayout title="Transactions">
      <div className="flex flex-col gap-3">
        <div className="">
          <p className="text-[16px] text-grey-800">Total Customers</p>

          <Fade duration={1000}>
            <p className="text-[24px]">{userTransactions.length}</p>
          </Fade>

          <p className="text-[13px] text-[#D1D1D1]">
            These are the total number of transactions
          </p>
        </div>

        <Fade duration={1000}>
          <Search
            className="mt-3 w-full"
            placeholder={"Search for transaction with reference number"}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
            handleClick={() => searchTransaction()}
          />
        </Fade>

        <Pagination
          limit
          handleFetchPagination={fetchTransactions}
          currentPage={currentPage}
          pageValue={pageValue}
          handleChange={(e) => {
            if (e.target.value >= 0) setCurrentPage(e.target.value);
          }}
          handleChangeLimitRange={(e) => {
            if (e >= 0) setLimitRange(e);
          }}
          loading={loading}
        />

        <div className="max-h-[70vh] w-full overflow-y-auto">
          {userTransactions.length !== 0 && !loading ? (
            <table className="w-full">
              <tr className="sticky w-full top-0 z-10 bg-light-gray text-[16px]">
                <td className="py-3 px-3">S/N</td>
                <td>Narration</td>

                <td>Amount</td>
                <td>Date</td>
                <td>Transaction ref</td>
                <td>Status</td>
              </tr>

              {userTransactions.map((dt, key) => (
                <tr key={key}>
                  <td className="py-3 px-3">
                    <Fade>{key + 1}</Fade>
                  </td>
                  <td>
                    <Fade>{`${dt.from_name} TO ${dt.to_name}`}</Fade>
                  </td>
                  <td style={{ color: "green" }}>
                    <Fade>
                      <span className="numba">
                        {fm.from(parseFloat(dt.amt / 100), { symbol: "" })}
                      </span>
                    </Fade>
                  </td>
                  <td className="numba">
                    <Fade>{dt.created_on.substring(0, 10)}</Fade>
                  </td>
                  <td>
                    <Fade>{dt.transaction_ref}</Fade>
                  </td>
                  <td>
                    <Fade>
                      <span style={{ color: "green" }}>Approved</span>
                    </Fade>
                  </td>
                </tr>
              ))}
            </table>
          ) : !loading ? (
            <div className="empty">
              <img alt="" src={transact} />
              <p
                style={{
                  textAlign: "center",
                  margin: "1vh 0vh",
                  fontSize: "14px",
                }}
              >
                There are no existing trasactions
              </p>
            </div>
          ) : (
            <p>{loading && <Loader />}</p>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default UserTransaction;
