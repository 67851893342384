import "./SignInForm.css";
import zikora from "../../../Assets/LOGO/ZikoraLogoGreen.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { conn } from "../../../Utilt/conn";
import { Fade } from "react-awesome-reveal";
import loginimg from "../../../Assets/ICONS/login.png";
import PopError from "../../../ReuseComponent/PopError";

export function SignInForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isloading, loading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrorMsg] = useState("");

  const LoginUser = async (_email, _password) => {
    // if (isloading) return;

    loading(true);

    await axios
      .post(`${conn}/admin/authenticate`, {
        email: _email,
        password: _password,
        device_id: "3333",
        device_type: "computer",
        device_name: "Del",
      })
      .then((value) => {
        if (value.status === 200 || value.status === "200") {
          window.localStorage.setItem("auth", JSON.stringify(value.data.auth));
          window.localStorage.setItem("token", value.data.token);
          window.localStorage.setItem("refreshtoken", value.data.refreshToken);
          loading(false);

          navigate("/home");
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 400 ||
          error?.response?.status === 404
        ) {
          setIsError(true);
          setErrorMsg("In correct credentials");
          loading(false);
        }
      });
  };

  return (
    <div className="sign-in-form">
      <div className="inner">
        <div className="double-row">
          <div className="row1">
            <div className="image-logo">
              <img
                src={zikora}
                alt="img"
                style={{
                  width: "50px",
                  height: "50px",
                  margin: "0px 0px 50px 0px",
                }}
              />
            </div>

            <h1
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: "21px",
                marginBottom: "40px",
                color: "black",
              }}
            >
              Log In
            </h1>

            <form>
              <center>
                <Fade>
                  <div
                    style={{ textAlign: "start", margin: "0", padding: "0" }}
                  >
                    <label>Email</label>
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Email"
                  />
                </Fade>
                <br />
                <Fade>
                  <div
                    style={{ textAlign: "start", margin: "0", padding: "0" }}
                  >
                    <label>Password</label>
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                  />
                  <br />
                </Fade>
              </center>

              {isError && (
                <center>
                  <PopError
                    message={errMsg}
                    handleClick={() => setIsError(false)}
                  />
                </center>
              )}
              <span className="checklayout">
                <input type="checkbox" />
                <span>Remember Password</span>
              </span>

              <center>
                {isloading && (
                  <div
                    style={{ width: "50px", height: "50px", padding: "20px" }}
                  >
                    <div className="sk-bounce">
                      <div
                        className="sk-bounce-dot"
                        style={{ backgroundColor: "#4c6356" }}
                      />
                      <div
                        className="sk-bounce-dot"
                        style={{ backgroundColor: "#4c6356" }}
                      />
                    </div>
                  </div>
                )}
              </center>
              <center>
                <Fade>
                  <button
                    type="button"
                    className="hover:bg-[green] mt-4 bg-[#4c6356] text-[white] py-3 rounded-md"
                    onClick={() => LoginUser(email, password)}
                  >
                    Log In
                  </button>
                </Fade>
              </center>
              <p style={{ textAlign: "start", fontSize: "14px" }}>
                Don't have an account?{" "}
                <span style={{ color: "green" }}>Create one</span>
              </p>
            </form>
          </div>

          <div className="row2">
            <div className="in">
              <div className="header">
                <h1
                  style={{
                    fontWeight: "normal",
                    textAlign: "start",
                    width: "100%",
                    fontSize: "22px",
                  }}
                >
                  <span style={{ color: "#c0dfce" }}>Welcome To</span> Zikora
                  Admin
                </h1>
                <p style={{ fontSize: "14px" }}>
                  Here on your admin console it possible to manage the accounts
                  and also monitor users transactions and activities
                </p>
              </div>

              <div className="img">
                <img src={loginimg} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
