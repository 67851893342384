export function getConfig() {
  return {
    headers: {
      "Content-type": "application/json",
      token: window.localStorage.getItem("token"),
      refreshtoken: window.localStorage.getItem("refreshtoken"),
    },
  };
}

export function getUploadFileConfig() {
  return {
    headers: {
      "Content-type": "multipart/form-data",
      token: window.localStorage.getItem("token"),
      refreshtoken: window.localStorage.getItem("refreshtoken"),
    },
  };
}
