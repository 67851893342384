import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";

import AppPopper from "./AppPopper";

const CustumPopperSelect = ({
  placeholder = "text",
  displayCom = [],
  handleCallBack = () => {},
}) => {
  const [openAppPopper, setOpenAppPopper] = React.useState(null);
  const [display, setDisplay] = useState(null);
  const handleDisplay = (val) => {
    setDisplay(val.main);
    handleCallBack(val.id);
  };

  const handleClick = (event) => {
    setOpenAppPopper(openAppPopper ? null : event.currentTarget);
  };
  return (
    <div>
      <AppPopper
        openAppPopper={openAppPopper}
        data={displayCom}
        callBack={handleDisplay}
        setOpenAppPopper={setOpenAppPopper}
      />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          padding: "15px 20px",
          cursor: "pointer",
          borderRadius: "5px",
          border: "1px solid #C1C1C5",
        }}
        onClick={handleClick}
      >
        {display === null ? (
          <Typography>{placeholder}</Typography>
        ) : (
          <div style={{ width: "90%", height: "30px" }}>{display}</div>
        )}

        <FaChevronDown />
      </Stack>
    </div>
  );
};

export default CustumPopperSelect;
