import CustomModal from "../../../ReuseComponent/Modal/CustomModal";
import Button from "../../../ReuseComponent/FormUtils/Button";
import Loader from "../../../ReuseComponent/Loader";

const Confirm = ({ open, handleClose, handleAction, loading = false }) => {
  return (
    <CustomModal
      className="w-[20vw] px-4 py-3"
      open={open}
      handleClose={!loading && handleClose}
    >
      <div className="h-full w-full">
        {!loading ? (
          <div className="flex flex-col gap-8 items-center justify-center h-full">
            <p className="text-[14px] text-center">
              Are you sure you want to edit this user's BVN
            </p>

            <div className="flex justify-between w-full">
              <Button
                handleClick={handleClose}
                className=""
                text={"No"}
                outline
              />

              <Button handleClick={handleAction} className="" text={"Yes"} />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-8 items-center justify-center h-full">
            <Loader />
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default Confirm;
