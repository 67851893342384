import React, { useEffect, useState } from "react";
import "./Feedback.css";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { Menu, MenuItem } from "@mui/material";
import FeedbackModal from "./Modal/FeedbackModal";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";
import Loader from "../../ReuseComponent/Loader";
import { Fade } from "react-awesome-reveal";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllFeedbacksThunk,
  updateFeedbackStatusThunk,
  getAllFeedbacksNoLoadingThunk,
} from "../../ReduxStore/Feedback";
import { toast } from "react-toastify";
import Pagination from "../../ReuseComponent/Pagination";
import dayjs from "dayjs";
import trimText from "../../Utilt/trimText";

export const NewFeedback = () => {
  const dispatch = useDispatch();

  const feedbacksList = useSelector((state) => state.feedback)?.feebacksList;

  const [feedback, setFeedback] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageValue, setPageValue] = useState(1);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Updating Dialog

  const [openD, setOpen] = useState(false);
  const handleCloseD = () => {
    setOpen(false);
  };

  const [updatemsg, setUpdateMsg] = useState("");

  // Updating Dialog

  const [modal, setModal] = useState(false);

  const updateFeedbackStatus = (item, status, text) => {
    setUpdateMsg("");
    setOpen(true);
    dispatch(
      updateFeedbackStatusThunk({
        feedback_id: item?.id,
        status,
      }),
    ).then((val) => {
      if (val.payload?.status === 200) {
        toast.success("Success");
        dispatch(getAllFeedbacksNoLoadingThunk({ page: currentPage }));
      }
      handleCloseMenu();
      handleCloseD();
    });
  };

  const getAllFeedback = () => {
    setLoading(true);
    dispatch(getAllFeedbacksThunk({ page: currentPage })).then((_) => {
      setPageValue(currentPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllFeedback();
  }, []);

  const handleFetchPagination = () => {
    getAllFeedback();
  };

  return (
    <div>
      <MainLayout label={"Feed back"}>
        <div className="flex flex-col gap-8 mt-3">
          <div className="flex justify-between items-center">
            <p className="text-[black]">Customers Feedback / Reports</p>

            <Pagination
              handleFetchPagination={handleFetchPagination}
              currentPage={currentPage}
              pageValue={pageValue}
              handleChange={(e) => {
                if (e.target.value >= 0) setCurrentPage(e.target.value);
              }}
              loading={loading}
            />
          </div>

          <div className="max-h-[70vh] w-full overflow-y-auto text-[14px]">
            {!loading ? (
              <table className="w-full">
                <tr className="sticky w-full top-0 z-10 bg-light-gray font-semibold text-[black]">
                  <td className="py-4 px-3 w-[45%]">Name</td>
                  <td className="w-[15%]">Feedback Type</td>
                  <td className="w-[15%]">Date</td>
                  <td className="w-[15%]">Details</td>
                  <td className="w-[10%]">Action</td>
                </tr>

                {feedbacksList.map((item) => (
                  <tr key={item?.id} className="text-[12px]">
                    <td className="py-4 px-3">
                      <Fade>
                        <div
                          onClick={() =>
                            navigate("/new-feedback/detail", { state: item })
                          }
                          className="flex gap-2 cursor-pointer"
                        >
                          <img
                            src={item.image}
                            alt=""
                            className="w-[50px] h-[50px] object-cover border-1 rounded-full"
                          />

                          <div className="text-start">
                            <p style={{ color: "black" }}>
                              {item?.f_name ?? "No first name"}{" "}
                              {item?.l_name ?? "No last name"}
                            </p>
                            <p>
                              {trimText(
                                item?.description ?? "No description",
                                50,
                                50,
                              )}
                            </p>
                          </div>
                        </div>
                      </Fade>
                    </td>

                    <td
                      style={{ color: item?.name === "15" ? "green" : "red" }}
                    >
                      <Fade>{item?.name ?? "_"}</Fade>
                    </td>

                    <td style={{ color: "black" }}>
                      <Fade>{dayjs(item.created_at).format("DD/MM/YYYY")}</Fade>
                    </td>

                    <td className="text-[#608E75] cursor-pointer">
                      <Fade>
                        <span
                          onClick={() => {
                            navigate(`/new-feedback/${item?.id}`, {
                              state: item,
                            });
                          }}
                        >
                          View details
                        </span>
                      </Fade>
                    </td>

                    <td>
                      <Fade>
                        <div
                          onClick={(e) => {
                            handleClickMenu(e);
                            setFeedback(item);
                          }}
                          className="text-[12px] cursor-pointer"
                        >
                          {item.status.toString().toLowerCase() ===
                            "default" && <MdOutlineMoreHoriz />}
                          {item.status.toString().toLowerCase() === "fixed" && (
                            <span className="text-[green] border-1 border-[green] p-1">
                              {item.status}
                            </span>
                          )}
                          {item.status.toString().toLowerCase() ===
                            "in progress" && (
                            <span className="text-[orange] border-1 border-[orange] p-1">
                              {item.status}
                            </span>
                          )}
                          {item.status.toString().toLowerCase() ===
                            "unresolved" && (
                            <span className="text-[red] border-[red] border-1 p-1">
                              {item.status}
                            </span>
                          )}
                          {item.status.toString().toLowerCase() ===
                            "acknowledged" && (
                            <span className="text-[#608E75] border-1 border-[#608E75] p-1">
                              {item.status}
                            </span>
                          )}
                        </div>
                      </Fade>
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              <Loader className="mt-5" />
            )}

            {feedback !== null && (
              <FeedbackModal
                open={modal}
                close={() => setModal(false)}
                feedback={feedback}
              />
            )}
          </div>
        </div>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          style={{ left: "-7vw", textAlign: "center" }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              updateFeedbackStatus(feedback, 14, "Fixed");
            }}
            className="menu-text"
          >
            <span style={{ fontSize: "13px", color: "green" }}>
              <center>Fixed</center>
            </span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              updateFeedbackStatus(feedback, 24, "In Progress");
            }}
            className="menu-text"
          >
            <span style={{ fontSize: "13px", color: "orange" }}>
              In Progress
            </span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              updateFeedbackStatus(feedback, 34, "Unresolved");
            }}
            className="menu-text"
          >
            <span style={{ fontSize: "13px", color: "red" }}>Unresolved</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              updateFeedbackStatus(feedback, 44, "Acknowledged");
            }}
            className="menu-text"
          >
            <span style={{ fontSize: "13px", color: "#608E75" }}>
              Acknowledge
            </span>
          </MenuItem>
        </Menu>
      </MainLayout>

      <Dialog
        open={openD}
        onClose={handleCloseD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <center>
            <div style={{ width: "200px", padding: "30px" }}>
              <p className="dialog-text">Updating feedback status</p>
              <br />

              {updatemsg === "" && <Loader />}

              {updatemsg !== "" && updatemsg}
            </div>
          </center>
        </DialogContent>
      </Dialog>
    </div>
  );
};
