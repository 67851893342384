import { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "../../Utilt/capitalizeFirstLetter";
import { AiOutlineBell } from "react-icons/ai";
import { ClickAwayListener, Badge } from "@mui/material";
import Notifications from "./Notifications";
import { useGetAllNotificationsQuery } from "../../Network/notifications";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Header = ({ title }) => {
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [toggleNotification, setToggleNotification] = useState(false);

  const { isLoading, isSuccess, data } = useGetAllNotificationsQuery("", {
    pollingInterval: 3600000,
  });

  return (
    <div className=" fixed z-50 pl-[208px] text-[16px] h-20 w-full ">
      <div className=" w-full h-full pb-3 px-5 bg-[white] justify-between border-b-1 border-primary flex items-end">
        <div className="flex gap-3 items-center">
          {title !== "Metrics" && (
            <BsArrowLeft
              onClick={() => navigate(-1)}
              className="text-main-color cursor-pointer"
              size={20}
            />
          )}
          <p className="text-grey-800">{title}</p>
        </div>

        <div className="flex items-center gap-3">
          <div
            onClick={() => navigate("/profile")}
            className="flex gap-2 items-center"
          >
            <img
              className="h-10 w-10 cursor-pointer rounded-full bg-light-gray"
              src={auth?.image}
              alt=""
            />

            <p className="text-main-color">
              {capitalizeFirstLetter(auth?.f_name)}{" "}
              {capitalizeFirstLetter(auth?.l_name)}
            </p>
          </div>

          <div
            className="relative cursor-pointer"
            onClick={() => setToggleNotification(!toggleNotification)}
          >
            <StyledBadge
              badgeContent={isSuccess && data.length}
              color="primary"
            >
              <AiOutlineBell className="text-[24px] text-main-color cursor-pointer" />
            </StyledBadge>
            {toggleNotification && (
              <ClickAwayListener
                onClickAway={() => setToggleNotification(false)}
              >
                <div className="absolute right-0 z-50 bg-[white] w-[35vw] h-[500px] pb-10 overflow-y-auto shadow-shift-shadow-small">
                  <Notifications
                    data={isSuccess ? data : []}
                    isLoading={isLoading}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
