import FormModal from "./FormModal";

const CustomModal = ({
  children,
  open = false,
  handleClose,
  className = "",
}) => {
  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <div className={`w-[200px] h-[200px] ${className}`}>{children}</div>
      </FormModal>
    </div>
  );
};

export default CustomModal;
