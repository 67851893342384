/* eslint-disable class-methods-use-this */
import axios from "axios";
import { getConfig } from "../../Utilt/Config";
import { conn } from "../../Utilt/conn";

export class FeedbackClass {
  async getAllFeedbacks() {
    const result = await axios.get(`${conn}/admin/feedback/fetch`, getConfig());
    return result;
  }

  async feedbackMail(data) {
    const result = await axios.post(
      `${conn}/send_email_to_user`,
      data,
      getConfig(),
    );
    return result;
  }

  async updateFeedbackStatus(data) {
    const result = await axios.put(
      `${conn}/admin/feedback/update_status`,
      data,
      getConfig(),
    );
    return result;
  }
}
