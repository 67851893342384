/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fname: "",
  lname: "",
  gender: "0",
  phone: "",
  bvn: "",
  dob: "",
  email: "",
  pin: "",
  password: "",
  emailStat: "pending",
};

const OnboardSlice = createSlice({
  name: "onboard",
  initialState,

  reducers: {
    setEmailStat: (state, action) => (state.emailStat = action.payload),
    validateForm: (state, action) => {},
  },
});

export const { setEmailStat } = OnboardSlice.actions;

export default OnboardSlice.reducer;
