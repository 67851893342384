/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */

import React, { useState, useEffect } from "react";
import "./Transfer.css";
import { Box, Typography, Stack } from "@mui/material";

import Search from "../../ReuseComponent/Search/Search";
import { AppTable } from "./Widget/AppTable";
import SecondPage from "./Widget/SecondPage";
import ConfirmAdmin from "./Widget/confirmAdmin";
import ProgressLoader from "./Widget/loader";
import { CustomerPage } from "../../APIs/ServiceClass/CustomerPage";
import Loader from "../Loader/Loader";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import OtherBanks from "./Widget/OtherBanks";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../ReuseComponent/MainLayout/MainLayout";

const customerService = new CustomerPage();

const Transfer = () => {
  const navigate = useNavigate();

  const [alignment, setAlignment] = React.useState("zikora");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState(null);

  const [openLoader, setOpenLoader] = useState(false);
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };

  const [openConfirmAdmin, setOpenConfirmAdmin] = useState(false);
  const handleCloseConfirmAdmin = () => {
    setOpenConfirmAdmin(false);
  };
  const handleSubmitConfirmAdmin = () => {
    setOpenConfirmAdmin(false);
    setOpenLoader(true);
  };

  const callBack = (val) => {
    setOpenConfirmAdmin(true);
    setFormData(val);
  };

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    customerService
      .getCustomers()
      .then((val) => {
        setIsLoading(false);
        setData(val.data.data);
        setSearchData(val.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401) navigate("/login");
      });
  }, []);

  const handleSearch = (val) => {
    setData(searchData);
    if (val !== "") {
      const pattern = new RegExp(`\D*${val}\D*`, "i");
      const result = data.filter((val) =>
        `${val.f_name} ${val.l_name} ${val.bvn}`.match(pattern),
      );

      setData(result);
    }
  };
  return (
    <div>
      <ConfirmAdmin
        openConfirm={openConfirmAdmin}
        handleCloseConfirm={handleCloseConfirmAdmin}
        handleSubmitConfirm={handleSubmitConfirmAdmin}
      />
      {openLoader && (
        <ProgressLoader
          openLoader={openLoader}
          handleCloseLoader={handleCloseLoader}
          formData={formData}
        />
      )}

      <MainLayout title={"Send Money"}>
        <Stack spacing={4}>
          <div className="first-row">
            <p className="hearder-text">
              You are about to make a transaction on behalf of a customer.{" "}
            </p>
            <p className="sub-text">
              <span>PS:</span> Please ensure that you have verified the payment
              amount and selected the correct payment method before processing
              the payment for the selected customer on the Zikora.
            </p>
          </div>

          {pageIndex === 0 && (
            <div
              style={{
                width: "80%",
                alignSelf: "flex-end",
                marginRight: "30px",
              }}
            >
              <Search
                placeholder={"Search for your customer by name"}
                handleClick={(val) => handleSearch(val)}
              />
            </div>
          )}

          {!isLoading && pageIndex === 0 && (
            <Box
              sx={{
                border: "1px solid #E4E4E4",
                borderRadius: "10px",
                padding: "20px",
                height: "70vh",
              }}
            >
              <Typography
                fontSize={"16px"}
                color={"#66A681"}
                sx={{ marginLeft: "13px" }}
              >
                Custamers
              </Typography>
              {!isLoading && (
                <AppTable
                  setPageIndex={setPageIndex}
                  setSelectedUser={setSelectedUser}
                  data={data}
                />
              )}
            </Box>
          )}
          {isLoading && <Loader />}

          {pageIndex === 1 && (
            <div style={{ margin: "0 70px 30px 70px" }}>
              <div style={{ margin: "30px 30px 40px 0", marginBottom: "40px" }}>
                {" "}
                <Search placeholder={"Search for your customer by name"} />
              </div>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{ mb: 5 }}
              >
                <ToggleButton value="zikora">Zikora</ToggleButton>
                <ToggleButton value="other">Other Banks</ToggleButton>
              </ToggleButtonGroup>

              {alignment === "zikora" && (
                <div>
                  <SecondPage
                    handleCallBack={callBack}
                    selectedUser={selectedUser}
                  />
                </div>
              )}
              {alignment === "other" && (
                <div>
                  <OtherBanks
                    handleCallBack={callBack}
                    selectedUser={selectedUser}
                  />
                </div>
              )}
            </div>
          )}
        </Stack>
      </MainLayout>
    </div>
  );
};

export default Transfer;
