/* eslint-disable no-nested-ternary */

import React from "react";

const HeaderRow = ({ img, dta, customer }) => {
  return (
    <div className="flex justify-between w-full">
      <img className="w-20 h-20 rounded-full" src={img} alt="" />

      <div className="flex flex-col gap-2">
        <p className="text-[14px] text-[#6B6B6B]">Number of Transaction</p>
        <p className="text-[20px] text-[black]">
          {customer !== null && customer?.numberOfTransactionInThirtyDays}
        </p>
        <p className="text-[12px] text-[#C0C0C0]">
          Number of transactions (30 days)
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-[14px] text-[#6B6B6B]">Number of accounts</p>
        <p className="text-[20px] text-[black]">
          {customer !== null && customer?.noOfBankAccount}
        </p>
        <p className="text-[12px] text-[#C0C0C0]">
          These are number of accounts
        </p>
      </div>

      <div className="flex flex-col gap-3 text-[12px]">
        <p className="text-[14px] text-[#6B6B6B]">Status</p>
        <p>
          {" "}
          {dta?.approved === 1 ? (
            <span className="text-[green] bg-[#8CFFAC] py-2 px-3 rounded-lg">
              Approved
            </span>
          ) : dta?.approved === -1 ? (
            <span className="bg-[#FF9A8C] text-[#FF0000] py-2 px-3 rounded-lg">
              Disapproved
            </span>
          ) : (
            <span className="bg-[#ffbd33] text-[#ff5733] py-2 px-3 rounded-lg">
              Pending
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default HeaderRow;
