/* eslint-disable react/jsx-no-duplicate-props */

import {
  Typography,
  TextField,
  Button,
  Stack,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from "formik";

export const Text = ({ text }) => {
  return <Typography sx={{ fontSize: "12px" }}>{text}</Typography>;
};

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  "& .MuiOutlinedInput-notchedOutline:hover": {},
});

const useStylesNoneBorder = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
});

export const Field = ({
  type,
  callBack,
  value,
  variant = "outlined",
  noBorder = false,
  multiline,
  maxRows,
  width,
  size = "small",
  disable = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  const classesNoborder = useStylesNoneBorder();
  const classes = useStyles();

  return (
    <div className="flex flex-col gap-1">
      <TextField
        id="outlined-basic"
        {...props}
        {...field}
        size={size}
        type={type === undefined ? "text" : type}
        variant={variant}
        inputProps={{
          style: {
            fontSize: 14,
            color: meta.error && meta.touched ? "red" : "",
          },
        }}
        className={meta.error && meta.touched ? classes.root : ""}
        multiline={multiline !== undefined ? multiline : false}
        minRows={maxRows !== undefined ? maxRows : 1}
        maxRows={maxRows !== undefined ? maxRows : 1}
        sx={{ width, border: "none" }}
        disabled={disable}
        InputProps={{
          classes: {
            notchedOutline: noBorder && classesNoborder.root,
          },
          disableUnderline: noBorder,
        }}
      />

      {meta.error && meta.touched && (
        <Typography sx={{ fontSize: "10px", color: "red", p: 0 }}>
          {meta.error}
        </Typography>
      )}
    </div>
  );
};

export const FieldSelect = ({
  list,
  clickFunction = false,
  value,
  disable = false,
  callback,
  size = "small",
  height = "35px",
  width = "330px",
  placeholder = "Choose",
  ...props
}) => {
  // console.log(list)
  const classes = useStyles();
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth>
      {callback !== undefined && (
        <Select
          {...props}
          {...field}
          IconComponent={ExpandMoreIcon}
          disabled={disable}
          size={size}
          value={value}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ width, height }}
          className={meta.error && meta.touched ? classes.root : ""}
          onChange={callback}
        >
          <MenuItem value={"none"} disabled>
            {placeholder}
          </MenuItem>

          {list !== undefined &&
            clickFunction === true &&
            list.map((val) => (
              <MenuItem
                value={val.id}
                onClick={(e) => {
                  callback(val);
                }}
              >
                {val.name}
              </MenuItem>
            ))}
          {list !== undefined &&
            clickFunction === false &&
            list.map((val) => <MenuItem value={val.id}>{val.name}</MenuItem>)}
        </Select>
      )}
      {callback === undefined && (
        <Select
          {...props}
          {...field}
          IconComponent={ExpandMoreIcon}
          disabled={disable}
          size={size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ width, height }}
          className={meta.error && meta.touched ? classes.root : ""}
        >
          <MenuItem value={"none"} disabled>
            {placeholder}
          </MenuItem>

          {list !== undefined &&
            list.map((val) => <MenuItem value={val.id}>{val.name}</MenuItem>)}
        </Select>
      )}
      {meta.error && meta.touched && (
        <Typography sx={{ fontSize: "10px", color: "red", p: 0 }}>
          {meta.error}
        </Typography>
      )}
    </FormControl>
  );
};

export const FillButton = ({
  text,
  callBack,
  disable,
  height,
  width,
  textSize = "14px",
  isLoading = false,
}) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: "primary",
        color: "white",
        height: height !== undefined ? height : "36px",
        width: width !== undefined ? width : "93",
      }}
      variant="contained"
      disableElevation
      disabled={disable || isLoading}
      onClick={callBack}
    >
      {!isLoading && (
        <Typography sx={{ fontSize: textSize }}>{text}</Typography>
      )}
      {isLoading && <CircularProgress size="25px" color="white" />}
    </Button>
  );
};

export const TextAndField = ({ children, space = 1 }) => {
  return (
    <Stack direction="column" gap={space}>
      {children[0]}
      {children[1]}
    </Stack>
  );
};

export const OutlinedButton = ({
  text,
  callBack,
  disable,
  height,
  width,
  textSize = "14px",
}) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        color: "#242424",
        borderColor: "#242424",
        height: height !== undefined ? height : "36px",
        width: width !== undefined ? width : "93",
        ":hover": { borderColor: "rgba(0,0,0,0.2)" },
      }}
      variant="outlined"
      disabled={disable}
      onClick={callBack}
    >
      <Typography sx={{ fontSize: textSize }}>{text}</Typography>
    </Button>
  );
};

export const CustomIconButton = (
  { Icon, callBack = () => {} },
  iconStyle = "",
) => {
  return (
    <IconButton onClick={callBack}>
      <Icon sx={{ fontSize: "18px", ...iconStyle }} />
    </IconButton>
  );
};
