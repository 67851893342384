/* eslint-disable consistent-return */
import axios from "axios";
import { getConfig } from "../Utilt/Config";
import { conn } from "../Utilt/conn";
import zikoraApi from "./api";

export const getLimitRequest = async (page = 1) => {
  try {
    const response = await axios.get(
      `${conn}/get-all-limit-requests?page=${page}`,
      getConfig(),
    );
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getLimitRequestCount = async () => {
  try {
    const response = await axios.get(`${conn}/get-limit-count`, getConfig());
    return response.data;
  } catch (err) {
    if (err.response.status === 401) window.location.href = "/login";
    else return { data: err.response.data, status: err.response.status };
  }
};

export const getSingleRequest = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/get-specific-limit-request/${data.id}`,
      { accountId: data.accountId },
      getConfig(),
    );
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const approveLimit = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/approve-limit`,
      data,
      getConfig(),
    );
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const disapproveLimit = async (data) => {
  try {
    const response = await axios.post(
      `${conn}/admin/disapprove-limit`,
      data,
      getConfig(),
    );
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const deleteUserService = async (data) => {
  try {
    const response = await axios.delete(
      `${conn}/admin/delete-user/${data?.userId}`,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { data: err.response.data, status: err.response.status };
  }
};

export const restrictUserService = async (data) => {
  try {
    const response = await axios.put(
      `${conn}/user/restrict-user`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { data: err.response.data, status: err.response.status };
  }
};

export const unrestrictUserService = async (data) => {
  try {
    const response = await axios.put(
      `${conn}/user/un-restrict-user`,
      data,
      getConfig(),
    );
    return { data: response.data, status: response.status };
  } catch (err) {
    return { data: err.response.data, status: err.response.status };
  }
};

const limitRequest = zikoraApi.injectEndpoints({
  endpoints: (builder) => ({
    getSingleLimitById: builder.query({
      query: (id) => `/get-specific-limit-request-by-id/${id}`,
      keepUnusedDataFor: 30,

      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetSingleLimitByIdQuery } = limitRequest;
