import React from "react";
import { Formik } from "formik";
import { Stack, Typography } from "@mui/material";
import * as Yup from "yup";

import ModalTemplate from "./ModalTemplate";
import {
  TextAndField,
  Field,
  FillButton,
} from "../../../ReuseComponent/FormUtils/FormUtils";

const LimitModal = ({
  openLimitModal,
  handleCloseLimitModal,
  data,
  handleSubmit,
}) => {
  return (
    <ModalTemplate
      title="Limit Management"
      openModalTemplate={openLimitModal}
      handleCloseModalTemplate={handleCloseLimitModal}
      style={{ minHeight: "350px" }}
    >
      <Stack
        className="h-full"
        width={"80%"}
        direction={"column"}
        justifyContent={"center"}
      >
        <Typography
          sx={{ color: "#565555", fontSize: "14px", textAlign: "center" }}
        >
          {" "}
          To increase {data.account_name} account limit, please fill out the
          form below with the requested information.
        </Typography>

        <Formik
          initialValues={{
            accountUser: data.nuban,
            increase: "",
            adminPin: "",
          }}
          validationSchema={Yup.object({
            accountUser: Yup.string()
              .min(2, "Invalid Account Number")
              .required("required"),
            increase: Yup.number()
              .min(2, "Invalid Name Amount")
              .required("required"),
            adminPin: Yup.number()
              .min(4, "Must be 4 numbers")
              .required("required"),
          })}
          onSubmit={(values) => {
            const val = {
              ...values,
              accountId: data.id,
            };
            handleSubmit(val);
          }}
        >
          {(props) => {
            return (
              <Stack spacing={"30px"} mt={3}>
                <FieldContainer>
                  <Field
                    name="accountUser"
                    disable
                    noBorder
                    variant="standard"
                    width={"24rem"}
                    size="large"
                    placeholder="Account Type"
                  />
                  <Typography>User Account</Typography>
                </FieldContainer>

                <FieldContainer>
                  <Field
                    name="adminPin"
                    noBorder
                    type={"number"}
                    variant="standard"
                    width={"24rem"}
                    size="large"
                    placeholder="Pin"
                  />
                  <Typography>Admin Pin</Typography>
                </FieldContainer>

                <TextAndField>
                  <Typography>Enter preferred limit increase.</Typography>
                  <FieldContainer>
                    <Field
                      name="increase"
                      type={"number"}
                      noBorder
                      variant="standard"
                      width={"24rem"}
                      size="large"
                      placeholder="e.g N 20,000,000"
                    />
                    <Typography>N 1,000,000</Typography>
                  </FieldContainer>
                </TextAndField>

                <FillButton text={"Submit"} callBack={props.handleSubmit} />
              </Stack>
            );
          }}
        </Formik>
      </Stack>
    </ModalTemplate>
  );
};

export default LimitModal;

const FieldContainer = ({ children }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      width={"100%"}
      sx={{ borderBottom: "2px solid black" }}
    >
      {children}
    </Stack>
  );
};
