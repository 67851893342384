import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import trimText from "../../Utilt/trimText";
import { useNavigate } from "react-router-dom";
import { useUpdateNotificationMutation } from "../../Network/notifications";

const notificationTypes = {
  feedback: "feedback",
  failedBills: "failed_bills_payment",
  limit: "limit-management",
};

const Notifications = ({ data }) => {
  const handleChange = () => {
    console.log("change");
  };

  return (
    <div className="flex flex-col px-3 py-2 gap-3">
      <div className="flex items-center justify-between sticky top-1 bg-[white]">
        <p className="text-[16px] text-[black] font-medium">Notifications</p>

        <div className="flex gap-2 items-center">
          <p className="text-[10px] text-[#565555]">Mark as all read</p>
          <Switch onChange={handleChange} />
        </div>
      </div>

      {data.length !== 0 ? (
        <div className="w-full">
          {data.map((x, index) => (
            <Card id={index} data={x} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center mt-10 text-[#4e4e4e] text-[14px]">
          <p>No Notifications</p>
        </div>
      )}
    </div>
  );
};

export default Notifications;

const Card = ({ id, data }) => {
  const navigate = useNavigate();

  const [updateNotification, { isLoading, isSuccess, error }] =
    useUpdateNotificationMutation();

  const handleView = () => {
    const datas = data;
    updateNotification(data?.id);
    if (datas?.notification_type === notificationTypes.feedback)
      navigate(`/new-feedback/${datas.feedback_id}`);
    else if (datas?.notification_type === notificationTypes.limit)
      navigate(`/new-request/${datas.limit_id}`);
    else if (datas?.notification_type === notificationTypes.failedBills) {
      // Do Nothing
    } else {
      // Do Nothing
    }
  };

  return (
    <div
      key={id}
      className="border-1 border-[white] hover:border-[#608E75] hover:bg-[#F4FFF9] rounded-[4px] flex flex-col gap-2 px-3 py-2"
    >
      <p className=" text-main-color text-[10px]">
        {dayjs(data?.created_at).format("YYYY-MM-DD")}
      </p>
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <img
            className=" bg-5A5 h-12 w-12 object-cover object-center rounded-full"
            src={data?.image}
            alt=""
          />
          <div className="text-[12px] text-[#3C3C3C]">
            <p>{data?.email}</p>
            <p>{trimText(data?.description, 50, 50)}</p>
          </div>
        </div>

        <p
          onClick={handleView}
          className=" text-main-color text-[12px] cursor-pointer"
        >
          View
        </p>
      </div>
    </div>
  );
};
