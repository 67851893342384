/* eslint-disable no-shadow */
/* eslint-disable consistent-return */

import axios from "axios";
import {
  updateFemaleUsers,
  updateMaleUsers,
  updateTotalAccounts,
  updateTransactionCount,
  updateTransactionValue,
} from "../../ReduxStore/Metric";
import { conn } from "../../Utilt/conn";
// import { config } from "../../Utilt/Config";
const { FormatMoney } = require("format-money-js");

const fm = new FormatMoney({
  decimals: 2,
});

export const getTransactionCount = async (id, dispatch) => {
  const token = window.localStorage.getItem("token");
  const refreshtoken = window.localStorage.getItem("refreshtoken");

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  try {
    const response = await axios.get(
      `${conn}/admin/metrics/transactions/count`,
      config,
    );
    dispatch(updateTransactionCount(response.data.count));
  } catch (err) {
    // Do nothing
  }
};

export const getTransactionValue = async (id, dispatch) => {
  const token = window.localStorage.getItem("token");
  const refreshtoken = window.localStorage.getItem("refreshtoken");

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  try {
    const response = await axios.get(
      `${conn}/admin/metrics/transactions/total`,
      config,
    );
    // console.log("Transacted" + response.data.count)
    dispatch(updateTransactionValue(response.data.data.total_within));
  } catch (err) {
    // Do nothing
  }
};

export const getTotalAccounts = async (id, dispatch) => {
  const token = window.localStorage.getItem("token");
  const refreshtoken = window.localStorage.getItem("refreshtoken");

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  try {
    const response = await axios.get(
      `${conn}/admin/metrics/accounts/count`,
      config,
    );
    dispatch(updateTotalAccounts(response.data.count));
  } catch (err) {
    // Do nothing
  }
};

export const getUserCount = async (id, dispatch) => {
  const token = window.localStorage.getItem("token");
  const refreshtoken = window.localStorage.getItem("refreshtoken");

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
      refreshtoken,
    },
  };

  try {
    const response = await axios.get(
      `${conn}/admin/metrics/users/count`,
      config,
    );
    dispatch(updateMaleUsers(response.data.data.male));
    dispatch(updateFemaleUsers(response.data.data.female));
  } catch (err) {
    // Do nothing
  }
};

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const amountprefix = (value) => {
  if (parseFloat(value) > 0 && parseFloat(value) < 999) {
    return value;
  }

  if (parseFloat(value) > 999 && parseFloat(value) < 999999) {
    return `${fm.from(parseFloat(value) / 1000, { symbol: "" }).toString()}K`;
  }

  if (parseFloat(value) > 999999 && parseFloat(value) < 999999999) {
    return `${fm.from(parseFloat(value) / 1000000, { symbol: "" }).toString()}M`;
  }

  if (parseFloat(value) > 999999999 && parseFloat(value) < 999999999999) {
    return `${fm.from(parseFloat(value) / 1000000000, { symbol: "" }).toString()}B`;
  }
};
