import { Column } from "@ant-design/plots";

export const BarChart = ({ data = [], settings = {} }) => {
  const config = {
    data,
    xField: "day",
    yField: "value",

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        color: "red",
      },
    },

    columnStyle: {
      radius: [8, 8, 0, 0], // Adjust the radius of the top-left, top-right, bottom-right, and bottom-left corners
    },
    ...settings,
  };
  return <Column {...config} />;
};
