/* eslint-disable no-plusplus */

export function highest(data) {
  let high = data[0].in;

  for (let i = 0; i < data.length; i++) {
    if (data[i].in > high) {
      high = data[i].in;
    }

    if (data[i].out > high) {
      high = data[i].out;
    }
  }

  return high;
}
