/* eslint-disable class-methods-use-this */

import axios from "axios";
import { getConfig, getUploadFileConfig } from "../../Utilt/Config";
import { conn } from "../../Utilt/conn";

export class CustomerPage {
  async getCustomerInfo(data) {
    const result = await axios.post(
      `${conn}/admin/user/get_user_brief_info`,
      { user_id: data },
      getConfig(),
    );
    return result;
  }

  async getCustomers() {
    const result = await axios.post(
      `${conn}/admin/get_customers`,
      { page: 1, limit: 1000 },
      getConfig(),
    );
    return result;
  }

  async getCustomersAccount(data) {
    const result = await axios.get(
      `${conn}/admin/customer_accounts/${data}`,
      getConfig(),
    );
    return result;
  }

  async getUserBeneficiary(data) {
    const result = await axios.post(
      `${conn}/user/beneficiaries/fetch`,
      { user_id: data },
      getConfig(),
    );
    return result;
  }

  async getCategories() {
    const result = await axios.get(
      `${conn}/transaction/categories`,
      getConfig(),
    );
    return result;
  }

  async adminUpdateUser({ data, id }) {
    const result = await axios.patch(
      `${conn}/admin/user/updateUserProfile/${id}`,
      data,
      getConfig(),
    );
    return result;
  }

  async uploadFile(data) {
    const result = await axios.post(
      `${conn}/upload_pic`,
      data,
      getUploadFileConfig(),
    );
    return result;
  }

  async updateBVN(data) {
    const result = await axios.patch(
      `${conn}/user/updateProfile`,
      data,
      getConfig(),
    );
    return result;
  }

  async setTransferLimit(data) {
    const result = await axios.put(
      `${conn}/admin/setTransferLimit`,
      data,
      getConfig(),
    );
    return result;
  }

  async adminChangePin(data) {
    const result = await axios.put(
      `${conn}/admin/user/change_pin`,
      data,
      getConfig(),
    );
    return result;
  }

  async adminChangePassword(data) {
    const result = await axios.post(
      `${conn}/admin/user/change_password`,
      data,
      getConfig(),
    );
    return result;
  }

  async adminSendEmail(data) {
    const result = await axios.post(
      `${conn}/send_email_to_user`,
      data,
      getConfig(),
    );
    return result;
  }
}
