import React from "react";

function Loader() {
  return (
    <center style={{ width: "100%" }}>
      <div style={{ width: "60px", height: "60px", padding: "30px" }}>
        <div className="sk-bounce">
          <div
            className="sk-bounce-dot"
            style={{ backgroundColor: "#4c6356" }}
          />
          <div
            className="sk-bounce-dot"
            style={{ backgroundColor: "#4c6356" }}
          />
        </div>
      </div>
    </center>
  );
}

export default Loader;
