import { useGetSingleLimitByIdQuery } from "../../../Network/Request";
import { useNavigate } from "react-router-dom";

const useGetLimitReuest = (id) => {
  const navigate = useNavigate();
  const { isFetching, data, error } = useGetSingleLimitByIdQuery(id);

  if (error) {
    if ([401, "401"].includes(error.status)) navigate("/login");
  }

  return { isFetching, data };
};

export default useGetLimitReuest;
