import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

const FormModal = ({ open, handleClose, children }) => {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "0.5rem",
          }}
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
};

export default FormModal;

//
