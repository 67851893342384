/* eslint-disable prefer-template */

import React, { useState } from "react";
import { style2 } from "../../../Utilt/modal";
import { Modal, Box } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { FeedbackClass } from "../../../APIs/ServiceClass/Feedback";
import Loader from "../../Loader/Loader";

const FeedbackModal = ({ open, close, feedback }) => {
  const feedclass = new FeedbackClass();

  const auth = JSON.parse(localStorage.getItem("auth"));

  const [mail, setMail] = React.useState("");

  const [msg, setMsg] = useState("");

  const [loading, setIsloading] = useState(false);

  const sendReply = async () => {
    if (mail !== "") {
      setMsg("");
      setIsloading(true);
      feedclass
        .feedbackMail({
          user_id: feedback.user_id,
          sender_id: auth.id,
          subject: "Feedback #[" + feedback.id + "]",
          body: mail,
        })
        .then((value) => {
          console.log(value);
          setIsloading(false);
          setMsg("");
          close();
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
          setMsg("Error sending mail");
        });
    } else {
      setMsg("Please type in mail");
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setMsg("");
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {feedback !== null && (
        <Box
          sx={{
            ...style2,
            overflowY: "scroll",
            fontFamily: "Poppins, sans-serif",
            fontSize: "12px",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              width: "100%",
              marginBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span style={{ color: "#8D8D8D" }}>
                Customers Feedback {">"}{" "}
              </span>
              <span style={{ color: "red" }}>Feedback {feedback.id}</span>
            </div>
            <span
              style={{ fontSize: "24px", color: "#66A681" }}
              onClick={() => close()}
            >
              <CgClose />
            </span>
          </Box>

          <Box
            sx={{ display: "inline-flex", width: "100%", margin: "10px 0px" }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "black",
                borderRadius: "100%",
              }}
            >
              <img
                src={feedback.image}
                width="100%"
                height="100%"
                style={{ borderRadius: "100%", objectFit: "cover" }}
                alt=""
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <p>
                {feedback.f_name} {feedback.l_name}
              </p>
              <p style={{ color: "#6B6B6B" }}>Email: {feedback.email}</p>
            </div>
          </Box>

          <hr />

          <center>
            <section
              style={{ width: "85%", textAlign: "start", margin: "20px 0px" }}
            >
              <p style={{ color: "#66A681" }}>Report an issue</p>
              <p style={{ fontSize: "21px" }}>{feedback.title}</p>

              <p style={{ marginTop: "20px" }}>{feedback.description}</p>
            </section>

            <hr
              style={{ border: "1px solid #D1D1D1", borderStyle: "dashed" }}
            />

            <section
              style={{ width: "100%", marginTop: "20px", textAlign: "start" }}
            >
              <center>
                <div style={{ width: "85%", textAlign: "start" }}>
                  <p style={{ marginTop: "20px", color: "#66A681" }}>
                    Attachment:{" "}
                  </p>
                </div>
              </center>
            </section>

            <section
              style={{ width: "90%", textAlign: "start", marginTop: "30px" }}
            >
              <p style={{ fontWeight: "bold" }}>
                <b>Send a reply</b>
              </p>

              <center>
                <section
                  style={{
                    width: "96%",
                    textAlign: "start",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ margin: "10px 0px" }}>
                    <span>To:</span>
                    <span style={{ paddingLeft: "20px" }}>
                      {feedback.email}
                    </span>
                  </div>

                  <hr style={{ border: ".5px solid #C3C3C3" }} />

                  <div style={{ margin: "10px 0px" }}>
                    <span>From:</span>
                    <span style={{ paddingLeft: "20px", color: "#8D8D8D" }}>
                      customerservice@zikora.com
                    </span>
                  </div>

                  <hr style={{ border: ".5px solid #C3C3C3" }} />

                  <div style={{ margin: "10px 0px" }}>
                    <span>Subject:</span>
                    <span style={{ paddingLeft: "20px" }}>
                      Re: Feedback #[{feedback.id}]
                    </span>
                  </div>

                  <hr style={{ border: ".5px solid #C3C3C3" }} />
                </section>

                <section
                  style={{
                    width: "94%",
                    textAlign: "start",
                    marginTop: "30px",
                  }}
                >
                  <center>
                    <textarea
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                      placeholder="Type reply here.."
                      cols={4}
                      style={{
                        width: "100%",
                        height: "120px",
                        border: "none",
                        padding: "10px",
                      }}
                    />
                  </center>
                </section>

                <p style={{ color: "red" }}>{msg}</p>

                {!loading ? (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      display: "inline-flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <button
                      onClick={sendReply}
                      style={{
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: "#66A681",
                      }}
                    >
                      Reply
                    </button>
                  </div>
                ) : (
                  <Loader />
                )}
              </center>
            </section>
          </center>
        </Box>
      )}
    </Modal>
  );
};

export default FeedbackModal;
